import React from "react";
import bgImage from "../../../assets/images/otherService/bg/2.jpg";
import img1 from "../../../assets/images/otherService/3.jpg";
import single from "../../../assets/images/otherService/Sc485sc482health/1.webp";
import couple from "../../../assets/images/otherService/Sc485sc482health/2.jpg";
import multi from "../../../assets/images/otherService/Sc485sc482health/3.jpg";
import { Link } from "react-router-dom";
import EnquiryForms from "../../shared/forms/enquiry/EnquiryForms";

const Sc485sc482health = () => {
  const policys = [
    {
      name: "Single policy",
      image: single,
      description:
        "A single policy will provide cover for the primary visa holder only. This policy will not cover dependants, spouses or children. If your situation changes, you will need to update your policy.",
    },
    {
      name: "Dual family policy",
      image: couple,
      description:
        "A dual family policy provides cover for the primary visa holder or either one adult spouse or de facto partner or one or more children under the age of 18 years old.",
    },
    {
      name: "Multi-family policy",
      image: multi,
      description:
        "A multi-family policy provides cover for the primary visa holder, one adult spouse or de facto partner and one or more dependant children under the age of 18 years old.",
    },
  ];
  return (
    <div>
      <div
        className=" bg-no-repeat bg-center bg-cover  "
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <h2 className="text-center text-5xl text-white p-28">
          SC 485 / SC 482 Health Cover options
        </h2>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 lg:-ml-16 bg-white ">
        <div className="lg:col-span-3 mx-5  lg:ml-[15%] my-20 ">
          <h2 className="text-xl font-normal my-0">
            Health insurance for your 482 Temporary Skill Shortage visa
          </h2>
          <p className=" font-bold my-1">
            Are you travelling to Australia on a 482 Temporary Skill Shortage
            (TSS) visa? <br />
          </p>
          <p className="">
            We offer Overseas Visitor Health Cover (OVHC) for international
            visitors on the 482 Temporary Skill Shortage visa. Our OVHC is
            Australia-wide and lets you access medical and health cover if
            something happens during your stay. <br /> <br />
            The Department of Home Affairs requires TSS visa holders to take out
            health insurance each time they travel to Australia.
          </p>
          <h2 className="text-xl font-medium my-1">
            About the 482 Temporary Skill Shortage (TSS) visa
          </h2>
          <p className="my-1">
            The Temporary Skill Shortage (TSS) visa allows workers to travel to
            Australia to work in their nominated occupation for their approved
            sponsor. This visa replaces the 457 Skilled Temporary Work visa.{" "}
            <br /> <br />
            For more information, visit the Department of Home Affairs website.
          </p>
          <h2 className="text-2xl font-medium text-center my-8">
            Who can be covered?
          </h2>
          <div className="grid grid-cols-1 lg:grid-cols-3 justify-items-center   gap-5">
            {policys.map((policy) => (
              <div key={policy.name} className="my-10">
                <div className="flex flex-col justify-center  gap-3">
                  <div className="avatar">
                    <div className="w-40 rounded-full">
                      <img src={policy.image} alt="family" />
                    </div>
                  </div>
                  <h2 className="text-xl text-left">{policy.name}</h2>
                  <p className=" text-left">{policy.description}</p>
                </div>
              </div>
            ))}
          </div>

          <h2 className="text-xl font-medium my-1">Other visas we cover</h2>
          <h3 className="text-lg font-normal">Student visas</h3>
          <li>500 Student Visa</li>

          <h1 className="text-xl font-normal my-1">Working visas</h1>

          <li>188 Business Innovation & Investment (Provisional)</li>
          <li>400 Temporary Work (Short Stay Activity)</li>
          <li>401 Temporary Work (Long Stay Activity)</li>
          <li>402 Training and Research</li>
          <li>403 Temporary Work (International Relations)</li>
          <li>407 Training</li>
          <li>408 Temporary Activity</li>
          <li>416 Seasonal Worker Program</li>
          <li>457 Skilled Temporary Work</li>
          <li>461 New Zealand Citizen Family Relationship Visa</li>
          <li>476 Skilled Recognised Graduate</li>
          <li>482 Temporary Skill Shortage (TSS)</li>
          <li>485 Temporary Graduate</li>
          <li>489 Skilled Regional (Provisional)</li>
          <li>491 Skilled Work Regional (Provisional)</li>
          <h1 className="text-xl font-normal my-1">Visitor visas</h1>
          <li>300 Prospective Marriage Visa</li>
          <li>580 Guardian Visa</li>
          <li>590 Student Guardian Visa</li>
          <li>600 Visitor</li>
          <li>601 Electronic Travel Authority</li>
          <li>651 eVisitor</li>
          <li>870 Sponsored Parent (Temporary) Visa</li>
          <h1 className="text-xl font-normal my-1">Working holiday visas</h1>
          <li>417 Working Holiday</li>
          <li>462 Work and Holiday</li>
          <li>Second Working Holiday Visa</li>

          <h1 className="text-xl font-normal my-1">Bridging Visa</h1>
          <li>010 Bridging Visa A (BVA)</li>
          <li>020 Bridging Visa B (BVB)</li>
          <li>030 Bridging Visa C (BVC)</li>
        </div>

        <div>
          <div className="avatar">
            <div className="w-full rounded my-12 mx-7">
              <img src={img1} alt="cover" />
            </div>
          </div>

          <ul>
            <Link to="/otherService/student-health-cover">
              <li className="text-white bg-orange-500 hover:bg-blue-600 rounded my-3 mx-7 p-2 text-center">
                Overseas Student Health Cover(OSHC)
              </li>
            </Link>
            <Link to="/otherService/visitor-health-cover">
              <li className="text-white bg-orange-500 hover:bg-blue-600 rounded my-3 mx-7 p-2 text-center">
                Overseas Visitor Health Cover(OVHC)
              </li>
            </Link>
            <Link to="/otherService/sc-485-sc-482-health">
              <li className="text-white bg-orange-500 hover:bg-blue-600 rounded my-3 mx-7 p-2 text-center">
                SC 485 / SC 482 Health
              </li>
            </Link>
            <Link to="/otherService/job-help">
              <li className=" text-white bg-orange-500 hover:bg-blue-600 rounded my-3 mx-7 p-2 text-center">
                Job Help
              </li>
            </Link>
            <Link to="/otherService/accommodation-help">
              {" "}
              <li className=" text-white bg-orange-500 hover:bg-blue-600 rounded my-3 mx-7 p-2 text-center">
                Accommodation Help
              </li>
            </Link>
            <Link to="/otherService/airport-pick-u-services">
              {" "}
              <li className="text-white bg-orange-500 hover:bg-blue-600 rounded my-3 mx-7 p-2 text-center">
                Airport Pick up Services
              </li>
            </Link>
          </ul>
          <div>
            <EnquiryForms/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sc485sc482health;
