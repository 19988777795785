import React from "react";
import bgImage from "../../../../assets/images/study in/australia/classification/bg.png";
import img1 from "../../../../assets/images/study in/australia/2.jpg";
import { Link } from "react-router-dom";
import { BsFillFilePdfFill } from "react-icons/bs";
import EnquiryForms from "../../../shared/forms/enquiry/EnquiryForms";

const UniversityAndCollegeInAutralia = () => {

  return (
    <div>
      <div
        className=" bg-no-repeat bg-center bg-cover"
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <h2 className="text-center text-5xl text-white p-28">
          Universities and Colleges in Australia
        </h2>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 lg:-ml-16 bg-white ">
        <div className="lg:col-span-3 mx-5  lg:ml-[15%] my-20 ">
          <h2 className="text-xl font-bold my-2">
            Top Universities and Colleges in Australia
          </h2>
          <p className=" my-1">
            After the U.K. and the U.S., Australia is the 3rd most popular
            destination for international students like you. And it’s easy to
            see why! Just looking through their universities, you have several
            amazing programmes and beautiful cities to study in.
            <br />
          </p>
          <p className=" my-1">
            Plus, several top-ranked and globally recognised schools and
            colleges are located in Australia.
            <br />
          </p>
          <p className=" mt-1 mb-4">
            Australia is home to plenty of prestigious universities, that are
            highly ranked and well-known around the world, giving you plenty of
            options for reaching some of the best professors and teachers.
            Places like{" "}
            <strong>
              University of Queensland, University of Adelaide, Charles Darwin
              University, and University of Tasmania
            </strong>{" "}
            all have great shiny spots at the top of the rankings lists. If
            you’re looking for the best law schools, business schools, medical
            schools, or engineering schools, with the best reputations to enrol
            in for your Bachelor’s, Master’s, or Ph.D, definitely consider going
            to Australia.
            <br />
          </p>

          <hr />
          <h2 className="text-xl font-bold mt-4 mb-2">
            List of Australian Universities
          </h2>
          <h2 className="text-lg font-bold mt-4 mb-2">
            Australian Capital Territory
          </h2>
          <li>
            Australian National University –{" "}
            <a
              className="link link-hover text-orange-500 hover:text-orange-600"
              target="_blank"
              href="http://www.anu.edu.au/study/information-for/international-students"
            >
              http://www.anu.edu.au/study/information-for/international-students
            </a>{" "}
          </li>
          <li>
            University of Canberra –{" "}
            <a
              className="link link-hover text-orange-500 hover:text-orange-600"
              target="_blank"
              href="http://www.anu.edu.au/study/information-for/international-students"
            >
              http://www.canberra.edu.au/international
            </a>{" "}
          </li>
          <h2 className="text-lg font-bold mt-4 mb-2">New South Wales</h2>
          <li>
            Australian Catholic University –
            <a
              className="link link-hover text-orange-500 hover:text-orange-600"
              target="_blank"
              href="https://www.acu.edu.au/international"
            >
              https://www.acu.edu.au/international
            </a>{" "}
          </li>
          <li>
            Charles Sturt University –
            <a
              className="link link-hover text-orange-500 hover:text-orange-600"
              target="_blank"
              href=" http://study.csu.edu.au/international"
            >
              http://study.csu.edu.au/international
            </a>{" "}
          </li>

          <li>
            Macquarie University –
            <a
              className="link link-hover text-orange-500 hover:text-orange-600"
              target="_blank"
              href="  https://www.mq.edu.au/international.php"
            >
              https://www.mq.edu.au/international.php
            </a>{" "}
          </li>
          <li>
            Southern Cross University –
            <a
              className="link link-hover text-orange-500 hover:text-orange-600"
              target="_blank"
              href="http://scu.edu.au/international/"
            >
              http://scu.edu.au/international/
            </a>{" "}
          </li>
          <li>
            University of New England –
            <a
              className="link link-hover text-orange-500 hover:text-orange-600"
              target="_blank"
              href="  https://www.une.edu.au/study/international"
            >
              https://www.une.edu.au/study/international
            </a>{" "}
          </li>
          <li>
            University of New South Wales –
            <a
              className="link link-hover text-orange-500 hover:text-orange-600"
              target="_blank"
              href=" http://www.international.unsw.edu.au/"
            >
              http://www.international.unsw.edu.au/
            </a>{" "}
          </li>
          <li>
            University of Newcastle –
            <a
              className="link link-hover text-orange-500 hover:text-orange-600"
              target="_blank"
              href=" https://www.newcastle.edu.au/international"
            >
              https://www.newcastle.edu.au/international
            </a>{" "}
          </li>
          <li>
            University of Sydney –
            <a
              className="link link-hover text-orange-500 hover:text-orange-600"
              target="_blank"
              href=" http://sydney.edu.au/future-students/"
            >
              http://sydney.edu.au/future-students/
            </a>{" "}
          </li>
          <li>
            University of Technology, Sydney –
            <a
              className="link link-hover text-orange-500 hover:text-orange-600"
              target="_blank"
              href=" http://www.uts.edu.au/future-students/international"
            >
              http://www.uts.edu.au/future-students/international
            </a>{" "}
          </li>

          <li>
            Western Sydney University –
            <a
              className="link link-hover text-orange-500 hover:text-orange-600"
              target="_blank"
              href=" http://www.westernsydney.edu.au/international"
            >
              http://www.westernsydney.edu.au/international
            </a>{" "}
          </li>

          <li>
            University of Wollongong –
            <a
              className="link link-hover text-orange-500 hover:text-orange-600"
              target="_blank"
              href="University of Wollongong"
            >
              University of Wollongong
            </a>{" "}
          </li>

          <h2 className="text-lg font-bold mt-4 mb-2">Northern Territory</h2>
          <li>
            Charles Darwin University –
            <a
              className="link link-hover text-orange-500 hover:text-orange-600"
              target="_blank"
              href=" http://www.cdu.edu.au/international"
            >
              http://www.cdu.edu.au/international
            </a>{" "}
          </li>
          <h2 className="text-lg font-bold mt-8 mb-2">Queensland</h2>
          <li>
            Bond University –
            <a
              className="link link-hover text-orange-500 hover:text-orange-600"
              target="_blank"
              href=" http://bond.edu.au/future-students/international"
            >
              http://bond.edu.au/future-students/international
            </a>{" "}
          </li>
          <li>
            CQ University –
            <a
              className="link link-hover text-orange-500 hover:text-orange-600"
              target="_blank"
              href="  https://www.cqu.edu.au/international-students"
            >
              https://www.cqu.edu.au/international-students
            </a>{" "}
          </li>
          <li>
            Federation University of Australia –
            <a
              className="link link-hover text-orange-500 hover:text-orange-600"
              target="_blank"
              href="https://federation.edu.au/brisbane"
            >
              https://federation.edu.au/brisbane
            </a>{" "}
          </li>

          <li>
            Griffith University–
            <a
              className="link link-hover text-orange-500 hover:text-orange-600"
              target="_blank"
              href="http://www.griffith.edu.au/international"
            >
              http://www.griffith.edu.au/international
            </a>{" "}
          </li>

          <li>
            James Cook University –
            <a
              className="link link-hover text-orange-500 hover:text-orange-600"
              target="_blank"
              href=" http://www.jcu.edu.au/international/"
            >
              http://www.jcu.edu.au/international/
            </a>{" "}
          </li>
          <li>
            Queensland University of Technology –
            <a
              className="link link-hover text-orange-500 hover:text-orange-600"
              target="_blank"
              href="  https://www.qut.edu.au/international"
            >
              https://www.qut.edu.au/international
            </a>{" "}
          </li>
          <li>
            University of Queensland –
            <a
              className="link link-hover text-orange-500 hover:text-orange-600"
              target="_blank"
              href=" http://www.uq.edu.au/international/"
            >
              http://www.uq.edu.au/international/
            </a>{" "}
          </li>
          <li>
            University of Southern Queensland –
            <a
              className="link link-hover text-orange-500 hover:text-orange-600"
              target="_blank"
              href=" http://www.usq.edu.au/study/international"
            >
              http://www.usq.edu.au/study/international
            </a>{" "}
          </li>
          <li>
            University of the Sunshine Coast –
            <a
              className="link link-hover text-orange-500 hover:text-orange-600"
              target="_blank"
              href=" http://www.usc.edu.au/learn/international-students"
            >
              http://www.usc.edu.au/learn/international-students
            </a>{" "}
          </li>

          <h2 className="text-lg font-bold mt-4 mb-2">South Australia</h2>
          <li>
            Carnegie Mellon University –
            <a
              className="link link-hover text-orange-500 hover:text-orange-600"
              target="_blank"
              href="http://www.australia.cmu.edu/"
            >
              http://www.australia.cmu.edu/
            </a>{" "}
          </li>
          <li>
            Flinders University –
            <a
              className="link link-hover text-orange-500 hover:text-orange-600"
              target="_blank"
              href=" http://www.flinders.edu.au/international-students/"
            >
              http://www.flinders.edu.au/international-students/
            </a>
          </li>
          <li>
            Torrens University Australia –
            <a
              className="link link-hover text-orange-500 hover:text-orange-600"
              target="_blank"
              href="http://www.torrens.edu.au/studying/international-students"
            >
              http://www.torrens.edu.au/studying/international-students
            </a>{" "}
          </li>

          <li>
            University of Adelaide –
            <a
              className="link link-hover text-orange-500 hover:text-orange-600"
              target="_blank"
              href=" http://international.adelaide.edu.au/"
            >
              http://international.adelaide.edu.au/
            </a>{" "}
          </li>

          <li>
            University of South Australia –
            <a
              className="link link-hover text-orange-500 hover:text-orange-600"
              target="_blank"
              href="http://www.unisa.edu.au/study-at-unisa/international-students/"
            >
              http://www.unisa.edu.au/study-at-unisa/international-students/
            </a>{" "}
          </li>

          <h2 className="text-lg font-bold mt-4 mb-2">Tasmania</h2>
          <li>
            University of Tasmania –
            <a
              className="link link-hover text-orange-500 hover:text-orange-600"
              target="_blank"
              href=" http://www.utas.edu.au/international"
            >
              http://www.utas.edu.au/international
            </a>{" "}
          </li>

          <h2 className="text-lg font-bold mt-4 mb-2">Victoria</h2>
          <li>
            Deakin University–
            <a
              className="link link-hover text-orange-500 hover:text-orange-600"
              target="_blank"
              href=" http://www.deakin.edu.au/study-at-deakin/international-students"
            >
              http://www.deakin.edu.au/study-at-deakin/international-students
            </a>{" "}
          </li>

          <li>
            Federation University of Australia –
            <a
              className="link link-hover text-orange-500 hover:text-orange-600"
              target="_blank"
              href=" http://federation.edu.au/international"
            >
              http://federation.edu.au/international
            </a>{" "}
          </li>

          <li>
            La Trobe University –
            <a
              className="link link-hover text-orange-500 hover:text-orange-600"
              target="_blank"
              href="  http://www.latrobe.edu.au/international"
            >
              http://www.latrobe.edu.au/international
            </a>{" "}
          </li>
          <li>
            Monash University –
            <a
              className="link link-hover text-orange-500 hover:text-orange-600"
              target="_blank"
              href="https://www.monash.edu/study/international"
            >
              https://www.monash.edu/study/international
            </a>{" "}
          </li>
          <li>
            RMIT University –
            <a
              className="link link-hover text-orange-500 hover:text-orange-600"
              target="_blank"
              href="https://www.rmit.edu.au/study-with-us/international-students/"
            >
              https://www.rmit.edu.au/study-with-us/international-students/
            </a>{" "}
          </li>
          <li>
            Swinburne University of Technology –
            <a
              className="link link-hover text-orange-500 hover:text-orange-600"
              target="_blank"
              href="http://www.swinburne.edu.au/study/international/"
            >
              http://www.swinburne.edu.au/study/international/
            </a>{" "}
          </li>
          <li>
            University of Divinity –
            <a
              className="link link-hover text-orange-500 hover:text-orange-600"
              target="_blank"
              href="http://www.divinity.edu.au/study/international-student-resources/"
            >
              http://www.divinity.edu.au/study/international-student-resources/
            </a>{" "}
          </li>

          <li>
            University of Melbourne –
            <a
              className="link link-hover text-orange-500 hover:text-orange-600"
              target="_blank"
              href=" https://www.unimelb.edu.au/"
            >
              https://www.unimelb.edu.au/
            </a>{" "}
          </li>

          <li>
            Victoria University –
            <a
              className="link link-hover text-orange-500 hover:text-orange-600"
              target="_blank"
              href="http://www.vu.edu.au/study-with-us/international-students"
            >
              http://www.vu.edu.au/study-with-us/international-students
            </a>{" "}
          </li>

          <h2 className="text-lg font-bold mt-4 mb-2">Western Australia</h2>
          <li>
            Curtin University –
            <a
              className="link link-hover text-orange-500 hover:text-orange-600"
              target="_blank"
              href=" http://international.curtin.edu.au/"
            >
              http://international.curtin.edu.au/
            </a>{" "}
          </li>

          <li>
            Edith Cowan University –
            <a
              className="link link-hover text-orange-500 hover:text-orange-600"
              target="_blank"
              href=" http://www.ecu.edu.au/degrees/international"
            >
              http://www.ecu.edu.au/degrees/international
            </a>{" "}
          </li>

          <li>
            Murdoch University –
            <a
              className="link link-hover text-orange-500 hover:text-orange-600"
              target="_blank"
              href=" http://www.murdoch.edu.au/Future-students/International-students/"
            >
              http://www.murdoch.edu.au/Future-students/International-students/
            </a>{" "}
          </li>
          <li>
            University of Notre Dame Australia –
            <a
              className="link link-hover text-orange-500 hover:text-orange-600"
              target="_blank"
              href=" http://www.nd.edu.au/nav-future-students/international-students"
            >
              http://www.nd.edu.au/nav-future-students/international-students
            </a>{" "}
          </li>
          <li>
            University of Western Australia –
            <a
              className="link link-hover text-orange-500 hover:text-orange-600"
              target="_blank"
              href="http://www.international.uwa.edu.au/"
            >
              http://www.international.uwa.edu.au/
            </a>{" "}
          </li>

          <p className="my-10">
            <a
              className="link link-hover text-orange-500 hover:text-orange-600"
              href="https://drive.google.com/file/d/1IuIPd2qFkSvIjtoXaC_ujBiNu3c3GThL/view?usp=share_link"
              target="_blank"
            >
              <BsFillFilePdfFill className="inline  w-8 h-8" />
              You can Download Australia University Map{" "}
            </a>
          </p>
        </div>

        <div>
          <div className="avatar">
            <div className="w-full rounded my-12 mx-7">
              <img src={img1} alt="cover" />
            </div>
          </div>

          <ul clasName="">
            <Link to="/australian-education-system">
              <li className="text-white bg-orange-500 hover:bg-blue-600 rounded my-3 mx-7 p-2 text-center">
                Australian Education System
              </li>
            </Link>
            <Link to="/universities-and-colleges-in-australia">
              <li className="text-white bg-orange-500 hover:bg-blue-600 rounded my-3 mx-7 p-2 text-center">
                Universities and Colleges in Australia
              </li>
            </Link>
            <Link to="/australian-study-admissions-process">
              <li className="text-white bg-orange-500 hover:bg-blue-600 rounded my-3 mx-7 p-2 text-center">
                Australian Study Admissions Process
              </li>
            </Link>
            <Link to="/australian-study-document-checklist">
              <li className=" text-white bg-orange-500 hover:bg-blue-600 rounded my-3 mx-7 p-2 text-center">
                Australian Study Document Checklist
              </li>
            </Link>
            <Link to="/accommodation-help">
              <li className=" text-white bg-orange-500 hover:bg-blue-600 rounded my-3 mx-7 p-2 text-center">
                Accommodation Help
              </li>
            </Link>
            <Link to="/australian-student-visa-application-process">
              <li className="text-white bg-orange-500 hover:bg-blue-600 rounded my-3 mx-7 p-2 text-center">
                Australian Student Visa Application Process
              </li>
            </Link>
            <Link to="/about-australia-and-its-weather">
              <li className="text-white bg-orange-500 hover:bg-blue-600 rounded my-3 mx-7 p-2 text-center">
                About Australia and its Weather
              </li>
            </Link>
          </ul>
          <div>
            <EnquiryForms />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UniversityAndCollegeInAutralia;
