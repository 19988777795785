import React from "react";
import bgImage from "../../../../assets/images/migration/visa-for-australia.jpg";
import { Link, a } from "react-router-dom";

const VisaOptionForAustralia = () => {
  const visaCategory = [
    {
      name: "Student and Graduate Visa",
      description:
        "Anyone who wants to experience Australia’s high quality of education can apply for a student visa (500). The education provider and country of citizenship determine student visa requirements. Once a student completes their qualification in Australia and they meet certain study requirements they can apply for a two- or three-year Graduate Skilled Visa (485) which will give them unrestricted work rights.",
    },
    {
      name: "Short Term Work Visa",
      description:
        "Short-term work visas are classified as either short stay specialist visas, training visas, or temporary activity visa.",
    },
    {
      name: "Short Stay Visas for Specialist Work – subclass 400",
      description:
        "A specialist work visa (400) can be granted to people who have highly specialized skills that are not easily available in Australia and are required to undertake work of a highly specialised nature in Australia, which is of a short-term duration and usually to fulfil a contractual obligation in Australia required for an Australian company.",
    },
    {
      name: "Temporary Activity Visa – Training – subclass 407",
      description:
        "A Training Visa (407) is granted to people who seek to enhance their skills through a structured work- place training program. The applicant will usually need to have had 12 months experience in the last 24 months in the occupation they wish to be trained in. The employer needs to be approved as a temporary activity sponsor and present a relevant and detailed structured training plan which the overseas person will be trained in. The visa can be requested for any period of time up to 2 years, depending on the amount of time that training is needed.",
    },
    {
      name: "Temporary Activity Visa – is required to be in Australia for a short period to undertake specific activities – subclass 408.",
      description:
        "This visa is for people who require to come to Australia for short periods. There are several types of short stay activity visas such as: invited participant in an event, entertainment, performing in film or television, religious worker, domestic worker, sports trainee, elite player, coach, youth exchange and many others.",
    },
    {
      name: "Business Visa",
      description:
        "You have a business or investment background where you have either been involved in running your business or your investment activities in the last few years.You now want to come live in Australia and start a new business in Australia or buy into an existing one. Alternatively, as an investor, you wish to invest in Australia such as in Australian Government Bonds or other Australian investments. As you are not yet an Australian citizen or permanent resident, you may apply for a business visa. You may qualify for either a business innovation, investment visa or business talent visa.",
    },
    {
      name: "Skilled Person Visa",
      description:
        "If you are a skilled person who wants to live and work in Australia, you may apply for a Skilled Visa. Skilled Independent (189) and Skilled State Sponsored (190) visas are granted to skilled professionals who qualify for the General Skilled Migration Program and can score a minimum of 65 on the points test. The application involves being granted an invitation under Expression of Interest. (EOI) to lodge a Permanent Residence visa application. Skilled Provisional Visas (489) are granted for 4 years on the other hand, and can be applied for by skilled workers who want to work and live in regional parts of Australia. This provides a pathway to apply for permanent resident status after 3 years. This application involves being sponsored by a state or territory in regional Australia or by a family member living in regional Australia.",
    },
    {
      name: "Sponsorship Visa",
      description:
        "As an Australian business, you wish to sponsor a skilled overseas worker on a temporary or permanent basis. As an overseas skilled worker, you have an employer in Australia that would like to sponsor you to work for them. Sponsorship visas are classified as subclass 482, 186, and 187.",
    },
    {
      name: "Partner or Parent Visa",
      description:
        "A temporary or permanent partner visa is granted to the de facto partner, spouse or fiancé of an Australian citizen or permanent resident. A parent visa is also granted to parents of a child or children who are living in Australia to give them a chance to stay with their children permanently.All information listed here can be changed with changing nature of regulations and policies of Department of Home Affairs of Australia. Its highly recommended to always check department’s website – www.homeaffairs.gov.au and/or visit registered migration agent for detailed and updated information.",
    },
  ];

  return (
    <div className="bg-base-200 mt-0">
      <div
        className=" bg-no-repeat bg-center"
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <h2 className="text-center text-5xl text-white p-28">
          Visa Option for Australia
        </h2>
      </div>

      <div>
        <section className="dark:bg-gray-800 dark:text-gray-100">
          <div className="container flex flex-col justify-center p-4 mx-auto md:p-8">
            <p className="p-2 text-sm font-medium tracking-wider text-center uppercase">
              Australian VISA options and process
            </p>
            <h2 className="mb-12 text-4xl text-orange-500 font-bold leading-none text-center sm:text-5xl">
              VARIOUS VISA OPTIONS FOR AUSTRALIA{" "}
            </h2>
            <div className="flex flex-col divide-y  sm:px-8 lg:px-12 xl:px-32 divide-gray-700">
              <details>
                <summary className=" hover:ml-5 py-2 text-xl outline-none cursor-pointer focus:underline">
                  Student Visas for Australia
                </summary>
                <div className="px-4 pb-4">
                  {/* <p>Student Visas for Australia</p> */}
                  <ul className=" font-semibold text-blue-600  ml-10">
                    <a
                      target="_blank"
                      className="hover:text-blue-800"
                      href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/student-500"
                    >
                      <li> Student visa (SC 500 Visa) </li>
                    </a>
                    <li>
                      <a
                        target="_blank"
                        className="hover:text-blue-800"
                        href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/student-500"
                      >
                        Student Subsequent Visa ( SC 500 Visa)
                      </a>
                    </li>
                    <li>
                      {" "}
                      <a
                        target="_blank"
                        className="hover:text-blue-800"
                        href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/student-590"
                      >
                        Student Guardian visa (SC 590 Visa)
                      </a>{" "}
                    </li>
                  </ul>
                </div>
              </details>
              <details>
                <summary className=" hover:ml-5 py-2 text-xl outline-none cursor-pointer focus:underline">
                  Skilled Visas for Australia
                </summary>
                <div className="px-4 pb-4">
                  <details>
                    <summary className="py-2 outline-none cursor-pointer focus:underline">
                      Skilled Temporary Visa{" "}
                    </summary>
                    <div className="px-4 pb-4">
                      <ul className=" font-semibold text-blue-600  ml-10">
                        <li>
                          {" "}
                          <a
                            target="_blank"
                            className="hover:text-blue-800"
                            href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/temporary-graduate-485/graduate-work"
                          >
                            SC 485 Visa – Graduate Work Stream
                          </a>{" "}
                        </li>
                        <li>
                          {" "}
                          <a
                            target="_blank"
                            className="hover:text-blue-800"
                            href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/temporary-graduate-485/post-study-work"
                          >
                            SC 485 Visa – Post Study Work Visa
                          </a>{" "}
                        </li>
                        <li>
                          {" "}
                          <a
                            target="_blank"
                            className="hover:text-blue-800"
                            href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/student-590"
                          >
                            SC 476 Visa – Recognised Graduate visa
                          </a>{" "}
                        </li>
                      </ul>
                    </div>
                  </details>
                  <details>
                    <summary className="py-2 outline-none cursor-pointer focus:underline">
                      Skilled Visas (PR)
                    </summary>
                    <div className="px-4 pb-4">
                      <ul className=" font-semibold text-blue-600  ml-10">
                        <li>
                          {" "}
                          <a
                            target="_blank"
                            className="hover:text-blue-800"
                            href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/skilled-independent-189"
                          >
                            SC 189 Visa – Skilled Independent Visa
                          </a>{" "}
                        </li>
                        <li>
                          {" "}
                          <a
                            target="_blank"
                            className="hover:text-blue-800"
                            href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/skilled-nominated-190"
                          >
                            SC 190 Visa – Skilled Nominated Visa
                          </a>{" "}
                        </li>
                        <li>
                          {" "}
                          <a
                            target="_blank"
                            className="hover:text-blue-800"
                            href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/skilled-regional-887"
                          >
                            SC 887 Visa – Skilled Regional Visa
                          </a>{" "}
                        </li>
                        <li>
                          {" "}
                          <a
                            target="_blank"
                            className="hover:text-blue-800"
                            href="https://immi.homeaffairs.gov.au/visas/working-in-australia/visas-for-innovation/global-talent-independent-program"
                          >
                            Global Talent Independent program (GTI)
                          </a>{" "}
                        </li>
                      </ul>
                    </div>
                  </details>
                  <details>
                    <summary className="py-2 outline-none cursor-pointer focus:underline">
                      Regional Visas
                    </summary>
                    <div className="px-4 pb-4">
                      <ul className=" font-semibold text-blue-600  ml-10">
                        <li>
                          {" "}
                          <a
                            target="_blank"
                            className="hover:text-blue-800"
                            href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/skilled-work-regional-provisional-491/application#Eligibility"
                          >
                            SC 491 Visa – Family Stream
                          </a>{" "}
                        </li>
                        <li>
                          {" "}
                          <a
                            target="_blank"
                            className="hover:text-blue-800"
                            href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/skilled-work-regional-provisional-491"
                          >
                            SC 491 Visa – State Nomination
                          </a>{" "}
                        </li>
                        <li>
                          {" "}
                          <a
                            target="_blank"
                            className="hover:text-blue-800"
                            href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/skilled-regional-191"
                          >
                            SC 191 Visa
                          </a>{" "}
                        </li>
                      </ul>
                    </div>
                  </details>
                </div>
              </details>
              <details>
                <summary className=" hover:ml-5 py-2 text-xl outline-none cursor-pointer focus:underline">
                  Employer Sponsored Visas for Australia
                </summary>
                <div className="px-4 pb-4">
                  <details>
                    <summary className="py-2 outline-none cursor-pointer focus:underline">
                      SC 482 Visa{" "}
                    </summary>
                    <div className="px-4 pb-4">
                      <ul className=" font-semibold text-blue-600  ml-10">
                        <li>
                          {" "}
                          <a
                            target="_blank"
                            className="hover:text-blue-800"
                            href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/temporary-skill-shortage-482/medium-term-stream"
                          >
                            Medium Term TSS visa
                          </a>{" "}
                        </li>
                        <li>
                          {" "}
                          <a
                            target="_blank"
                            className="hover:text-blue-800"
                            href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/temporary-skill-shortage-482/short-term-stream"
                          >
                            Short Term TSS visa
                          </a>{" "}
                        </li>
                        <li>
                          {" "}
                          <a
                            target="_blank"
                            className="hover:text-blue-800"
                            href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/temporary-skill-shortage-482/labour-agreement-stream"
                          >
                            Labour Agreement for TSS visa
                          </a>{" "}
                        </li>
                      </ul>
                    </div>
                  </details>
                  <details>
                    <summary className="py-2 outline-none cursor-pointer focus:underline">
                      PR Visa
                    </summary>
                    <div className="px-4 pb-4">
                      <ul className=" font-semibold text-blue-600  ml-10">
                        <li>
                          {" "}
                          <a
                            target="_blank"
                            className="hover:text-blue-800"
                            href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/employer-nomination-scheme-186"
                          >
                            Employer Nomination – SC186 visa
                          </a>{" "}
                        </li>
                        <li>
                          {" "}
                          <a
                            target="_blank"
                            className="hover:text-blue-800"
                            href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/skilled-regional-191"
                          >
                            SC 191 VISA
                          </a>{" "}
                        </li>
                      </ul>
                    </div>
                  </details>
                  <details>
                    <summary className="py-2 outline-none cursor-pointer focus:underline">
                      SC 494 REGIONAL
                    </summary>
                    <div className="px-4 pb-4">
                      <ul className=" font-semibold text-blue-600  ml-10">
                        <li>
                          {" "}
                          <a
                            target="_blank"
                            className="hover:text-blue-800"
                            href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/skilled-employer-sponsored-regional-494"
                          >
                            Regional Employer Sponsored – SC 494 visa
                          </a>{" "}
                        </li>
                      </ul>
                    </div>
                  </details>
                </div>
              </details>
              <details>
                <summary className=" hover:ml-5 py-2 text-xl outline-none cursor-pointer focus:underline">
                  Family Visas for Australia
                </summary>
                <div className="px-4 pb-4">
                  <details>
                    <summary className="py-2 outline-none cursor-pointer focus:underline">
                      Partner Visa
                    </summary>
                    <div className="px-4 pb-4">
                      <p>Partner visa (onshore)</p>
                      <ul className=" font-semibold text-blue-600  ml-10">
                        <li>
                          {" "}
                          <a
                            target="_blank"
                            className="hover:text-blue-800"
                            href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/partner-onshore/temporary-820"
                          >
                            SC 820 VISA – TEMP.
                          </a>{" "}
                        </li>
                        <li>
                          {" "}
                          <a
                            target="_blank"
                            className="hover:text-blue-800"
                            href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/partner-onshore/permanent-801"
                          >
                            SC 801 VISA – PERMANENT
                          </a>{" "}
                        </li>
                      </ul>
                      <p>Partner visa (offshore)</p>
                      <ul className=" font-semibold text-blue-600  ml-10">
                        <li>
                          {" "}
                          <a
                            target="_blank"
                            className="hover:text-blue-800"
                            href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/partner-offshore/provisional-309"
                          >
                            SC 309 VISA – TEMP.
                          </a>{" "}
                        </li>
                        <li>
                          {" "}
                          <a
                            target="_blank"
                            className="hover:text-blue-800"
                            href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/partner-offshore/migrant-100"
                          >
                            SC 100 VISA – PERMANENT
                          </a>{" "}
                        </li>
                      </ul>
                      <ul>
                        <li>
                          {" "}
                          <a
                            target="_blank"
                            className="hover:text-blue-800"
                            href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/prospective-marriage-300"
                          >
                            Fiancé (Prospective Spouse subclass 300)
                          </a>{" "}
                        </li>
                      </ul>
                    </div>
                  </details>
                  <details>
                    <summary className="py-2 outline-none cursor-pointer focus:underline">
                      Parent Visa
                    </summary>
                    <div className="px-4 pb-4">
                      <ul className=" font-semibold text-blue-600  ml-10">
                        <li>
                          <a
                            target="_blank"
                            className="hover:text-blue-800"
                            href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/contributory-parent-143"
                          >
                            Contributory Parent visa (subclass 143)
                          </a>{" "}
                        </li>
                        <li>
                          <a
                            target="_blank"
                            className="text-blue-600 hover:text-blue-800"
                            href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/contributory-parent-temporary-173"
                          >
                            Contributory Parent (Temporary) visa (subclass 173)
                          </a>{" "}
                        </li>
                        <li>
                          <a
                            target="_blank"
                            className="text-blue-600 hover:text-blue-800"
                            href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/contributory-aged-parent-temporary-884"
                          >
                            Contributory Aged Parent visa- Temporary (subclass
                            884)
                          </a>{" "}
                        </li>
                        <li>
                          <a
                            target="_blank"
                            className="text-blue-600 hover:text-blue-800"
                            href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/aged-parent-804"
                          >
                            {" "}
                            Aged Parent visa (subclass 804)
                          </a>{" "}
                        </li>
                        <li>
                          <a
                            target="_blank"
                            className="text-blue-600 hover:text-blue-800"
                            href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/parent-103"
                          >
                            Parent visa (subclass 103)
                          </a>{" "}
                        </li>
                        <li>
                          <a
                            target="_blank"
                            className="text-blue-600 hover:text-blue-800"
                            href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/sponsored-parent-temporary-870"
                          >
                            Sponsored Parent 870 visa
                          </a>{" "}
                        </li>
                      </ul>
                    </div>
                  </details>
                  <details>
                    <summary className="py-2 outline-none cursor-pointer focus:underline">
                      Child Visa
                    </summary>
                    <div className="px-4 pb-4">
                      <ul className=" font-semibold text-blue-600  ml-10">
                        <a
                          className="hover:text-blue-800"
                          target="_blank"
                          href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/adoption-102"
                        >
                          {" "}
                          <li>Adoption visa (subclass 102)</li>
                        </a>
                        <li>
                          {" "}
                          <a
                            target="_blank"
                            className="hover:text-blue-800"
                            href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/child-101"
                          >
                            Child visa (subclass 101)
                          </a>{" "}
                        </li>
                        <li>
                          {" "}
                          <a
                            target="_blank"
                            className="hover:text-blue-800"
                            href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/child-802"
                          >
                            Child Onshore visa (Subclass 802)
                          </a>{" "}
                        </li>
                      </ul>
                    </div>
                  </details>
                </div>
              </details>
            </div>
          </div>
        </section>
      </div>
      <div className="mx-[10%] flex flex-col gap-8 my-16">
        {visaCategory.map((visa) => (
          <div key={visa.name}>
            <h2 className="text-xl font-medium">{visa.name}</h2>
            <p>{visa.description}</p>
          </div>
        ))}
      </div>
      <Link to="/contactUs">
        <h2 className="link-hover text-2xl text-center text-blue-600 font-medium mt-20">
          CONTACT OUR OFFICES NOW
        </h2>
      </Link>
    </div>
  );
};

export default VisaOptionForAustralia;
