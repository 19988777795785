import React from "react";
import bgImage from "../../../../assets/images/study in/australia/classification/bg.png";
import img1 from "../../../../assets/images/study in/australia/1.jpg";
import { Link } from "react-router-dom";
import EnquiryForms from "../../../shared/forms/enquiry/EnquiryForms";
const EducationSystem = () => {


  return (
    <div>
      <div
        className=" bg-no-repeat bg-center bg-cover"
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <h2 className="text-center text-5xl text-white p-28">
          Australian Education System
        </h2>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 lg:-ml-16 bg-white ">
        <div className="lg:col-span-3 mx-5  lg:ml-[15%] my-20 ">
          <h2 className="text-xl font-bold my-2">
            The Australian education system offers primary, secondary and
            tertiary education linked across the country and across the world.
          </h2>
          <p className=" my-1">
            The Australian education system provides primary, secondary and
            tertiary education.
            <br />
          </p>
          <h2 className="text-xl font-normal my-2">
            {" "}
            School education (Primary and Secondary)
          </h2>

          <p className="py-2">
            School education is similar across all of Australia with only minor
            variations between states and territories. School education (primary
            and secondary) is compulsory between the ages of six and sixteen
            (Year 1 to Year 9 or 10). School education is 13 years and divided
            into:
            <br />
          </p>
          <li>
            {" "}
            Primary school – Runs for seven or eight years, starting at
            Kindergarten/Preparatory through to Year 6 or 7.
          </li>
          <li>
            {" "}
            Secondary school – Runs for three or four years, from Years 7 to 10
            or 8 to 10.
          </li>
          <li>
            Senior secondary school – Runs for two years, Years 11 and 12.
          </li>

          <h2 className="text-xl font-medium my-2">Tertiary education</h2>
          <p className="my-2">
            Tertiary education includes both higher education (including
            universities) and vocational education and training (VET).
          </p>

          <h2 className="text-xl font-normal my-2">Language of instruction</h2>
          <p>
            English is the official language of Australia and the main language
            of instruction in the education system. Many schools offer bilingual
            programs or programs in other languages.
          </p>
          <h3 className="text-xl font-medium my-2">
            Australian Qualifications Framework
          </h3>
          <p>
            The Australian education system is distinguished from many other
            countries by the{" "}
            <a
              className="link link-hover text-orange-500 hover:text-orange-600"
              href="https://www.aqf.edu.au/what-is-the-aqf"
              target="_blank"
            >
              Australian Qualifications Framework (AQF)
            </a>
            . The AQF was established in 1995 and is a national policy that
            covers qualifications from the tertiary education sector (higher
            education and vocational education and training) in addition to the
            school-leaving certificate; the Senior Secondary Certificate of
            Education.
            <br />
            The AQF has 10 levels and links school, vocational and university
            education qualifications into one national system. This allows you
            to move easily from one level of study to the next, and from one
            institution to another, as long as you satisfy student visa
            requirements. It allows for choice and flexibility in career
            planning. All qualifications in the AQF help prepare you for both
            further study and your working life.
            <br />
            If you are studying an AQF qualification, you can be sure that your
            institution is Government-authorised and nationally accredited, and
            that your degree or other AQF qualification will be genuine.
            <br />
            Our institutions are linked across the country and across the world,
            which makes it easy to move throughout the education system between
            courses or institutions and formal agreement and recognition
            frameworks mean every step of the path will contribute to your
            future no matter what your study or career goals.
            <br />
          </p>
        </div>

        <div>
          <div className="avatar">
            <div className="w-full rounded my-12 mx-7">
              <img src={img1} alt="cover" />
            </div>
          </div>

          <ul clasName="">
            <Link to="/australian-education-system">
              <li className="text-white bg-orange-500 hover:bg-blue-600 rounded my-3 mx-7 p-2 text-center">
                Australian Education System
              </li>
            </Link>
            <Link to="/universities-and-colleges-in-australia">
              <li className="text-white bg-orange-500 hover:bg-blue-600 rounded my-3 mx-7 p-2 text-center">
                Universities and Colleges in Australia
              </li>
            </Link>
            <Link to="/australian-study-admissions-process">
              <li className="text-white bg-orange-500 hover:bg-blue-600 rounded my-3 mx-7 p-2 text-center">
                Australian Study Admissions Process
              </li>
            </Link>
            <Link to="/australian-study-document-checklist">
              <li className=" text-white bg-orange-500 hover:bg-blue-600 rounded my-3 mx-7 p-2 text-center">
                Australian Study Document Checklist
              </li>
            </Link>
            <Link to="/accommodation-help">
              {" "}
              <li className=" text-white bg-orange-500 hover:bg-blue-600 rounded my-3 mx-7 p-2 text-center">
                Accommodation Help
              </li>
            </Link>
            <Link to="/australian-student-visa-application-process">
              {" "}
              <li className="text-white bg-orange-500 hover:bg-blue-600 rounded my-3 mx-7 p-2 text-center">
                Australian Student Visa Application Process
              </li>
            </Link>
            <Link to="/about-australia-and-its-weather">
              {" "}
              <li className="text-white bg-orange-500 hover:bg-blue-600 rounded my-3 mx-7 p-2 text-center">
                About Australia and its Weather
              </li>
            </Link>
          </ul>
          <div>
           <EnquiryForms/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EducationSystem;
