import React from "react";

const Map = () => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 mb-10">
      <div>
        <h2 className="text-2xl font-semibold text-orange-500 text-center mt-10">
          Find us on Bangladesh
        </h2>
        <div className="w-40 h-[2px] bg-orange-600 mx-auto mb-10 mt-2"></div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14608.2011548557!2d90.35158469134392!3d23.74558604607329!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755bf55655f495f%3A0x913fb611c903e3bf!2sCronic%20Management%20Harbour!5e0!3m2!1sen!2sbd!4v1681621052195!5m2!1sen!2sbd"
          className="w-full h-[450px] border-8 border-gray-300"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      <div>
        <h2 className="text-2xl font-semibold text-orange-500 text-center mt-10">
          Find us on Australia
        </h2>
        <div className="w-40 h-[2px] bg-orange-600 mx-auto mb-10 mt-2"></div>
   
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3327.8098254475913!2d150.1602159!3d-33.4803017!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b120a59411f4ed9%3A0xc395127aa63651fe!2s35%20Main%20St%2C%20Lithgow%20NSW%202790%2C%20Australia!5e0!3m2!1sen!2sbd!4v1685258364131!5m2!1sen!2sbd" className="w-full h-[450px] border-8 border-gray-300"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade">

        </iframe>
      </div>
    </div>
  );
};

export default Map;
