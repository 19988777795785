import React from "react";
import bgImage from "../../../../assets/images/study in/australia/classification/bg.png";
import img1 from "../../../../assets/images/study in/australia/4.jpeg";
import { Link } from "react-router-dom";
import EnquiryForms from "../../../shared/forms/enquiry/EnquiryForms";

const AustralianStudyDocumentAndChecklist = () => {
 
  return (
    <div>
      <div
        className=" bg-no-repeat bg-center bg-cover"
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <h2 className="text-center text-5xl text-white p-28">
          Australian Study Document Checklist
        </h2>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 lg:-ml-16 bg-white ">
        <div className="lg:col-span-3 mx-5  lg:ml-[15%] my-20 ">
          <h2 className="text-xl font-normal my-2 ">
            Documents Needed to Start Your Student Visa Application for
            Australia
          </h2>

          <p className=" my-1">
            So you are all set for Australia. Getting into the college of choice
            is only the first step. What lies beyond the extensive application
            process is the equally important – the visa application for
            Australia. So, once you have selected which type of student visa
            applies to your intended study in Australia, there are a few basic
            documents you must have before you start the Visa Application
            Process. Since the process is online for Indian Students, it is
            important to have all the documents ready. Here’s what all:
            <br />
          </p>

          <ul className="list-decimal mt-6 mb-2">
            <li>
              <span className="font-bold">Confirmation of Enrolment (CoE)</span>{" "}
              <br />
              Confirmation of Enrolment is a certificate issued by the
              University/ Institute you would have applied to. The certificate
              essentially marks out that you are a registered student for the
              course. It is also the certificate that validates that the course
              you are applying for is approved and is registered under the
              Commonwealth Register of Institutions of Courses (CRICOS). This is
              mandatory for getting a Visa. <br />
              Also, in case you are intending to take up an English Language
              Course or a bridging course or any foundation course as a
              precursor to your main degree program, you need to provide an
              independent CoE for each of the courses you are intending to take.
            </li>
            <li className="my-6">
              <span className="font-bold">Valid Passport</span> <br />
              At the time of application, you must have a valid passport with at
              least 6 months of validity at the time. For example, say you are
              applying for a Visa on July 1, 2015. At the moment of your
              application, you must have a visa that is valid till at least
              about January 1, 2016. Also, you would need a valid passport
              during the time of your stay. In that instance, you can apply for
              a new passport after making your application for Visa and intimate
              the embassy when you receive new passport as well as update your
              account with the new passport number and details. <br />
              <br />
            </li>
            <li className="my-6">
              <span className="font-bold">English Language Proficiency</span>{" "}
              <br />
              Australian Visa mandatorily requires English Language Proficiency
              Certificates by Indian Students. Irrespective of the requirement
              of the course you are applying for, you would need to provide
              proof of English language proficiency at the time of filling the
              Visa Application Form.
              <br />
              <br />
              The Australian Immigration Department accepts 5 English Language
              Proficiency Tests, namely <br />
              <br />
              <ul className="list-disc">
                <li>
                  International English Language Testing System with a band of 6
                  in each of its sections as well as an overall score of minimum
                  6.
                </li>
                <li>
                  Occupational English Test (OET) with a score of at least B in
                  each of its sections
                </li>
                <li>
                  Test of English as a Foreign Language internet-based test
                  (TOEFL iBT) with the minimum score of 12 for listening, 13 for
                  reading, 21 for writing and 18 for speaking
                </li>
                <li>
                  Pearson Test of English (PTE) Academic with a score of minimum
                  50 in each of its sections
                </li>
                <li>
                  Cambridge English: Advanced (CAE) test with a minimum test
                  score of 169 in each of the four sub sections
                </li>
              </ul>
            </li>
            <p>
              Note: Irrespective of which of the first four examination score
              you provide, you must not have written these exams earlier than 3
              years at the time of the visa application. In case of CAE, the
              exam undertaken after 1 January 2015 and prior to visa application
              is accepted.
            </p>

            <li className="my-6">
              <span className="font-bold">Health Checkup and Insurance</span>{" "}
              <br />
              In order to receive a student visa for Australia, you need to
              provide proof that you are in good health. You can do so by
              providing a certificate of good health. The health checkup and
              certificate can only be provided by the empanelled doctors. You
              can make an appointment with the one closest to you for the same.
              Here’s a link for the Empanelled Doctors for India. You would be
              provided with a HAP id which you would need to provide at the time
              of filling the form.
              <br />
              <br />
              At times, you can also apply for a health checkup after starting
              the visa application. However, this would be a required for
              processing your visa. Getting it beforehand would ensure quicker
              processing.
              <br />
              <br />
              Apart from a health checkup, you would also need a Health
              Insurance or an OSHC (Overseas Students Health Cover). At the time
              of visa application, you should have an Overseas Student Health
              Cover (OSHC) for the duration of your visa. Depending on the
              duration of your health cover, your visa tenure would be settled
              so make sure that it covers the duration of your course. The
              average cost of OSHC is AUS$437 for 12 months for a single
              student.
              <br />
              <br />
            </li>
            <li className="my-6">
              <span className="font-bold">Proof of Funds</span> <br />
              Like many other counties, you would be needed to show proof that
              you have enough funds to cater to your education and living
              expenses while you are in Australia. You would be required to
              provide documents that declare that you have sufficient funds to
              cover the duration of your stay in Australia. Essentially, the
              funds should cover:
              <br />
              <br />
              <ul className="list-disc">
                <li>Travel costs to and from Australia</li>
                <li>Living costs</li>
                <li>Tuition costs</li>
              </ul>
              This is necessary and in case you are applying for a student loan
              to cover your expenses, you would need the letter from the bank to
              validate your financial capacity.
              <br />
              <br />
            </li>

            <li className="my-6">
              <span className="font-bold">Penal Clearance</span> <br />
              Penal Clearance or police clearance is also required by students
              planning to go and study in Australia. In order to validate your
              character and that you do not have any criminal record, you would
              need to provide a document, duly signed and attested by the
              regional passport office. For more information and address you can
              refer to this link.
              <br />
              <br />
            </li>

            <li className="my-6">
              <span className="font-bold">
                Certified Copies of Academic Transcripts and Passport
              </span>{" "}
              <br />
              You would also need true copies of all your academic documents and
              transcripts.
              <br />
              <br />
            </li>
            <li className="my-6">
              <span className="font-bold">Passport Size Photographs</span>{" "}
              <br />
              You would be required to submit 4 passport sized photographs. When
              submitting the same, you should ensure that
              <br />
              <br />
              <ul className="list-disc">
                <li>
                  These photographs are of the head and shoulders only against a
                  plain background.
                </li>
                <li>Your name is printed at the back of each photograph.</li>
              </ul>
            </li>

            <li className="my-6">
              <span className="font-bold">Credit/ Debit Card</span> <br />
              Last but not the least, you would need access to a debit or a
              credit card to make an online visa fee payment. At present the
              visa fee for Student Visa (573) is AUD 535. This, however is
              subject to change and students are advised to check the official
              website before they start the application process.
              <br />
              <br />
            </li>
          </ul>
        </div>

        <div>
          <div className="avatar">
            <div className="w-full rounded my-12 mx-7">
              <img src={img1} alt="cover" />
            </div>
          </div>

          <ul clasName="">
            <Link to="/australian-education-system">
              <li className="text-white bg-orange-500 hover:bg-blue-600 rounded my-3 mx-7 p-2 text-center">
                Australian Education System
              </li>
            </Link>
            <Link to="/universities-and-colleges-in-australia">
              <li className="text-white bg-orange-500 hover:bg-blue-600 rounded my-3 mx-7 p-2 text-center">
                Universities and Colleges in Australia
              </li>
            </Link>
            <Link to="/australian-study-admissions-process">
              <li className="text-white bg-orange-500 hover:bg-blue-600 rounded my-3 mx-7 p-2 text-center">
                Australian Study Admissions Process
              </li>
            </Link>
            <Link to="/australian-study-document-checklist">
              <li className=" text-white bg-orange-500 hover:bg-blue-600 rounded my-3 mx-7 p-2 text-center">
                Australian Study Document Checklist
              </li>
            </Link>
            <Link to="/accommodation-help">
              {" "}
              <li className=" text-white bg-orange-500 hover:bg-blue-600 rounded my-3 mx-7 p-2 text-center">
                Accommodation Help
              </li>
            </Link>
            <Link to="/australian-student-visa-application-process">
              {" "}
              <li className="text-white bg-orange-500 hover:bg-blue-600 rounded my-3 mx-7 p-2 text-center">
                Australian Student Visa Application Process
              </li>
            </Link>
            <Link to="/about-australia-and-its-weather">
              {" "}
              <li className="text-white bg-orange-500 hover:bg-blue-600 rounded my-3 mx-7 p-2 text-center">
                About Australia and its Weather
              </li>
            </Link>
          </ul>
          <div>
          <EnquiryForms/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AustralianStudyDocumentAndChecklist;
