import React from "react";
import bgImage from "../../../../assets/images/study in/australia/classification/bg.png";
import img1 from "../../../../assets/images/study in/australia/5.jpg";
import { Link } from "react-router-dom";
import EnquiryForms from "../../../shared/forms/enquiry/EnquiryForms";

const AustralianStudentVisaApplicationProcess = () => {
  return (
    <div>
      <div
        className=" bg-no-repeat bg-center bg-cover"
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <h2 className="text-center text-5xl text-white p-28">
          Australian Student Visa Application Process
        </h2>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 lg:-ml-16 bg-white ">
        <div className="lg:col-span-3 mx-5  lg:ml-[15%] my-20 ">
          <h2 className="text-xl font-normal my-2 ">
            Student VISA to Australia
          </h2>
          <p>
            If you are going to study in Australia for a longer period of time
            you will need to apply for a student visa. Here we guide you through
            the application process step by step.
            <br /> <br />
            If you are taking a short program, for example a language course, of
            maximum 12 weeks you can use a tourist visa. If you have a Working
            Holiday visa you are allowed to study for 16 weeks. For university
            studies and all longer programs you will need to apply for a student
            visa by following the steps below:
            <br />
            <br />
          </p>
          <h2 className="text-xl font-bold my-2 bg-gradient-to-r from-slate-200 p-1">
            1. Apply to a school in Australia and get a CoE
          </h2>
          <p className=" my-1">
            Before you can apply for a student visa to Australia you must be
            enrolled at an Australian school. After you have received an offer
            letter from a school, accepted the offer in writing and paid their
            tuition deposit the school will send you a CoE, Confirmation of
            Enrolment. You will need this document to begin your visa
            application. If you will take for several programs, for example
            first take a preparatory language course and then study at college,
            you will need one CoE for each program. <br />
            <br />
            We have a partner who can help you free of charge with the
            application to all universities in Australia fill out an information
            request so we can help you!
            <br />
          </p>

          <h2 className="text-xl font-bold my-4 bg-gradient-to-r from-slate-200 p-1">
            2. Create an account with the Australian immigration authorities
          </h2>
          <p className=" my-1">
            Nowadays you normally apply online for Australian visas. Before you
            start your visa application you have to create an account at{" "}
            <a
              className="link link-hover text-orange-500 hover:text-orange-600"
              href=" https://online.immi.gov.au/lusc/register"
              target="_blank"
            >
              https://online.immi.gov.au/lusc/register
            </a>
            <br /> <br />
            They will ask you to submit your name, phone number and e-mail
            address. Then you get to create a password and accept their
            conditions.
            <br />
          </p>
          <li>IELTS/PTE/TOEFL</li>
          <li>
            recognised degree completed academic degree (diploma, bachelor or
            master) Valid passport
          </li>
          <li>Resume</li>
          <li>Work Experience if applicable</li>

          <h2 className="text-xl font-bold my-4 bg-gradient-to-r from-slate-200 p-1">
            3. Make sure that you have all the needed documents in digital
            format
          </h2>
          <p className=" my-1">
            To apply for a student visa you will need the following documents:
            <br />
            <ul className="list-outside list-disc ml-6">
              <li>Your CoE</li>
              <li>
                An OSHC health insurance.{" "}
                <Link to="/student-health-cover">
                  See our study abroad insurance FAQ
                </Link>
              </li>
              <li>
                Passport that preferably should be valid for your whole stay in
                Australia since it will be troublesome to exchange your passport
                there when you study on a student visa
              </li>
              <li>
                Proof of temporary stay: on your application you will have to
                certify that you only plan to stay temporary in Australia. You
                can support this claim by attaching a proof of employment or a
                letter from your school in your home country or similar
                documents.
              </li>{" "}
              <br />
              Depending on where you apply from and where you will study
              additional documents will be needed. Some common documents you
              might need are: <br />
              <li>Additional id documents such as a birth certificate</li>
              <li>
                Proof that you can finance your studies, such as a bank
                statement
              </li>
              <li>
                Proof that you have enough knowledge to pass your intended
                studies, such as certified grade reports and IELTS-test result.
              </li>
              <li>
                Proof of previous employment such as an employment contract, pay
                checks etc.
              </li>
              <li>
                Documentation of criminal offenses if you have ever been
                convicted.
              </li>
              <li>
                To get a more complete document list go to
                https://immi.homeaffairs.gov.au/visas/web-evidentiary-tool and
                use the Document Checklist tool. There you fill in your
                nationality and school and get a detailed list of needed
                documents. You also need to choose who your sponsor is, choose
                “None of the above” unless you are sure that you belong to one
                of the other categories.
              </li>{" "}
              <br />
              If you apply online all documents should be uploaded in a digital
              format. So it is good if you have access to a scanner when you do
              the application or you can scan everything in advance. All
              documents should be translated to English by a certified
              translator. You can save your application and continue later if
              you miss some documents.
            </ul>
          </p>

          <h2 className="text-xl font-bold my-4 bg-gradient-to-r from-slate-200 p-1">
            4. Complete the visa application online
          </h2>
          <p className=" my-1">
            You should apply for a student visa subclass 500. The application
            can be made at the earliest 124 days before your course starts
            (according to the date on your CoE). You apply online. Read more and
            follow the instructions at:{" "}
            <a
              className="link link-hover text-orange-500 hover:text-orange-600"
              href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/student-500"
              target="_blank"
            >
              {" "}
              https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/student-500
            </a>
            <br />
            Thereafter you will be asked to submit personal details, family
            details, answer questions about your previous education and work
            experience, and submit details about your health and criminal
            record. You can save the application and take a break if you want.{" "}
            <br />
            On the first page you fill in you nationality and your CoE code(s).
            You should also choose your Education sector, for example ELICOS for
            language courses or Higher Education for courses that leads to a
            university degree. More information is available on the form.
          </p>

          <h2 className="text-xl font-bold my-4 bg-gradient-to-r from-slate-200 p-1">
            5. Pay the visa fee and get a TRN-number
          </h2>
          <p className=" my-1">
            When you have completed the application you should pay the
            application fee which currently is 575 AUD. The easiest is to pay it
            with a credit card online. Read more about the payment here:
            <br />
            <a
              className="link link-hover text-orange-500 hover:text-orange-600"
              href="https://immi.homeaffairs.gov.au/help-support/applying-online-or-on-paper/online/how-to-pay"
              target="_blank"
            >
              {" "}
              https://immi.homeaffairs.gov.au/help-support/applying-online-or-on-paper/online/how-to-pay
            </a>
            <br />
            When the application and payment is done you will get a receipt with
            a TRN-number (Transaction Reference Number). Make sure to save it so
            you can check the status of your application.
          </p>
          <h2 className="text-xl font-bold my-4 bg-gradient-to-r from-slate-200 p-1">
            6. Pay the visa fee and get a TRN-number
          </h2>
          <p className=" my-1">
            Depending on where you will study and where you live you might also
            need to do a health checkup and/or a visa interview. You will
            receive this information about this when you complete the
            application above if it applies to you.
            <br />
            Don’t do the health checkup before you have completed the
            application as you will need your TRN-number and you will get
            instructions on what you need to check. On this page you can find
            doctors in your country that are approved by the Australian
            Immigrations to do this checkup.
            <br />
          </p>

          <h2 className="text-xl font-bold my-4 bg-gradient-to-r from-slate-200 p-1">
            7. Get your visa decision
          </h2>
          <p className=" my-1">
            How long it takes to get you visa decision depends on where you are
            applying from and how busy the Australian immigration authorities
            are. In our experience you usually get your decision within one or a
            couple of weeks once your application is complete. You can check the
            status of your application by logging in to the immigration website
            (with the account you created in step 2) and if needed enter your
            TNR-number. Login at:
            <br />
            <a
              className="link link-hover text-orange-500 hover:text-orange-600"
              href="https://online.immi.gov.au/lusc/login"
              target="_blank"
            >
              {" "}
              https://online.immi.gov.au/lusc/login
            </a>
            <br />
            Normally the Australian authorities will notify you by e-mail once
            your visa decision is ready. Your visa is electronic so if you want
            a physical proof of you can print the grant letter that you receive
            online once your visa is approved.
          </p>

          <h2 className="text-xl font-bold my-4 bg-gradient-to-r from-slate-200 p-1">
            8. Travel to Australia
          </h2>
          <p className=" my-1">
            Once you have received your visa you can enter Australia at the
            earliest 90 days before the course start date printed on your CoE.
            If you have not booked accommodation through your school you should
            inform them of your address in Australia within 7 days of arriving
            there. Usually you have the right to stay in Australia for 30 days
            after your course ends or 60 days if your course lasts longer than
            10 months. All these date limits should be written on your visa and
            if that information differs from what we write here you should
            always follow the instructions on your visa. <br /> <br />
            <Link
              className="link link-hover text-orange-500 hover:text-orange-600"
              to="/education/study-in-australia"
            >
              Read more about studying in Australia!
            </Link>
            <br /> <br />
            Disclaimer: Please note that the Australian authorities can make
            changes to the visa application process without notifying us.
            Different rules can apply to different nationalities so always read
            the instructions from the immigration authorities carefully. The
            information in the article above is correct to the best of our
            knowledge but we cannot be held responsible for any error that might
            occur without our knowledge. When in doubt always trust the official
            sources and check with them if needed.
            <br />
            Normally the Australian authorities will notify you by e-mail once
            your visa decision is ready. Your visa is electronic so if you want
            a physical proof of you can print the grant letter that you receive
            online once your visa is approved.
          </p>
        </div>

        <div>
          <div className="avatar">
            <div className="w-full rounded my-12 mx-7">
              <img src={img1} alt="cover" />
            </div>
          </div>

          <ul clasName="">
            <Link to="/australian-education-system">
              <li className="text-white bg-orange-500 hover:bg-blue-600 rounded my-3 mx-7 p-2 text-center">
                Australian Education System
              </li>
            </Link>
            <Link to="/universities-and-colleges-in-australia">
              <li className="text-white bg-orange-500 hover:bg-blue-600 rounded my-3 mx-7 p-2 text-center">
                Universities and Colleges in Australia
              </li>
            </Link>
            <Link to="/australian-study-admissions-process">
              <li className="text-white bg-orange-500 hover:bg-blue-600 rounded my-3 mx-7 p-2 text-center">
                Australian Study Admissions Process
              </li>
            </Link>
            <Link to="/australian-study-document-checklist">
              <li className=" text-white bg-orange-500 hover:bg-blue-600 rounded my-3 mx-7 p-2 text-center">
                Australian Study Document Checklist
              </li>
            </Link>
            <Link to="/accommodation-help">
              {" "}
              <li className=" text-white bg-orange-500 hover:bg-blue-600 rounded my-3 mx-7 p-2 text-center">
                Accommodation Help
              </li>
            </Link>
            <Link to="/australian-student-visa-application-process">
              {" "}
              <li className="text-white bg-orange-500 hover:bg-blue-600 rounded my-3 mx-7 p-2 text-center">
                Australian Student Visa Application Process
              </li>
            </Link>
            <Link to="/about-australia-and-its-weather">
              {" "}
              <li className="text-white bg-orange-500 hover:bg-blue-600 rounded my-3 mx-7 p-2 text-center">
                About Australia and its Weather
              </li>
            </Link>
          </ul>
          <div>
           <EnquiryForms/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AustralianStudentVisaApplicationProcess;
