import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaFacebookSquare } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { BiRightArrow } from "react-icons/bi";
import { AiOutlinePhone } from "react-icons/ai";
import { pcMenuItems } from "./Mylinks";
import logo from "../../../assets/images/logo.png";
import flags1 from "../../../assets/images/flags/flag-1.png";
import flags2 from "../../../assets/images/flags/flag-2.png";
import flags3 from "../../../assets/images/flags/flag-3.png";
import flags4 from "../../../assets/images/flags/flag-4.png";

function NavBar() {
  const [isOpen, setIsOpen] = useState(false);

  const onclick = () => {
    setIsOpen(false);
  };

  const mobileMenuItems = (
    <>
      <li className="font-semibold ">
        <Link onClick={onclick} to="/home">
          Home
        </Link>
      </li>
      <li className="font-semibold">
        <span tabIndex={0}>
          About Us
          <svg
            className="fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
          >
            <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
          </svg>
        </span>
        <ul className="p-2 nav-menu bg-base-300  z-50 hover:z ">
          <li>
            <Link onClick={onclick} to="/aboutUs">
              About Us
            </Link>
          </li>
          <li>
            <Link onClick={onclick} to="/aboutUs/whyHurborEducation">
              Why harbour <br />
              Education
            </Link>
          </li>
          <li>
            <Link onClick={onclick} to="/aboutUs/ourVission">
              Our Vision
            </Link>
          </li>
          <li>
            <Link onClick={onclick} to="/aboutUs/ourMission">
              Our Mission
            </Link>
          </li>
          <li>
            <Link onClick={onclick} to="/aboutUs/migration-and-visa-help">
              Migration and Visa
              <br /> Help
            </Link>
          </li>
          <li>
            <Link onClick={onclick} to="/aboutUs/our-office">
              Our Office
            </Link>
          </li>
        </ul>
      </li>
      <li className="font-semibold ">
        <span tabIndex={0}>
          Education
          <svg
            className="fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
          >
            <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
          </svg>
        </span>
        <ul className="p-2 nav-menu bg-base-300 z-50">
          <li>
            <Link onClick={onclick} to="/education">
              Education
            </Link>
          </li>
          <li>
            <Link onClick={onclick} to="/education/study-in-australia">
              Study in Australia
            </Link>
          </li>
          <li>
            <Link onClick={onclick} to="/education/study-in-canada">
              Study in Canada
            </Link>
          </li>
          <li>
            <Link onClick={onclick} to="/education/study-in-unitedKingdom">
              Study in United
              <br /> Kingdom
            </Link>
          </li>
          <li>
            <Link onClick={onclick} to="/education/study-in-unitedStates">
              Study in United
              <br />
              States
            </Link>
          </li>
        </ul>
      </li>
      <li className="font-semibold">
        <span tabIndex={0}>
          Migration
          <svg
            className="fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
          >
            <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
          </svg>
        </span>

        <ul className="p-2 nav-menu bg-base-300 z-50">
          <li>
            <Link onClick={onclick} to="/migration">
              Migration
            </Link>
          </li>
          <li>
            <Link onClick={onclick} to="/migration/visa-option-for-australia">
              Visa Option for
              <br /> Australia
            </Link>
          </li>
          <li>
            <Link onClick={onclick} to="/migration/visa-option-for-canada">
              Visa Option for
              <br /> Canada
            </Link>
          </li>
        </ul>
      </li>
      <li className="font-semibold">
        <span to="/otherService" tabIndex={0}>
          Other Services
          <svg
            className="fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
          >
            <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
          </svg>
        </span>
        <ul className="p-2 nav-menu bg-base-300 z-50">
          <li>
            <Link onClick={onclick} to="/otherService">
              Other Services
            </Link>
          </li>
          <li>
            <Link onClick={onclick} to="/otherService/student-health-cover">
              Overseas Student
              <br /> Health Cover (OSHC)
            </Link>
          </li>
          <li>
            <Link onClick={onclick} to="/otherService/visitor-health-cover">
              Overseas Visitor
              <br /> Health Cover (OSHC)
            </Link>
          </li>
          <li>
            <Link onClick={onclick} to="/otherService/sc-485-sc-482-health">
              SC 485 / SC 482 <br /> Health Cover options
            </Link>
          </li>
          <li>
            <Link onClick={onclick} to="/otherService/job-help">
              Job Help
            </Link>
          </li>
          <li>
            <Link onClick={onclick} to="/otherService/accommodation-help">
              Accommodation Help
            </Link>
          </li>
          <li>
            <Link onClick={onclick} to="/otherService/airport-pick-u-services">
              Airport Pick up <br /> Services
            </Link>
          </li>
        </ul>
      </li>
      <li className="font-semibold">
        <Link onClick={onclick} to="/learningCenter">
          Learning Center
        </Link>
      </li>

      <li className="font-semibold">
        <Link onClick={onclick} to="/blog">
          Blog
        </Link>
      </li>
      <li className="font-semibold">
        <Link onClick={onclick} to="/contactUs">
          Contact Us
        </Link>
      </li>
    </>
  );

  return (
    <>
      <nav className="bg-amber-600 border-gray-200 dark:bg-gray-900 ">
        <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl pb-10 lg:p-2">
          <div className="flex lg:flex-wrap  lg:gap-4 gap-1 lg:justify-between  items-center  max-w-screen-xl p-4 text-white font-medium mb-4 lg:mb-12 ">
            <span>
              <HiOutlineMail className=" lg:inline hidden mr-2 h-6 w-6" />
              <a href="mailto:admin@harbour.com.bd">
              admin@harbour.com.bd
              </a>
            </span>
            <span>
              <AiOutlinePhone className=" lg:inline hidden h-6 w-6" />
              <a
                href="tel:+88 02222243443"
                className="ml-6 lg:ml-0 text-sm text-white dark:text-white hover:underline"
              >
                +88 02222243443
              </a>
            </span>
          </div>
          <div className="flex items-center text-white gap-2 mb-10">
            <span className="ml-3">
              Study in <BiRightArrow className="inline mr-2"></BiRightArrow>
            </span>
            <Link onClick={onclick} to="/education/study-in-australia">
              <img
                className="inline-flex items-center rounded-full"
                src={flags1}
                alt="flags"
              />
            </Link>
            <Link onClick={onclick} to="/education/study-in-canada">
              <img
                className="inline-flex items-center rounded-full"
                src={flags2}
                alt="flags"
              />
            </Link>
            <Link onClick={onclick} to="/education/study-in-unitedKingdom">
              <img
                className="inline-flex items-center rounded-full"
                src={flags3}
                alt="flags"
              />
            </Link>
            <Link onClick={onclick} to="/education/study-in-unitedStates">
              <img
                className="inline-flex items-center rounded-full"
                src={flags4}
                alt="flags"
              />
            </Link>
          </div>
          <button className="bg-[#265e9a]  hover:bg-[#2260a3] border-orange-400 shadow-xl  p-2 rounded text-white mr-2 mb-10">
            <a
              target="_blank"
              href="https://www.facebook.com/Harbour-Education-and-Migration-101331986220799/"
            >
              Follow us on <FaFacebookSquare className="inline" />
            </a>
          </button>
        </div>
      </nav>

      <nav className="navbar h-28 bg-base-300">
        <div className="md:w-auto w-full flex justify-between relative">
          <Link onClick={onclick} to="/" className="flex items-center">
            <img className="w-4/5 h-[220px] mt-1" src={logo} alt="logo" />
          </Link>
          <button
            className="dropdown  block lg:hidden"
            onClick={() => setIsOpen(!isOpen)}
          >
            <svg
              className={`fill-current h-8 w-8 `}
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              {!isOpen ? (
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              ) : (
                <path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z" />
              )}
            </svg>
          </button>
          <ul
            tabIndex={0}
            className={`menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52 right-44 top-48 z-40 block lg:hidden absolute ${
              !isOpen ? "hidden" : "block"
            } `}
          >
            {mobileMenuItems}
          </ul>
        </div>
        <div className="navbar-center hidden lg:flex">
          <ul className="menu menu-horizontal p-0">{pcMenuItems}</ul>
        </div>
      </nav>
    </>
  );
}

export default NavBar;
