import React from "react";
import flags1 from "../../../assets/images/contactUs/bd_flags.webp";
import flags2 from "../../../assets/images/contactUs/Australia_flags.webp";
import { GiRotaryPhone } from "react-icons/gi";
import { BsWhatsapp } from "react-icons/bs";
import { toast } from "react-hot-toast";

const OurOffice = () => {
  const handlerSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const name = form.user_name.value;
    const email = form.email.value;
    const message = form.message.value;
    //  console.log(email,name,message)
    toast.success("Successfully delivered your message.");
    form.reset();
  };

  return (
    <div className="bg-base-200">
      <div className="my-20 mx-[10%] ">
        <div className=" p-6 ">
          <p className="text-center text-orange-500 font-medium text-xl">
            HARBOUR EDUCATION & MIGRATION
          </p>
          <div className="w-36 h-[2px] bg-orange-600 mx-auto mt-2"></div>

          <div className="flex flex-col lg:flex-row gap-8 ">
            <div className="w-full lg:w-1/2  mb-6">
              <div>
                <div className="flex flex-col justify-center items-center text-center my-10 border border-orange-400 p-8">
                  <div className="space-y-2">
                    <div className="dark:text-gray-400 text-xl">
                      Visit Our Bangladesh Office
                    </div>
                    <div className="flex  justify-center items-center">
                      <img src={flags1} alt="" className="p-2 h-28 md:h-24" />
                    </div>
                  </div>
                  <div className="flex items-center justify-center flex-col leading-loose font-semibold">
                    <h2 className="text-lg font-bold">Harbour Education & Migration</h2>
                    <p>Office:211 West Dhanmondi</p>
                    <p>Road:10/A(new) 19(Old)</p>
                    <p>
                      Dhanmondi-Dhaka-1209
                      <span className="hidden lg:inline">,</span>
                      <span className="lg:inline block ">Bangladesh</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full lg:w-1/2">
              <div>
                <div>
                  <div className="flex flex-col justify-center items-center text-center my-10 border border-orange-400 p-8">
                    <div className="space-y-2">
                      <div className="dark:text-gray-400 text-xl">
                        Visit Our Australia Office
                      </div>
                      <div className="flex  justify-center items-center">
                        <img src={flags2} alt="" className="p-2 h-28 md:h-24" />
                      </div>
                    </div>
                    <div className="flex items-center justify-center flex-col leading-loose font-semibold">
                      <h2 className="text-lg font-bold">Harbour Education & Migration</h2>
                      <p>Office: 35 Main st</p>
                      <p>City:Lithgow </p>
                      <p>NSW 2790,Australia</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* google map */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 mb-10">
        <div>
          <h2 className="text-2xl font-semibold text-orange-500 text-center mt-10">
            Find us on Bangladesh
          </h2>
          <div className="w-40 h-[2px] bg-orange-600 mx-auto mb-10 mt-2"></div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14608.2011548557!2d90.35158469134392!3d23.74558604607329!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755bf55655f495f%3A0x913fb611c903e3bf!2sCronic%20Management%20Harbour!5e0!3m2!1sen!2sbd!4v1681621052195!5m2!1sen!2sbd"
            className="w-full h-[450px] border-8 border-gray-300"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <div>
          <h2 className="text-2xl font-semibold text-orange-500 text-center mt-10">
            Find us on Australia
          </h2>
          <div className="w-40 h-[2px] bg-orange-600 mx-auto mb-10 mt-2"></div>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3327.8098254475913!2d150.1602159!3d-33.4803017!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b120a59411f4ed9%3A0xc395127aa63651fe!2s35%20Main%20St%2C%20Lithgow%20NSW%202790%2C%20Australia!5e0!3m2!1sen!2sbd!4v1685258364131!5m2!1sen!2sbd" className="w-full h-[450px] border-8 border-gray-300"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade">
        </iframe>
        </div>
      </div>

      {/* contact form */}

      <section className=" px-2 py-20 dark:bg-gray-800 dark:text-gray-50">
        <div className="grid max-w-6xl grid-cols-1 px-6 mx-auto lg:px-8 md:grid-cols-2 md:divide-x">
          <div className="py-6 md:py-0 md:px-6">
            <h1 className="text-4xl font-bold">Get in touch</h1>
            <p className="pt-2 pb-4">
              Fill in the form to start a conversation
            </p>
            <div className="space-y-4">
              <p className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="w-5 h-5 mr-2 sm:mr-6"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span>Dhaka,Bangladesh</span>
              </p>

              <p className="flex items-center">
                <GiRotaryPhone className=" h-5 w-5 inline mr-4 "></GiRotaryPhone>
                <span>+8802222243443</span>
              </p>
              <p className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="w-5 h-5 mr-2 sm:mr-6"
                >
                  <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"></path>
                </svg>
                <span>+8801896122100</span>
              </p>
              <p className="flex items-center">
                <a
                  href="https://wa.me/8801896122111"
                  class="whatsapp_float"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <BsWhatsapp className=" h-5 w-5 inline mr-4 " />
                  <span>+8801896122111</span>
                </a>
              </p>

              <p className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="w-5 h-5 mr-2 sm:mr-6"
                >
                  <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"></path>
                </svg>
                <span>+61 02 8590 4971(Aus)</span>
              </p>

              <p className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="w-5 h-5 mr-2 sm:mr-6"
                >
                  <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                  <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
                </svg>
                <span>harbourgroupbd@gmail.com</span>
              </p>
            </div>
          </div>
          <form
            onSubmit={handlerSubmit}
            className="flex flex-col py-3 space-y-6 md:py-0 md:px-6 ng-untouched ng-pristine ng-valid"
          >
            <label className="block">
              <span className="mb-1">Your Name</span>
              <input
                type="text"
                name="user_name"
                required
                className="input input-bordered w-full"
              />
            </label>
            <label className="block">
              <span className="mb-1">Email address</span>
              <input
                type="email"
                name="email"
                required
                className="input input-bordered w-full"
              />
            </label>
            <label className="block">
              <span className="mb-1">Message</span>
              <textarea
                name="message"
                rows="3"
                required
                className=" w-full textarea textarea-bordered"
              ></textarea>
            </label>
            <input
              type="submit"
              value="Send Message"
              className="btn btn-outline font-medium text-sm rounded-lg lg:px-8 lg:btn-md "
            ></input>
          </form>
        </div>
      </section>
    </div>
  );
};

export default OurOffice;
