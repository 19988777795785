import React from "react";
import bgImage from "../../../assets/images/study in/inner-page-banner.jpg";
import testimonial from "../../../assets/images/student/student-1.png";
import eduSystem from "../../../assets/images/study in/australia/1.jpg";
import uniCollege from "../../../assets/images/study in/australia/2.jpg";
import admission from "../../../assets/images/study in/australia/3.jpg";
import document from "../../../assets/images/study in/australia/4.jpeg";
import visaProcess from "../../../assets/images/study in/australia/5.jpg";
import weather from "../../../assets/images/study in/australia/6.png";
import { Link } from "react-router-dom";
import { toast } from "react-hot-toast";

const Australia = () => {

  const studyInAustralia = [
    {
      name: "Australian Education System",
      image: eduSystem,
      description:
        "The Australian education system offers primary,secondary, and tertiary education linked across the country and globally.",
      to: "/australian-education-system",
    },
    {
      name: "Universities and Colleges in Australia ",
      image: uniCollege,
      description:
        "Find comprehensive guidance for choosing a university,course, and academic profile from Australian universities.",
      to: "/universities-and-colleges-in-australia",
    },
    {
      name: "Australian Study Admissions Process",
      image: admission,
      description:
        "Harbour Education promotes Australia as a preferredchoice for international students due to its popularity as a global education destination.",
      to: "/australian-study-admissions-process",
    },
    {
      name: "Australian Study Document Checklist",
      image: document,
      description:
        "After successfully securing admission into your preferredcollege, you have only taken the first step towards youracademic journey in Australia.Preparing for college admission is only the first step towards achieving academic success in Australia.",
      to: "/australian-study-document-checklist",
    },
    {
      name: "Australian Student Visa Application Process",
      image: visaProcess,
      description:
        " We provide comprehensive guidance on the step-by-step process of applying for a student visa in Australia, for those who plan to study there for an extended period of time.We assist students with the step-by-step process of applying for a student visa if they plan to study in Australia for an extended period of time.",
      to: "/australian-student-visa-application-process",
    },
    {
      name: "About Australia and its Weather",
      image: weather,
      description:
        "This section serves as a valuable resource for learning about Australia, including its history,religion, flag, economy, government, and more.",
      to: "/about-australia-and-its-weather",
    },
  ];


const handlerSubmit=(e)=>{
  e.preventDefault()
  const form = e.target;
  toast.success("Thank you for your interest, we will contact you soon.");
  form.reset()
  console.log(e)
}


  return (
    <div className=" bg-white ">
      <div
        className=" bg-no-repeat bg-center "
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <h2 className="text-center text-5xl text-white p-28">
          Study In Austraila
        </h2>
      </div>

      <div className="grid grid-cols-3 gap-4">
        <div className=" col-span-3 lg:col-span-2 grid grid-cols-1 gap-6  p-5 mx-auto sm:p-10 md:p-16 dark:bg-gray-800 dark:text-gray-100">
          {studyInAustralia.map((australia) => (
            <div
              key={australia.name}
              className="card lg:card-side bg-base-200 shadow-xl"
            >
              <figure>
                <img
                  className="w-full "
                  src={australia.image}
                  alt="education"
                />
              </figure>
              <div className="card-body  lg:w-[50%]">
                <h2 className="card-title text-orange-500">{australia.name}</h2>
                <p>{australia.description.split(0.1)}</p>
                <div className="card-actions justify-end">
                  <Link to={australia.to}>
                    <button className=" btn hover:bg-red-500 text-orange-500 outline-orange-500 rounded-full btn-outline ">
                      Read More
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="hidden lg:block">
          <div className="border border-orange-500 bg-base-200 shadow-2xl w-3/4 mt-16 p-5 ">
            <div>
              <p className="text-center text-lg">
                Interested in Studying in <br />
                <span className="text-2xl"> Austraila?</span>
              </p>
            </div>
            <div className="divider"></div>

            <p>
              Fill the below form and get in touch with Harbour Education
              counsellor.
            </p>

            <form
             onSubmit={handlerSubmit}
             className="p-3">
              <label className="block">
                <span className="mb-1">Full Name *</span>
                <input
                  type="text"
                  name="user_name"
                  placeholder="Enter your full name"
                  required
                  className="input w-full border border-orange-500 my-2"
                />
              </label>
              <label className="block">
                <span className="mb-1">Email address *</span>
                <input
                  type="email"
                  name="user_email"
                  placeholder="Enter your email"
                  required
                  className="input w-full border border-orange-500 my-2"
                />
              </label>
              <label className="block">
                <span className="mb-1">Mobile Number *</span>
                <input
                  type="phone"
                  name="mobile"
                  placeholder="Enter your mobile number"
                  required
                  className="input w-full border border-orange-500 my-2"
                />
              </label>
              <label className="block">
                <span className="mb-1">Message *</span>
                <textarea
                  name="message"
                  rows="3"
                  placeholder="Write your message here"
                  required
                  className=" w-full textarea border border-orange-500 my-2"
                ></textarea>
              </label>
              <input
                type="submit"
                value="Send Message"
                className="border border-orange-500 text-orange-500  hover:bg-zinc-100 font-medium text-sm rounded-lg lg:px-8 lg:btn-md "
              ></input>
            </form>
          </div>
          <div className=" w-3/4 mt-16">
            <h2 className=" text-lg p-3 text-white font-bold">Testimonials</h2>
            <div className="italic p-2">
              <div className="avatar ml-[30%]">
                <div className="w-24 mask mask-squircle ">
                  <img
                    className="flex mx-auto"
                    src={testimonial}
                    alt="student"
                  />
                </div>
              </div>

              <blockquote className="lg:mx-[20%] text- italic font-semibold text-gray-900 dark:text-white">
                <svg
                  aria-hidden="true"
                  className="w-10 h-10 text-gray-400 dark:text-gray-600"
                  viewBox="0 0 24 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z"
                    fill="currentColor"
                  />
                </svg>
                <p>
                  “I loved Harbour Education, because you really become part of
                  a family with the rest of your Harbour mates. I thought that
                  was such a unique part of my time abroad when a lot of my
                  friends in other programs had hundreds of students and felt
                  little to no connection with their program. I really
                  appreciated that everyone in Harbour was so close and
                  comfortable with each other.”
                </p>
              </blockquote>
              <p className="text-lg text-center  font-semibold text-orange-500">
                Soheb Islam
              </p>
              <address className="text-center">
                <small className="opacity-[.5] text-center">
                  13/kha,Rayer Bazar,Dhanmondi,Dhaka{" "}
                </small>
              </address>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Australia;
