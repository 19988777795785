import React from "react";
import "react-multi-carousel/lib/styles.css";
import austrailaImage from "../../../assets/images/countrys-images/study-in-australia.jpg";
import canadaImage from "../../../assets/images/countrys-images/study-in-canada.jpg";
import unitedKingdomImage from "../../../assets/images/countrys-images/study-in-united-kingdom.jpg";
import unitedStateImage from "../../../assets/images/countrys-images/stuydy-in-unitated-state.jpg";
import { Link } from "react-router-dom";

import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
// ..
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "./CountrySlider.css";

// import required modules
import { Pagination } from "swiper";

AOS.init();

const countrys = [
  {
    name: "Austraila",
    image: austrailaImage,
    description:
      " Australian qualifications and institutions are recognized globally for their high quality and excellence in academics.",
    btnText: "Study in Austraila",
    to: "/education/study-in-australia",
  },
  {
    name: "Canada",
    image: canadaImage,
    description:
      "Canadians place great importance on learning, and have developed one of the world’s finest education system with high standards.",
    btnText: "Study in Canada",
    to: "/education/study-in-canada",
  },
  {
    name: "United Kingdom",
    image: unitedKingdomImage,
    description:
      "UK institutions consistently rank among the best in the world and qualifications are internationally valued and recognized.",
    btnText: "Study in United Kingdom",
    to: "/education/study-in-unitedKingdom",
  },
  {
    name: "United States",
    image: unitedStateImage,
    description:
      "Universities in the U.S.pride themselves on being at the forefront of technology,research & techniques and in making the best possible equipmen to students.",
    btnText: "Study in United States",
    to: "/education/study-in-unitedStates",
  },
];

const CountrySlider = () => {
  return (
    <Swiper
      slidesPerView={1}
      spaceBetween={10}
      pagination={{
        clickable: true,
      }}
      breakpoints={{
        640: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 50,
        },
      }}
      modules={[Pagination]}
      className="mySwiper"
    >
      {countrys.map((country, i) => (
        <SwiperSlide key={i}>
          <div className=" card card-compact bg-base-200 shadow-xl my-10 ">
            <div
              data-aos="zoom-in"
              data-aos-easing="linear"
              data-aos-duration="1500"
            >
              <figure>
                <img
                  className="w-full  hover:aspect-[16/12]  object-cover object-top rounded-t-lg"
                  src={country.image}
                  alt="country"
                />
              </figure>
            </div>

            <div className="card-body bg-[#eee]">
              <h2 className="card-title">{country.name}</h2>
              <p className="text-left">{country.description}</p>
              <div className="card-actions justify-end">
                <Link to={country.to}>
                  <button className=" btn hover:bg-red-500 text-orange-500 outline-orange-500 rounded-full btn-outline ">
                    {country.btnText}
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default CountrySlider;
