import { Link } from "react-router-dom";


   

export const pcMenuItems = (
  <>
    <li className="font-semibold">
      <Link to="/home">Home</Link>
    </li>
    <li className="font-semibold">
      <Link to="/aboutUs" tabIndex={0}>
        About Us
        <svg
          className="fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
        >
          <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
        </svg>
      </Link>
      <ul className="p-2 nav-menu bg-base-300  z-50 hover:z ">
        <li>
          <Link to="/aboutUs/whyHurborEducation">Why harbour Education</Link>
        </li>
        <li>
          <Link to="/aboutUs/ourVission">Our Vision</Link>
        </li>
        <li>
          <Link to="/aboutUs/ourMission">Our Mission</Link>
        </li>
        <li>
          <Link to="/aboutUs/migration-and-visa-help">
            Migration and Visa Help
          </Link>
        </li>
        <li>
          <Link to="/aboutUs/our-office">Our Office</Link>
        </li>
      </ul>
    </li>
    <li className="font-semibold ">
      <Link to="/education" tabIndex={0}>
        Education
        <svg
          className="fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
        >
          <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
        </svg>
      </Link>
      <ul className="p-2 nav-menu bg-base-300 z-50">
        <li>
          <Link to="/education/study-in-australia">Study in Australia</Link>
        </li>
        <li>
          <Link to="/education/study-in-canada">Study in Canada</Link>
        </li>
        <li>
          <Link to="/education/study-in-unitedKingdom">
            Study in United Kingdom
          </Link>
        </li>
        <li>
          <Link to="/education/study-in-unitedStates">
            Study in United States
          </Link>
        </li>
      </ul>
    </li>
    <li className="font-semibold">
      <Link to="/migration" tabIndex={0}>
        Migration
        <svg
          className="fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
        >
          <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
        </svg>
      </Link>

      <ul className="p-2 nav-menu bg-base-300 z-50">
        <li>
          <Link to="/migration/visa-option-for-australia">
            Visa Option for Australia
          </Link>
        </li>
        <li>
          <Link to="/migration/visa-option-for-canada">
            Visa Option for Canada
          </Link>
        </li>
      </ul>
    </li>
    <li className="font-semibold">
      <Link to="/otherService" tabIndex={0}>
        Other Services
        <svg
          className="fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
        >
          <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
        </svg>
      </Link>
      <ul className="p-2 nav-menu bg-base-300 z-50">
        <li>
          <Link to="/otherService/student-health-cover">
            Overseas Student Health Cover (OSHC)
          </Link>
        </li>
        <li>
          <Link to="/otherService/visitor-health-cover">
            Overseas Visitor Health Cover (OSHC)
          </Link>
        </li>
        <li>
          <Link to="/otherService/sc-485-sc-482-health">
            SC 485 / SC 482 Health Cover options
          </Link>
        </li>
        <li>
          <Link to="/otherService/job-help">Job Help</Link>
        </li>
        <li>
          <Link to="/otherService/accommodation-help">
            Accommodation Help
          </Link>
        </li>
        <li>
          <Link to="/otherService/airport-pick-u-services">
            Airport Pick up Services
          </Link>
        </li>
      </ul>
    </li>
    <li className="font-semibold">
      <Link to="/learningCenter">Learning Center</Link>
    </li>

    <li className="font-semibold">
      <Link to="/blog">Blog</Link>
    </li>
    <li className="font-semibold">
      <Link to="/contactUs">Contact Us</Link>
    </li>
  </>
);
