import { createBrowserRouter } from "react-router-dom";
import AboutUs from "../component/aboutUs/AboutUs/AboutUs";
import WhyHarbourEducation from "../component/aboutUs/whyHarbourEducation/WhyHarbourEducation";
import Home from "../component/home";
import Main from "../layout/main/Main";
import Australia from "../component/education/studyInAustralia/Australia";
import Education from "../component/education/Education";
import Canada from "../component/education/studyInCanada/Canada";
import UnitatedKingdom from "../component/education/studyInUnitatedKingdom/UnitedKingdom";
import UnitatedStates from "../component/education/studyInUnitatedStates/UnitatedStates";
import Blog from "../component/blog/Blog";
import LearningCenter from "../component/LearningCenter/LearningCenter";
import Migration from "../component/home/migration/Migration";
import VisaOptionForAustralia from "../component/home/migration/visaOptionFOrAustralia/VisaOptionForAustralia";
import VisaOptionForCanada from "../component/home/migration/visaOptionForCanada/VisaOptionForCanada";
import OtherService from "../component/otherService/OtherService";
import OurMission from "../component/aboutUs/OurMission/OurMission";
import OurVission from "../component/aboutUs/OurVision/OurVission";
import ContactUs from "../component/contactUs/ContactUs";
import StudentHealthConver from "../component/otherService/studentHealthCover/StudentHealthConver";
import VisitorHealthConver from "../component/otherService/visitorHealthConver/VisitorHealthConver";
import Sc485sc482health from "../component/otherService/sc-485-sc-482-health/Sc485sc482health";
import JobHelp from "../component/otherService/job-help/JobHelp";
import AccommodationHelp from "../component/otherService/accommodation-help/AccommodationHelp";
import AirportPickUservices from "../component/otherService/airportPickUservices/AirportPickUservices";
import EducationSystem from "../component/education/studyInAustralia/australianEducationSystm/EducationSystem";
import UniversityAndCollegeInAutralia from "../component/education/studyInAustralia/universitiesAndColleges/UniversityAndCollegeInAutralia";
import MigrationVisaHelp from "../component/aboutUs/migration-and-visa-help/MigrationVisaHelp";
import OurOffice from "../component/aboutUs/ourOffice/OurOffice";
import AustralianStudyAdmissions from "../component/education/studyInAustralia/admissionsProcess/AustralianStudyAdmissions";
import AustralianStudyDocumentAndChecklist from "../component/education/studyInAustralia/documentChecklist/AustralianStudyDocumentAndChecklist";
import AustralianStudentVisaApplicationProcess from "../component/education/studyInAustralia/applicationProcess/AustralianStudentVisaApplicationProcess";
import AboutAustraliaAnditsWeather from "../component/education/studyInAustralia/aboutWeather/AboutAustraliaAnditsWeather";
import PrivacyPolicy from "../component/shared/privacyPolicy/PrivacyPolicy";
import TermsConditions from "../component/shared/termsConditions/TermsConditions";
import ErrorPage from "../component/shared/errorPage/ErrorPage";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
    errorElement:<ErrorPage/>,
    children: [
      { path: "/", element: <Home /> },
      { path: "/home", element: <Home /> },
      { path: "/aboutUs", element: <AboutUs /> },
      { path: "/aboutUs/whyHurborEducation", element: <WhyHarbourEducation /> },
      { path: "/aboutUs/ourMission", element: <OurMission /> },
      { path: "/aboutUs/ourVission", element: <OurVission /> },
      {
        path: "/aboutUs/migration-and-visa-help",
        element: <MigrationVisaHelp />,
      },
      { path: "/aboutUs/our-office", element: <OurOffice /> },
      { path: "/education", element: <Education /> },
      { path: "/education/study-in-australia", element: <Australia /> },
      { path: "/australian-education-system", element: <EducationSystem /> },
      {
        path: "/universities-and-colleges-in-australia",
        element: <UniversityAndCollegeInAutralia />,
      },
      {
        path: "/australian-study-admissions-process",
        element: <AustralianStudyAdmissions />,
      },
      {
        path: "/australian-study-document-checklist",
        element: <AustralianStudyDocumentAndChecklist />,
      },
      { path: "/accommodation-help", element: <AccommodationHelp /> },
      {
        path: "/australian-student-visa-application-process",
        element: <AustralianStudentVisaApplicationProcess />,
      },
      {
        path: "/about-australia-and-its-weather",
        element: <AboutAustraliaAnditsWeather />,
      },

      { path: "/education/study-in-canada", element: <Canada /> },
      {
        path: "/education/study-in-unitedKingdom",
        element: <UnitatedKingdom />,
      },
      { path: "/education/study-in-unitedStates", element: <UnitatedStates /> },
      { path: "/migration", element: <Migration /> },
      {
        path: "/migration/visa-option-for-australia",
        element: <VisaOptionForAustralia />,
      },
      {
        path: "/migration/visa-option-for-canada",
        element: <VisaOptionForCanada />,
      },
      { path: "/otherService", element: <OtherService /> },
      {
        path: "/otherService/student-health-cover",
        element: <StudentHealthConver />,
      },
      {
        path: "/otherService/visitor-health-cover",
        element: <VisitorHealthConver />,
      },
      {
        path: "/otherService/sc-485-sc-482-health",
        element: <Sc485sc482health />,
      },
      { path: "/otherService/job-help", element: <JobHelp /> },
      {
        path: "/otherService/accommodation-help",
        element: <AccommodationHelp />,
      },
      {
        path: "/otherService/airport-pick-u-services",
        element: <AirportPickUservices />,
      },
      { path: "/learningCenter", element: <LearningCenter /> },
      { path: "/blog", element: <Blog /> },
      { path: "/contactUs", element: <ContactUs /> },
      { path: "/privacy-policy", element: <PrivacyPolicy /> },
      { path: "/terms-Conditions", element: <TermsConditions /> },
    ],
  },
]);
