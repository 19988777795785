import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";

import image1 from "../../../assets/images/TrustedUniLogo/1.png";
import image3 from "../../../assets/images/TrustedUniLogo/3.jfif";
import image4 from "../../../assets/images/TrustedUniLogo/4.png";
import image5 from "../../../assets/images/TrustedUniLogo/5.png";
import image6 from "../../../assets/images/TrustedUniLogo/6.svg";
import image7 from "../../../assets/images/TrustedUniLogo/7.png";
import image8 from "../../../assets/images/TrustedUniLogo/8.jfif";
import image9 from "../../../assets/images/TrustedUniLogo/9.png";
import image10 from "../../../assets/images/TrustedUniLogo/10.jpg";
import image11 from "../../../assets/images/TrustedUniLogo/11.png";
import image12 from "../../../assets/images/TrustedUniLogo/12.jpg";
import image13 from "../../../assets/images/TrustedUniLogo/13.png";
import image14 from "../../../assets/images/TrustedUniLogo/14.png";
import image15 from "../../../assets/images/TrustedUniLogo/15.png";
import image16 from "../../../assets/images/TrustedUniLogo/16.jfif";

// Import Swiper styles
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";

import ".//MultiSlider.css";

// import required modules
import { Autoplay, Grid, Pagination, Navigation } from "swiper";

const MultiSlider = () => {
  return (
    <div>
      <Swiper
        autoplay={{
          delay: 1300,
          disableOnInteraction: false,
        }}
        slidesPerView={1}
        grid={{
          rows: 1,
        }}
        spaceBetween={30}
        navigation={{
          clickable: true,
        }}
        modules={[Autoplay, Grid, Pagination, Navigation]}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 5,
            spaceBetween: 50,
          },
        }}
        className="mySwiper"
      >
        <SwiperSlide>
          <div>
            <a href="https://www.unimelb.edu.au/" target="_blank">
              <img alt="versityLogo" src={image1} />
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="space-y-4">
            <a href="https://divinity.edu.au/" target="_blank">
              <img alt="versityLogo" src={image3} />
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="space-y-4">
            <a href="https://www.swinburne.edu.au/" target="_blank">
              <img alt="versityLogo" src={image4} />
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="space-y-4">
            <a href="https://www.rmit.edu.au/" target="_blank">
              <img alt="versityLogo" src={image5} />
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="space-y-4">
            <a href="https://www.monash.edu/" target="_blank">
              <img alt="versityLogo" src={image6} />
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="space-y-4">
            <a href="https://www.latrobe.edu.au/" target="_blank">
              <img alt="versityLogo" src={image7} />
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="space-y-4">
            <a href="https://federation.edu.au/" target="_blank">
              <img alt="versityLogo" src={image8} />
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="space-y-4">
            <a href="https://www.deakin.edu.au/" target="_blank">
              <img alt="versityLogo" src={image9} />
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="space-y-4">
            <a href="https://www.unisq.edu.au/" target="_blank">
              <img alt="versityLogo" src={image10} />
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="space-y-4">
            <a href="https://www.uq.edu.au/" target="_blank">
              <img alt="versityLogo" src={image11} />
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="space-y-4">
            <a href="https://www.qut.edu.au/" target="_blank">
              <img alt="versityLogo" src={image12} />
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="space-y-4">
            <a href="https://www.jcu.edu.au/" target="_blank">
              <img alt="versityLogo" src={image13} />
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="space-y-4">
            <a href="https://www.griffith.edu.au/" target="_blank">
              <img alt="versityLogo" src={image14} />
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="space-y-4">
            <a href="https://www.cqu.edu.au/" target="_blank">
              <img alt="versityLogo" src={image15} />
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="space-y-4">
            <a href="https://bond.edu.au/" target="_blank">
              <img alt="versityLogo" src={image16} />
            </a>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default MultiSlider;
