import React from "react";
import bgImage from "../../../../assets/images/migration/visa-option/visa-option-for-canada.jpg";

const VisaOptionForCanada = () => {
  return (
    <div className="bg-base-200">
      <div
        className=" bg-no-repeat bg-center"
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <h2 className="text-center text-5xl text-white p-28">
          Visa Option for Canada
        </h2>
      </div>

      <div className="container mx-auto px-2 py-20">
        <div>
          <div className="relative text-center">
            <div className="hidden md:block text-xl lg:text-9xl font-bold opacity-5">
              Visa Option For Canada
            </div>
            <div className="absolute text-4xl lg:text-5xl font-bold  top-1/2 -translate-y-1/2 w-full">
              <p> Visa Option For Canada</p>
              <div className="w-28 h-[2px] bg-black mx-auto mt-2"></div>
            </div>
          </div>
        </div>

        <div className="mt-16">
          <div className="flex justify-center">
            <p className="text-2xl">Coming Soon...</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisaOptionForCanada;
