import React from "react";
import { HiOutlineMail } from "react-icons/hi";
import { GiRotaryPhone } from "react-icons/gi";
import { BsWhatsapp } from "react-icons/bs";
import { CiMobile4 } from "react-icons/ci";
import { FaFacebook } from "react-icons/fa";

const Media = () => {
  return (
    <div className=" w-full bg-orange-500 opacity-95 fixed z-10 bottom-0">
      <ul className="flex justify-center gap-10 text-white pt-1 text-xs ">
        <li className=" hover:text-amber-400">
          <a href="mailto:harbourgroupbd@gmail.com">
            <HiOutlineMail className=" h-8 w-8" />{" "}
            <span className="hidden lg:block">Email us</span>{" "}
          </a>
        </li>
        <li className=" hover:text-amber-400">
          <a
            href="https://www.facebook.com/Harbour-Education-and-Migration-101331986220799/"
            target="_blank"
          >
            <FaFacebook className=" h-8 w-8" />{" "}
            <span className="hidden lg:block">facebook</span>{" "}
          </a>
        </li>

        <li className=" hover:text-amber-400">
          <a
            href="https://wa.me/8801896122111"
            class="whatsapp_float"
            target="_blank"
            rel="noopener noreferrer"
          >
            <BsWhatsapp className=" h-8 w-8" />
            <span className="hidden lg:block">whatsApp</span>
          </a>
        </li>

        <li className=" hover:text-amber-400">
          <a target="_blank" href="tel:+8802222243443">
            <GiRotaryPhone className="h-8 w-8" />{" "}
            <span className="hidden lg:block">Phone</span>
          </a>
        </li>

        <li className=" hover:text-amber-400">
          <a
            href="tel:+8801896122100"
            class="whatsapp_float"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CiMobile4 className=" h-8 w-8" />
            <span className="hidden lg:block">Mobile</span>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Media;
