import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import student1 from "../../../assets/images/student/student-1.png";


import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styl

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "./styles.css";

// import required modules
import { Pagination } from "swiper";

AOS.init();

const Students = () => {
  const students = [
    {
      name: "Sojib Mondol",
      address:
        "Dhanmondi,Dhaka,Bangladesh to UTS (University of Technology, Sydney) Australia",
      image: student1,
      speech:
        "I went to 8 different education consultancies in Dhaka and got very confusing answer as chance of getting my Australian student visa is very low and asking me lot of money too to process my application. Thats when i contacted Harbour education team at their Dhanmondi office. They know what they are doing and they got me admissions into UTS within 8 days. Processed my file really quickly from their Sydney head office and followed very well. Due to Harbour Education, i am in Sydney now, all these with in 40 days. Super good.",
    },
    {
      name: "MD.Sojol Haqe",
      address:
        "Banani,Dhaka,Bangladesh to Admission to Torrens University, Sydney Australia",
      image: student1,
      speech:
        "Sitting in Dhaka, I contacted Harbour education from their website. Booked zoom session with them and in 4 weeks i got my admissions and visa granted all done . No troubles, no hassles and very professional .Harbour Education, is the best for student visa to Australia.",
    },
    {
      name: " Nayem Islam",
      address:
        "Kosba,Cumilla,Bangladesh to  Admission to ACAP, Sydney Australia - Master in Social Work",
      image: student1,
      speech:
        "Thank you Harbour Education team. My words wont be able to express my gratitude towards shaping up my future. Honest, reliable and very ethical. You all have guided me and helped me like family. Harbour Education team is best and they put me at ACAP for Masters in social work which turns out to be best course for me personally. Thank you all.",
    },
  ];

  return (
    <>
      <div>
        <h1 className="text-3xl text-center text-orange-500 mt-24 mb-10">
          What our past students have got to say about Harbour Education
        </h1>
      </div>
      <Swiper
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        {students.map((student) => (
          <SwiperSlide key={student.name} className="mb-10">
            <div
              data-aos="flip-down"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
            >
              <div className="italic">
                <div className="avatar">
                  <div className="w-24 mask mask-squircle">
                    <img src={student.image} alt="student" />
                  </div>
                </div>
                {/* <p className='text-center mx-10 lg:mx-[20%]'>{student.speech}</p>  */}
                <blockquote className=" text-center mx-10 lg:mx-[20%] text-lg italic font-semibold text-gray-900">
                  <svg
                    aria-hidden="true"
                    className="w-10 h-10 text-gray-400 dark:text-gray-600"
                    viewBox="0 0 24 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z"
                      fill="currentColor"
                    />
                  </svg>
                  <p>{student.speech}</p>
                </blockquote>
                <p className="text-lg font-bold text-orange-500">
                  {" "}
                  {student.name}{" "}
                </p>
                <address>
                  {" "}
                  <small className="opacity-[.5]"> {student.address} </small>
                </address>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div></div>
    </>
  );
};

export default Students;
