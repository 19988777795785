import React from "react";

import service1 from "../../../assets/images/services-images/our-services-1.png";
import service2 from "../../../assets/images/services-images/our-services-2.png";
import service3 from "../../../assets/images/services-images/our-services-3.png";
import service4 from "../../../assets/images/services-images/our-services-4.png";
import service5 from "../../../assets/images/services-images/our-services-5.jpg";
import service6 from "../../../assets/images/services-images/our-services-6.png";
import { Link } from "react-router-dom";

import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styl

AOS.init();
const Services = () => {
  const services = [
    {
      name: "Overseas Student Health Cover (OSHC)",
      image: service1,
      to: "/otherService/student-health-cover",
    },
    {
      name: "Overseas Visitor Health Cover (OSHC)",
      image: service2,
      to: "/otherService/visitor-health-cover",
    },
    {
      name: "SC 485 / SC 482 Health Cover Options",
      image: service3,
      to: "/otherService/sc-485-sc-482-health",
    },
    {
      name: "Job Help",
      image: service4,
      to: "/otherService/job-help",
    },
    {
      name: "Accommodation Help",
      image: service5,
      to: "/otherService/accommodation-help",
    },
    {
      name: "Airport Pick up Services",
      image: service6,
      to: "/otherService/airport-pick-u-services",
    },
  ];

  return (
    <div>
      <h2 className="text-center text-4xl text-orange-500 mb-16">
        We Provide Other Services.
      </h2>

      <div className=" grid sm:gap-10 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-16 mb-40 mx-[8%]">
        {services.map((service) => (
          <div key={service.name}>
            <div
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="3000"
            >
              <div className="flex flex-col gap-4 items-center">
                <img src={service.image} alt="service" />
                <p className="text-xl self-center text-center">
                  {service.name}{" "}
                </p>
                <Link to={service.to}>
                  <button className=" btn normal-case hover:bg-red-500 text-orange-500 outline-orange-500 rounded-full btn-outline ">
                    See Detalis
                  </button>
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
