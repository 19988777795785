import React from "react";
import bgImage from "../../../../assets/images/study in/australia/classification/bg.png";
import weather from "../../../../assets/images/study in/australia/weather.jpg";
import { Link } from "react-router-dom";

const AboutAustraliaAnditsWeather = () => {
  return (
    <div className=" bg-white">
      <div
        className=" bg-no-repeat bg-center bg-cover"
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <h2 className="text-center text-5xl text-white p-28">
          About Australia and its Weather
        </h2>
      </div>

      <div className="mx-[10%]">
        <h2 className="text-2xl font-bold mt-10 ">Australia Facts</h2>
        <div className="flex justify-center content-center mb-10 ">
          <img className="w-full " src={weather} alt="weather" />
        </div>

        <div className="my-10">
          <p className="leading-loose">
            Australia is an island country in the Southern hemisphere and
            belongs to Oceania/Australia. Australia is surrounded by the Indian
            Ocean and the Pacific Ocean. Australia/Oceania is the smallest
            continent of the seven continents and although some consider
            Australia as the largest island in the world, Australia is commonly
            referred to as a country and a continent. (Greenland is the biggest
            island in the world). Australia is also the driest inhabited
            continent of the world. <br />
            Australia is the sixth largest country in the world, after Russia,
            Canada, USA, China and Brazil.
            <br />
            The country is divided into six states (Southern Australia, Western
            Australia, New South Wales, Queensland, Victoria and Tasmania) and
            two self-governing territories: Northern Territory and Australian
            Capital Territory (which is around Canberra, the capital city).
            <br />
            The country’s interior consists of arid and semi-arid areas which
            are referred to as ‘outback’. About 70% of Australia are outback,
            while the coastal plains are more fertile and house the majority of
            Australia’s population. About 35% of Australia’s land area are
            covered by deserts.
            <br />
            Most of Australia’s population live in the eastern and southern
            parts of the country and along the coastline.
            <br />
            Australia is roughly the same size as the USA without the states of
            California and Texas.
            <br />
            Australia belongs to the Commonwealth countries.
          </p>
        </div>
        <a href="https://en.wikipedia.org/wiki/Australia" target="_blank">
          <button className="inline-flex items-center px-3 py-3 my-3 text-sm font-medium text-center text-white bg-red-500 rounded mb-10 hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-400 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-800">
            Learn More about Australia
            <svg
              aria-hidden="true"
              className="w-4 h-4 ml-2 -mr-1"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
        </a>
      </div>
    </div>
  );
};

export default AboutAustraliaAnditsWeather;
