import React from "react";

const TermsConditions = () => {
  return (
    <div className="bg-base-100">
      <div className=" mx-[5%] ">
        <h3 className="text-3xl font-semibold text-center pt-16">
          Our Terms Conditions
        </h3>
        <p>
          <span className="text-xl font-bold"> 1.Terms</span>
          <ul>
            <li className=" list-outside text-left my-4">
              By using our website, you are acknowledging and agreeing to abide
              by our terms of service and all relevant laws and regulations. You
              are also responsible for complying with all applicable local laws.
              If you do not agree to these terms and conditions, you may not
              access or use our website. Additionally, all content on our
              website is protected by copyright and trademark laws.
            </li>
          </ul>
        </p>
        <p>
          <span className="text-xl font-bold"> 2.Use licenses</span>
          <ul>
            <li className=" list-outside text-left my-4">
              Visitors to the Harbour Education website are permitted to
              download one copy of the material (information or software) solely
              for personal, non- commercial transitory viewing, but should note
              that this is a license and not a transfer of title.Harbour
              Education's materials are protected by copyright and proprietary
              notations, and accessing our website grants you a license to
              temporarily download the material for personal, non- commercial
              use only. However, under this license, you are not allowed to
              copy, modify, use the material for commercial or public display
              purposes, attempt to reverse-engineer or decompile our website's
              software, remove copyright or proprietary notations, or transfer
              the content to someone else or mirror it on another server. If any
              of the restrictions mentioned above are violated, the license
              provided by Harbour Education will be terminated. Upon
              termination, either by the website or the viewer, all of the
              materials from Harbour Education in the possession of the viewer,
              whether in electronic or printed format, must be destroyed.
            </li>
          </ul>
        </p>
        <p>
          <span className="text-xl font-bold"> 3.Disclaimer</span>
          <ul>
            <li className=" list-outside text-left my-4">
              On our website, all the materials are provided "as is" and without
              any warranty, whether implied or expressed. We do not guarantee
              the accuracy, completeness, or reliability of the information
              provided on our website. Moreover, we disclaim all warranties and
              conditions of merchantability, non- infringement of intellectual
              property, fitness for a particular purpose, or other violation of
              rights. Moreover, HARBOUR makes no warranties or representations
              associated with the accuracy, results, or reliability of the use
              of content on its website, or relating to such materials, or any
              of the sites linked to it.
            </li>
          </ul>
        </p>
        <p>
          <span className="text-xl font-bold">5.Accuracy Of Materials</span>
          <ul>
            <li className=" list-outside text-left my-4">
              The content on HARBOUR Consultancy Services’ website may have
              technical, photographic, or typographic errors. HARBOUR
              Consultancy Services does not make any warranties that all of the
              content on its website is accurate, relevant, or complete.
              Moreover, we have the right to make changes on our website at any
              time without prior notice. However, we make no commitments to keep
              updating the materials on our website.
            </li>
          </ul>
        </p>
        <p>
          <span className="text-xl font-bold">6.Links</span>
          <ul>
            <li className=" list-outside text-left my-4">
              HARBOUR Consultancy Services does not take responsibility for the
              content present on any of the linked websites and has not reviewed
              them. The fact that we have included links to other websites does
              not imply any endorsement or approval of the content present on
              those sites. Visitors to such linked sites use them at their own
              risk.
            </li>
          </ul>
        </p>
        <p>
          <span className="text-xl font-bold">7.Modifications</span>
          <ul>
            <li className=" list-outside text-left mt-4 pb-24">
              HARBOUR Consultancy Services reserves the right to update or
              modify its Terms of Service without any prior notice. By
              continuing to use the website, you agree to be bound by the most
              recent version of these Terms of Service.
            </li>
          </ul>
        </p>
      </div>
    </div>
  );
};

export default TermsConditions;
