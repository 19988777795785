import React from "react";
import bgImage from "../../../assets/images/aboutUs/about-us.jpg";
import testimonial from "../../../assets/images/student/testimonial.png";
import { Link } from "react-router-dom";
import { FaUserGraduate } from "react-icons/fa";

const WhyHarbourEducation = () => {
  return (
    <div className=" bg-white">
      <div
        className=" bg-no-repeat bg-center "
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <h2 className="text-center text-5xl text-white p-28">
          Why Harbour Education
        </h2>
      </div>

      <div className="grid grid-cols-4 gap-4 lg:-ml-16">
        <div className=" col-span-4 lg:col-span-3  p-5 sm:p-10 md:p-16 dark:bg-gray-800 dark:text-gray-100">
          <div className="m-4  mt-4 space-y-6 sm:px-10 sm:mx-12 lg:rounded-md dark:bg-gray-900">
            <div className="dark:text-gray-100">
              <p>
                At Harbour Education and Migration, we specialize in providing
                education services to students who want to study abroad. We
                offer a wide range of courses, including undergraduate and
                postgraduate degrees, language courses, and vocational training.
                Our team of experienced professionals can help you choose the
                right course and institution based on your academic background,
                career aspirations, and personal preferences. we are confident
                that we can deliver courses as well as migration solutions for
                various countries. We specialized in:
              </p>
            </div>
            <div>
              <li>Student Visa to Australia</li>
              <li>Student Visa to Canada</li>
              <li>Student Visa to United Kingdom</li>
              <li>Student Visa to United State</li>
            </div>
            <div>
              <p className="font-bold mt-10 mb-5">
                For all work, study and migration options in AUSTRALIA / CANADA
                / USA / UK / EUROPE – Harbour education and migration is your
                one stop solution provider.
              </p>

              <p>
                Harbour Education is an organization with a clear vision and
                zeal to reach to the highest point in our profession. With a
                clear focus to deliver best possible services to our students,
                we are fully committed to render quality services to our
                students within the agreed time frames with a personal touch. We
                feel proud in saying that our honest and realistic approach has
                helped us in winning the confidence of our students and in
                achieving almost 100% success rate in all the cases, which we
                have taken up so far. Harbour Education is an International
                Student Recruitment Organization that assists students in
                pursuing overseas education in universities in Australia, USA,
                New Zealand etc. Harbour is a solution-oriented company, which
                specializes in tailoring career pathways to suit individual
                profiles within the constraints that may prevail in each case.
                The constraints may be educational, financial or personal.
                Applying for higher education abroad is a huge decision, both
                financially and emotionally. It is imperative to invest adequate
                time and effort in order to make the right decision. We are here
                to help make it worth your while. Our expert counselors can hold
                your hand right through the entire process helping you make the
                right decision every step of the way.
                <br /> <br />
                <br />
                There may be many reasons why students choose Harbour to study
                abroad, but here are some answers.
                <br />
                <br />
                <p>
                  1. Expertise: Harbour has a team of experienced consultants
                  who are knowledgeable about the process of studying abroad,
                  including selecting the right university, applying for visas,
                  and navigating cultural differences. Students may come to
                  Harbour to benefit from their expertise and advice.
                  <br /> <br />
                  2. Personalized service: Harbour provides personalized
                  services that are tailored to each student's;s unique needs
                  and aspirations. By taking the time to understand each
                  student's;s background, interests, and goals, Harbour can
                  offer customized guidance and support.
                  <br /> <br />
                  3. Comprehensive support: Harbour offers a range of services
                  to support students at every stage of the process, from
                  selecting universities and preparing applications to securing
                  visas and settling into a new country. This comprehensive
                  approach can make the process of studying abroad less daunting
                  and more manageable.
                  <br /> <br />
                  4. Access to opportunities: Harbour has a network of
                  relationships with universities and education institutions
                  around the world, which can provide students with access to a
                  wider range of academic opportunities than they may have
                  otherwise. Students may come to Harbour to take advantage of
                  these connections and maximize their chances of success.
                  <br /> <br />
                  Overall, students may choose to come to Harbour for a
                  combination of these reasons, as well as other factors such as
                  reputation, affordability, and convenience.
                </p>
              </p>
              <h2 className="text-lg mt-8">Harbour Services</h2>
              <ul className="list-decimal my-8">
                <li>Assistance in choosing Universities/Colleges</li>
                <li>Assistance in Filling of Applications</li>
                <li>Assistance in Documentation for Admission</li>
                <li>Regular follow-up with Universities / colleges</li>
                <li>
                  Assistance in Securing Student Scholarship from the respective
                  universities/colleges
                </li>
                <li>Admission</li>
                <li>100% Visa Assistance</li>
                <li>3-4 mock Visa interviews [if necessary]</li>
              </ul>
              <h2 className="text-xl my-4">Universities Selection</h2>
              <ul className="list-decimal my-8">
                <li>Ranking of Universities & Accreditation</li>
                <li>Student Faculty Ratio</li>
                <li>Tuition Fee</li>
                <li>Scholarships & Assistantships</li>
                <li>On Campus & Off-Campus Job Opportunities</li>
                <li>Climatic Conditions</li>
                <li>Employment Success Rate after Completion of Course</li>
                <li>Visa Success Rate</li>
              </ul>
              <p>
                Study in Australia with best options of choosing Australian
                colleges or Australian Universities, please contact us to
                proceed with your application.
              </p>
            </div>
          </div>
        </div>
        <div className="hidden lg:block">
          <div className="border border-orange-500 w-3/4 mt-16 ">
            <h2 className="bg-orange-500 text-lg p-3 text-white font-bold">
              {" "}
              <span className="">Study Abroad</span>
            </h2>
            <ul className="p-2">
              <Link
                to="/education/study-in-australia"
                className="link link-hover hover:text-blue-600"
              >
                <li>
                  {" "}
                  <FaUserGraduate className="inline" /> Study in Australia
                </li>
              </Link>
              <Link
                to="/education/study-in-canada"
                className="link link-hover  hover:text-blue-600"
              >
                {" "}
                <li>
                  {" "}
                  <FaUserGraduate className="inline" /> Study in Canada
                </li>
              </Link>
              <Link
                to="/education/study-in-unitedKingdom"
                className="link link-hover  hover:text-blue-600"
              >
                <li>
                  {" "}
                  <FaUserGraduate className="inline" /> Study in United Kingdom
                </li>
              </Link>
              <Link
                to="/education/study-in-unitedStates"
                className="link link-hover  hover:text-blue-600"
              >
                <li>
                  {" "}
                  <FaUserGraduate className="inline" /> Study in United State
                </li>
              </Link>
            </ul>
          </div>
          <div className="border border-orange-500 w-3/4 mt-16">
            <h2 className="bg-orange-500 text-lg p-3 text-white font-bold">
              Testimonials
            </h2>
            <div className="italic p-2">
              <div className="avatar ml-[30%]">
                <div className="w-24 mask mask-squircle ">
                  <img
                    className="flex mx-auto"
                    src={testimonial}
                    alt="student"
                  />
                </div>
              </div>
              <p className="text-lg text-center font-semibold text-orange-500">
                {" "}
                Azmain Haqe{" "}
              </p>
              <address className="text-center">
                {" "}
                <small className="opacity-[.5] text-center">
                  {" "}
                  211/A,Mohammudpur,Dhaka{" "}
                </small>
              </address>
              <blockquote className=" text-center mx-10 lg:mx-[20%] text- italic font-semibold text-gray-900 dark:text-white">
                <svg
                  aria-hidden="true"
                  className="w-10 h-10 text-gray-400 dark:text-gray-600"
                  viewBox="0 0 24 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z"
                    fill="currentColor"
                  />
                </svg>
                <p>
                  “I loved Harbour Education, because you really become part of
                  a family with the rest of your Harbour mates. I thought that
                  was such a unique part of my time abroad when a lot of my
                  friends in other programs had hundreds of students and felt
                  little to no connection with their program. I really
                  appreciated that everyone in Harbour was so close and
                  comfortable with each other.”
                </p>
              </blockquote>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyHarbourEducation;
