import React from "react";
import bgImage from "../../assets/images/study in/education-abroad.jpg";
import austrailaImage from "../../assets/images/countrys-images/study-in-australia.jpg";
import canadaImage from "../../assets/images/countrys-images/study-in-canada.jpg";
import unitedKingdomImage from "../../assets/images/countrys-images/study-in-united-kingdom.jpg";
import unitedStateImage from "../../assets/images/countrys-images/stuydy-in-unitated-state.jpg";
import { Link } from "react-router-dom";

const Education = () => {
  const countrys = [
    {
      name: "Austraila",
      image: austrailaImage,
      description:
        " The name Australia conjures up an instant set of images for most Brits. These usually involve long golden beaches and sparkling blue sea (for swimming, surfing and snorkeling in). And lots and lots of sun. It’s true that Australia does have a lot of beaches – around 7,000, more than any other nation on earth.",
      btnText: "Study in Austraila",
      to: "/education/study-in-australia",
    },
    {
      name: "Canada",
      image: canadaImage,
      description:
        "Canada has many reasons to be counted among the best international study destinations. It offers a wide variety of course options ranging from undergraduate diplomas to master’s degrees. Research is a key component of Canadian universities and it plays a great role at the forefront of technology.",
      btnText: "Study in Canada",
      to: "/education/study-in-canada",
    },
    {
      name: "United Kingdom",
      image: unitedKingdomImage,
      description:
        "The UK has a rich legacy of universities of international repute such as the University of Oxford and the University of Cambridge. With 18 universities among the world’s top 100, UK attracts thousands of students and researchers every year, including many from India seeking to get admission in UK universities.",
      btnText: "Study in United Kingdom",
      to: "/education/study-in-unitedKingdom",
    },
    {
      name: "United States",
      image: unitedStateImage,
      description:
        "The United States of America has been a global leader in the field of education and boasts of a lion’s share of top ranked universities according to all major international rankings. Few countries offer as many high ranked universities and noble laureate academia, as USA does. As a matter of fact, nearly 50 of the top 200 universities in the world are based in USA.",
      btnText: "Study in United States",
      to: "/education/study-in-unitedStates",
    },
  ];

  return (
    <div className="bg-base-100">
      <div
        className=" bg-no-repeat bg-center"
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <h2 className="text-center text-5xl text-white p-28">
          Harbour Education
        </h2>
      </div>
      <div className="my-16">
        <p className="text-xl text-center ">
          Harbour is an Educational Services assisting students who wish to
          study abroad. <br />
          We are the official representatives of several foreign universities.
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-14 mx-[5%]">
        {countrys.map((country, i) => (
          <div
            key={i}
            className="card card-compact bg-base-200 shadow-xl my-10 rounded-none"
          >
            <figure>
              <img
                className="w-full  scale-100 hover:scale-125 aspect-[16/11]  object-cover object-top"
                src={country.image}
                alt="country"
              />
            </figure>
            <div className="card-body">
              <h2 className="card-title">{country.name}</h2>
              <p>{country.description}</p>
              <div className="card-actions justify-end">
                <Link to={country.to}>
                  <button className="inline-flex items-center px-3 py-2 my-3 text-sm font-medium text-center text-white bg-red-500 rounded-lg hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-400 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-800">
                    Learn More
                    <svg
                      aria-hidden="true"
                      className="w-4 h-4 ml-2 -mr-1"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Education;
