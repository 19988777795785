import React from "react";
import bgImage from "../../assets/images/other-service/inner-page-banner.jpg";
import img1 from "../../assets/images/otherService/1.jpeg";
import img2 from "../../assets/images/otherService/2.jpg";
import img3 from "../../assets/images/otherService/3.jpg";
import img4 from "../../assets/images/otherService/4.jpg";
import img5 from "../../assets/images/otherService/5.jpg";
import img6 from "../../assets/images/otherService/6.jpg";
import { Link } from "react-router-dom";

import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles

AOS.init();

const OtherService = () => {
  const otherServices = [
    {
      name: "Overseas Student Health Cover (OSHC)",
      description:
        "If you are a student from overseas on a temporary student visa it is a condition of your visa to maintain adequate health insurance for the duration of your stay in Australia. This means you need to purchase Overseas Student Health Cover (OSHC) and keep your policy up to date whilst you are in Australia and holding a student visa.",
      image: img1,
      to: "/otherService/student-health-cover",
    },
    {
      name: "Overseas Visitors Health Cover",
      description:
        "Overseas Visitors Health Cover (OVHC) is a form of health insurance which is designed for visitors to Australia who do not have access to Australia’s public Medicare system for medical or hospital expenses. OVHC insures against potential expenses you may incur if you require medical or hospital treatment.",
      image: img2,
      to: "/otherService/visitor-health-cover",
    },
    {
      name: "SC 485 / SC 482 Health",
      description:
        "Are you travelling to Australia on a 482 Temporary Skill Shortage (TSS) visa? We offer Overseas Visitor Health Cover (OVHC) for international visitors on the 482 Temporary Skill Shortage visa. Our OVHC is Australia-wide and lets you access medical and health cover if something happens during your stay. The Department of Home Affairs requires TSS visa holders to take out health insurance each time they travel to Australia.",
      image: img3,
      to: "/otherService/sc-485-sc-482-health",
    },
    {
      name: "Job Help",
      description:
        "Our organization provides the candidates with the perfect Employment Assistance through job search, counseling, resume writing, business entrepreneurship and more. The employee assistance services provided by us are designed to both assist employees and support the productivity and profitability of employers. We believe in mutually beneficial relationships that is built on trust and faith.",
      image: img4,
      to: "/otherService/job-help",
    },
    {
      name: "Accommodation Help",
      description:
        "Moving to another country, especially at a young age can be daunting, and we understand that. A very important part of adapting to the new culture and environment is in making a new home away from your home country. The right accommodation makes all the difference and will set the tone for your transition to living abroad.",
      image: img5,
      to: "/otherService/accommodation-help",
    },
    {
      name: "Airport Pick up Services",
      description:
        "When you pre-book your private taxi transfer or shared shuttle bus service with us, you can be sure of a convenient, comfortable start to your study or holiday in Australia. When you choose from our range of private transfer vehicles, including taxis or minibuses, you will benefit from our excellent Meet & Greet service.",
      image: img6,
      to: "/otherService/airport-pick-u-services",
    },
  ];

  return (
    <div className="bg-white ">
      <div
        className=" bg-no-repeat bg-center "
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <h2 className="text-center text-5xl text-white p-28">Other Services</h2>
      </div>
      <div
        data-aos="fade-right"
        data-aos-easing="linear"
        data-aos-duration="1500"
      >
        <div className="flex flex-col gap-8 mx-[10%] my-16">
          {otherServices.map((service) => (
            <div
              key={service.name}
              className=" border-2 rounded-none card lg:card-side bg-base-100 shadow-xl"
            >
              <figure>
                <img
                  className="w-full object-cover"
                  src={service.image}
                  alt="service"
                />
              </figure>
              <div className="card-body w-[100%]">
                <h2 className="card-title">{service.name}</h2>
                <p>{service.description}</p>
                <div className="card-actions justify-end">
                  <Link to={service.to}>
                    <button className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-red-500 rounded-lg hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-400 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-800">
                      Read more
                      <svg
                        aria-hidden="true"
                        className="w-4 h-4 ml-2 -mr-1"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OtherService;
