import React from "react";
import { Link } from "react-router-dom";
import logo from "../../../assets/images/logo.png";
import flags1 from "../../../assets/images/contactUs/bd_flags.webp";
import flags2 from "../../../assets/images/contactUs/Australia_flags.webp";

function Footer() {
  return (
    <>
      <footer className="footer p-10  bg-neutral-700 text-neutral-content">
        <div>
          <span className="footer-title">ABOUT US</span>
          <Link to="/aboutUs/whyHurborEducation" className="link link-hover">
            Why Harbour Education
          </Link>
          <Link to="/aboutUs/ourMission" className="link link-hover">
            Our Vision
          </Link>
          <Link to="/aboutUs/ourVission" className="link link-hover">
            Our Mission
          </Link>
          <Link
            to="/aboutUs/migration-and-visa-help"
            className="link link-hover"
          >
            Migration and Visa Help
          </Link>
          <Link to="/aboutUs/our-office" className="link link-hover">
            Our Offices
          </Link>
          {/* <button className="btn hover:bg-red-500 text-orange-500 outline-orange-500 rounded- btn-outline normal-case">Apply Online <i className="fa-light fa-arrow-right"> </i></button> */}
        </div>
        <div>
          <span className="footer-title">EDUCATION</span>
          <Link to="/education/study-in-australia" className="link link-hover">
            Study in Australia
          </Link>
          <Link to="/education/study-in-canada" className="link link-hover">
            Study in Canada
          </Link>
          <Link
            to="/education/study-in-unitedKingdom"
            className="link link-hover"
          >
            Study in United Kingdom
          </Link>
          <Link
            to="/education/study-in-unitedStates"
            className="link link-hover"
          >
            Study in United States
          </Link>
        </div>
        <div>
          <span className="footer-title">MIGRATION</span>
          <Link
            to="/migration/visa-option-for-australia"
            className="link link-hover"
          >
            Visa Options for Australia
          </Link>
          <Link
            to="/migration/visa-option-for-canada"
            className="link link-hover"
          >
            Visa Options for Canada
          </Link>
        </div>
        <div>
          <span className="footer-title">OTHER LINKS</span>
          <Link to="/otherService" className="link link-hover">
            Other Services
          </Link>
          <Link to="/blog" className="link link-hover">
            Our Blogs
          </Link>
          <Link to="/learningCenter" className="link link-hover">
            Learning Center
          </Link>
          <Link to="/contactUs" className="link link-hover">
            Contact Us
          </Link>
          <Link to="/privacy-policy" className="link link-hover">
            Privacy & Policy
          </Link>
          <Link to="/terms-conditions" className="link link-hover">
            Terms & Conditions
          </Link>
          {/* <a className="link link-hover">Privacy Policy</a> 
          <a className="link link-hover">Career With Us</a>  */}
        </div>
      </footer>
      <footer className=" pb-20 footer border-t  bg-neutral-700 text-base-content border-base-300">
        <div className="items-center grid-flow-col">
          <Link to="/">
            <img className=" h-56 w-60 " src={logo} alt="logo" />
          </Link>
        </div>

        <div className="hidden lg:flex flex-col justify-center items-center text-center text-white mt-8">
          <div className="justify-center items-center  hidden lg:flex m-0 lg:m-1">
            <img src={flags1} alt="" className="p-2 h-28 w-4/5 md:h-24" />
          </div>

          <div className="flex items-center justify-center flex-col leading-loose font-semibold">
            <h2 className="text-lg font-bold">Harbour Education & Migration</h2>
            <p>Office:211 West Dhanmondi</p>
            <p>Road:10/A(new) 19(Old)</p>
            <p>Dhanmondi-Dhaka-1209,Bangladesh</p>
          </div>
        </div>
        <div className="hidden lg:flex flex-col justify-center items-center text-center text-white mt-8">
          <div className="hidden lg:flex justify-center items-center m-0 lg:m-1">
            <img src={flags2} alt="" className="p-2 h-28 w-[67%] md:h-24" />
          </div>

          <div className="flex items-center justify-center flex-col leading-loose font-semibold ">
            <h2 className="text-lg font-bold">Harbour Education & Migration</h2>
            <p>Office:35 Main st</p>
            <p>City:Lithgow</p>
            <p>NSW 2790,Australia</p>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center ml-6 lg:ml-0  text-center text-white mt-0 lg:mt-8">
          <div className="flex items-start justify-start flex-col leading-loose font-semibold ">
            <h2 className="text-lg font-bold text-orange-500 uppercase">
              Opening Hours <br />
            </h2>
            <p>Sat- 10AM to 6PM</p>
            <p>Sun-10AM to 6PM</p>
            <p>Mon-10AM to 6PM</p>
            <p>Tue- 10AM to 6PM</p>
            <p>Wed-10AM to 6PM</p>
            <p>Thu- 10AM to 6PM</p>
            <p>
              Friday- <span className="text-red-500 uppercase">Closed</span>
            </p>
          </div>
        </div>
        <div className="md:place-self-center md:justify-self-end text-indigo-300 ">
          <div className="grid grid-flow-col gap-4 px-10">
            <a href="https://twitter.com/" target="_blank">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="fill-current"
              >
                <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"></path>
              </svg>
            </a>
            <a href="https://www.youtube.com/" target="_blank">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="fill-current"
              >
                <path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"></path>
              </svg>
            </a>
            <a
              href="https://www.facebook.com/Harbour-Education-and-Migration-101331986220799/"
              target="_blank"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="fill-current"
              >
                <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"></path>
              </svg>
            </a>
          </div>
        </div>
      </footer>
    </>
  );
}
export default Footer;
