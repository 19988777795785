import React from "react";
import bgImage from "../../../assets/images/migration/immigration-visa-services.jpg";
import vsAustraila from "../../../assets/images/migration/visa-option/main-qimg-b908627f303c221a8b4c9d5a61f129bf-lq.jpeg";
import vsCanada from "../../../assets/images/migration/visa-option/canada-migrate-800x450.jpg";
import { Link } from "react-router-dom";

import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles

AOS.init();
const Migration = () => {
  const VisaOptions = [
    {
      name: "Visa Option For Austraila",
      image: vsAustraila,
      description:
        "You have a business or investment background where you have either been involved in running your business or your investment activities in the last few years.",
      link: "/migration/visa-option-for-australia",
    },
    {
      name: "Visa Option For Canada",
      image: vsCanada,
      description:
        "Before you apply for a Canadian visa, you must first know which visa you want to apply for. Here are the types of Canada visas.",
      link: "/migration/visa-option-for-canada",
    },
  ];
  return (
    <div>
      <div
        className=" bg-no-repeat bg-center"
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <h2 className="text-center text-5xl text-white p-28">Migration</h2>
      </div>

      <h2 className="text-center text-xl my-12">
        We can provide employment emigration clearance for Gulf countries.
        <br />
        We have experienced and professional staff to help you with all your
        VISA/ DOCUMENTATION inquiries <br /> and as well as any other travel
        related issues.
      </h2>

      <div className="flex justify-center gap-6  flex-wrap">
        {VisaOptions.map((visaOption, i) => (
          <div key={i} className="max-w-lg p-4 dark:bg-gray-900 ">
            <div data-aos="zoom-in-right" data-aos-duration="2000">
              <div className="space-y-4">
                <div className="space-y-2">
                  <img
                    src={visaOption.image}
                    alt=""
                    className="block object-cover object-center w-full rounded-md h-72 dark:bg-gray-500"
                  />
                </div>
                <div className="space-y-2">
                  <h3 className="text-xl font-semibold ">{visaOption.name}</h3>
                  <p className="leading-snug ">{visaOption.description}</p>
                  <Link to={visaOption.link}>
                    <button className="inline-flex items-center px-3 py-2 my-3 text-sm font-medium text-center text-white bg-red-500 rounded-lg hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-400 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-800">
                      Learn More
                      <svg
                        aria-hidden="true"
                        className="w-4 h-4 ml-2 -mr-1"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Migration;
