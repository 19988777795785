import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="bg-base-100">
      <div className=" mx-[5%] ">
        <h3 className="text-3xl font-semibold text-center pt-16">
          Our Privacy & Policy
        </h3>
        <p>
          <span className="text-xl font-bold"> Introduction</span>
          <ul>
            <li className=" list-outside text-left my-4">
              At HARBOUR Consultancy Services, we value your privacy and take it
              very seriously. We are dedicated to following the Privacy Act in
              regards to all personal and sensitive information we may collect
              from you. We encourage you to review our Privacy Policy to
              understand how we manage and protect the information we collect
              from you.
            </li>
          </ul>
        </p>
        <p>
          <span className="text-xl font-bold">
            The Privacy Policy of HARBOUR Consultancy Services outlines the
            following:
          </span>
          <ul className="my-6">
            <li className=" list-outside text-left my-4">
              <strong>1.</strong>Types of personal information that we collect
              and hold.
            </li>
            <li className=" list-outside text-left my-4">
              <strong>2.</strong>Our process of collecting information.
            </li>
            <li className=" list-outside text-left my-4">
              <strong>3.</strong>The purpose of collecting various information.
            </li>
            <li className=" list-outside text-left my-4">
              <strong>4.</strong>The way in which we utilize and disclose
              information.
            </li>
            <li className=" list-outside text-left my-4">
              <strong>5.</strong>The measures we take to protect the information
              we collect.
            </li>
            <li className=" list-outside text-left my-4">
              <strong>6.</strong>How users may access and correct their personal
              information that we hold.
            </li>
            The Kinds Of Personal Information That We Collect And Hold. <br />
            Throughout our regular course of activities, we may collect
            information from you, some of which may be sensitive.
          </ul>
        </p>
        <p>
          <span className="text-xl font-bold">
            These are the types of information that we may collect from you:
          </span>
          <ul>
            <li className=" list-outside text-left my-4">
              <span className="font-semibold">
                {" "}
                We collect personal information, which may include but is not
                limited to your name, your postal and email addresses, your
                contact details, your residency status, date of birth, your
                educational qualifications, your employment details, tax file
                numbers, your passport details, information necessary for visa
                application and lodging (medical and financial information), as
                well as your professional and occupational memberships:
              </span>{" "}
              <br /> <br />
              Who enquire regarding study opportunities, or who want to study in
              an educational institution abroad. <br /> <br />
              Who engage with our visa application services (student visas,
              skilled migration visa, or post-study work visa).
              <br /> <br />
              Who register for participation in an event organized by HARBOUR.
              <br />
              Who register their interest in being engaged as individuals or
              consultants on programs managed by HARBOUR. <br />
              This information may include the copies of documents for proof of
              identity, such as passport copies, photographs, or proof of
              citizenship. <br /> <br />
              In case you register your interest with a HARBOUR counsellor, or
              subscribe to our website, we may collect your personal
              information, including your name, date of birth, postal and email
              address, contact details, occupation, country of residence,
              preferred study destination, as well as sensitive information,
              including professional memberships. <br /> <br />
              We may collect payment details in case you purchase any products
              from our website.
              <br /> <br />
              When registering for a conference or an event, such as an Expo, we
              may collect personal or sensitive information for attending the
              event. While collecting information for an event, we may also
              provide you information regarding our other services.
              <br />
            </li>
          </ul>
        </p>
        <p>
          <span className="text-xl font-bold">
            The Process of Collecting Information
          </span>
          <ul>
            <li className=" list-outside text-left my-4">
              At HARBOUR Consultancy Services, we collect personal information in
              a fair and lawful manner. We may collect your information directly
              from you, your authorized representative, or from a third party
              who provides our services to you. We may also collect information
              when we communicate with you through social media platforms such
              as LinkedIn. <br /> <br />
              We want to assure you that we only collect personal or sensitive
              information when it is necessary for our business operations or
              activities. Additionally, we will always obtain your consent
              before collecting your sensitive information. <br /> <br />
              Moreover, it is mandatory for our clients to provide us with their
              actual identities when communicating with us, and not use
              pseudonyms. |<br /> <br />
            </li>
          </ul>
        </p>
        <p>
          <span className="text-xl font-bold">
            The Activities For Which We May Collect Your Information
          </span>
          <ul>
            <li className=" list-outside text-left my-4">
              Harbour Education only collects your personal information so that
              we may serve you.
            </li>
          </ul>
        </p>
        <p>
          <span className="text-xl font-bold">
            By collecting your personal information, HARBOUR Consultancy Services
            is able to:
          </span>
          <ul className="my-6">
            <li className=" list-outside text-left my-4">
              Assist you in pursuing educational opportunities abroad.
            </li>
            <li className=" list-outside text-left my-4">
              Assist you in visa applications, including communicating with
              relevant government departments and organizations on your behalf.
            </li>
            <li className=" list-outside text-left my-4">
              Provide career guidance and support for post-education success.
            </li>
            <li className=" list-outside text-left my-4">
              Offer opportunities to become consultants in various programs or
              projects.
            </li>
            <li className=" list-outside text-left my-4">
              Conduct research and analysis for training and analytical
              purposes.
            </li>
            <li className=" list-outside text-left my-4">
              Manage events and business operations.
            </li>
            <li className=" list-outside text-left my-4">
              Respond to your inquiries and provide customer service.
            </li>
            We take privacy seriously and will not use your personal information
            for any purpose other than those stated above without your explicit
            consent.
          </ul>
        </p>
        <p>
          <span className="text-xl font-bold">
            Updates In The Privacy Policy
          </span>
          <ul className="my-6">
            <li className=" list-outside text-left mt-4 ">
              <span className="font-bold text-lg">Harbour </span>reserves the right to
              update or revise its Privacy Policy periodically. Any changes made
              will be posted on our website, and you agree to be bound by the
              most current version of our Privacy Policy. We encourage you to
              regularly review our Privacy Policy to stay informed about how we
              handle your personal information.
            </li>
          </ul>
        </p>
        <p>
          <span className="text-xl font-bold">Google Analytics</span>
          <ul className="my-6">
            <li className=" list-outside text-left mt-4">
              Harbour uses Google Analytics to analyze website usage in order to
              improve user experience and better understand website traffic. The
              data collected through Google Analytics may include the number of
              page views, unique visitors, time spent on the website, entry and
              exit points, files downloaded, and forms filled out on the
              website. This information helps us identify areas for improvement
              and optimize the website to better serve our users. We respect
              individual privacy and take appropriate measures to protect
              personal information collected through Google Analytics.
            </li>
          </ul>
        </p>
        <p>
          <span className="text-xl font-bold">Cookies</span>
          <ul className="my-6">
            <li className=" list-outside text-left mt-4 pb-24 font-semibold ">
              Cookies are data files that a website sends to a user’s web
              browser. Harbour uses cookies to improve our visitors’ experience
              on the site through selected locality, language preference, etc.
              <br /> <br />
              In case you want to disable cookies, you would have to follow the
              steps required by your browser.
              <br /> <br />
              We currently allow third party vendors to advertise on our site.
              These third parties may use cookies to analyze a visitor’s ad
              experience for the next time they visit our site. Few of the
              information collected, including age, gender, interests, etc. may
              be used for marketing means.
            </li>
          </ul>
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
