import React from "react";
import image2 from "../../../assets/images/aboutUs/picture-2.jpg";
import bgImage from "../../../assets/images/aboutUs/about-us.jpg";
import testimonial from "../../../assets/images/student/testimonial.png";
import { Link } from "react-router-dom";
import { FaUserGraduate } from "react-icons/fa";

const AboutUs = () => {
  return (
    <div className="">
      <div
        className=" bg-no-repeat bg-center"
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <h2 className="text-center text-5xl text-white p-28">About Us</h2>
      </div>

      <div className="grid grid-cols-3 gap-4 bg-white">
        <div className=" col-span-3 lg:col-span-2  p-5 mx-auto sm:p-10 md:p-16 dark:bg-gray-800 dark:text-gray-100">
          <div className="flex flex-col max-w-3xl mx-auto overflow-hidden rounded">
            <img
              src={image2}
              alt="bgImage"
              className="w-full h-60 sm:h-96 dark:bg-gray-500"
            />
            <div className="p-6 pb-12 m-4 mx-auto mt-4 space-y-6 lg:max-w-2xl sm:px-10 sm:mx-12 lg:rounded-md dark:bg-gray-900">
              <div className="dark:text-gray-100">
                <p>
                  At Harbour Education, we are committed to helping students
                  achieve their educational and career goals through expert
                  guidance and personalized support. Our team of experienced
                  education consultants have a deep understanding of the
                  Australian education system and can provide valuable insights
                  to help students make informed decisions about their academic
                  journey. At Harbour Education, we believe in the power of
                  education to transform lives and communities. That's why we
                  work tirelessly to ensure that every student we work with has
                  the best possible opportunity to achieve their dreams and
                  unlock their full potential. Harbour Education is a leading
                  education consultancy firm that provides expert guidance and
                  counseling to students who wish to study in Australia. Our
                  primary objective is to assist students in making informed
                  decisions about their higher education and career aspirations.
                  We strive to provide personalized support and guidance to
                  students throughout the entire process, from the initial
                  application to the visa process. At Harbour Education, we
                  understand that choosing the right course and university is a
                  crucial decision that can impact a student's future. Our team
                  of expert consultants has extensive knowledge and experience
                  in the Australian education system and can provide students
                  with detailed information about the universities, courses, and
                  scholarship options available. We take pride in our
                  customer-centric approach and believe in building long-lasting
                  relationships with our clients. We work closely with students
                  to understand their specific requirements and help them make
                  the best possible decisions. Our team also assists with pre-
                  departure arrangements such as accommodation and airport
                  pickups to ensure a smooth transition to the new country. In
                  conclusion, Harbour Education is your one-stop destination for
                  all your study abroad requirements. We are committed to
                  providing exceptional service to our clients and ensuring that
                  their higher education journey is a success.
                </p>
              </div>
            </div>
          </div>
          {/* mission part */}
          <div className="p-6 pb-12 m-4 mx-auto mt-4 space-y-6 lg:max-w-2xl sm:px-10 sm:mx-12 lg:rounded-md dark:bg-gray-900">
            <h2 className="text-lg text-orange-500 my-2">Our Vision</h2>
            <p>
              Our commitment as an education services provider is to help
              aspiring students to make the right education decisions for better
              future. Harbour Education guides the students through all aspects
              of planning and processing their study domestically as well as for
              overseas education. Our expertise in the field of education has
              made us an excellent resource in helping students to secure
              admission for suitable courses in recognized universities across
              the globe. To idea is to be the Leading Education Service provider
              in Australia, India, Nepal, Bangladesh and other countries to
              create success stories for our students, value additions to
              partner institutions and to all other stakeholders Harbour vision
              is to be the most efficient and most trusted international
              recruiter of students to universities and colleges in Australia,
              United States , Canada, New Zealand and other educational
              destinations across the globe. We want to remain the most
              authentic, transparent, and reachable consultants in India. Our
              experience and expertise has made us what we are now in the
              industry. We wish to continue developing healthy relationship with
              students and setting the new heights and standards in the
              Industry.
            </p>

            <Link to="/aboutUs/ourVission">
              <button className="text-red-500 my-2 text-center">
                Read More...
              </button>
            </Link>
          </div>

          {/* vission part */}

          <div className="p-6 pb-12 m-4 mx-auto mt-4 space-y-6 lg:max-w-2xl sm:px-10 sm:mx-12 lg:rounded-md dark:bg-gray-900">
            <h2 className="text-lg text-orange-500 my-2">Our Mission</h2>
            <p>
              Our commitment as an education services provider is to help
              aspiring students to make the right education decisions for better
              future. Harbour Education guides the students through all aspects
              of planning and processing their study domestically as well as for
              overseas education. Our expertise in the field of education has
              made us an excellent resource in helping students to secure
              admission for suitable courses in recognized universities across
              the globe. To idea is to be the Leading Education Service provider
              in Australia, India, Nepal, Bangladesh and other countries to
              create success stories for our students, value additions to
              partner institutions and to all other stakeholders Harbour vision
              is to be the most efficient and most trusted international
              recruiter of students to universities and colleges in Australia,
              United States , Canada, New Zealand and other educational
              destinations across the globe. We want to remain the most
              authentic, transparent, and reachable consultants in India. Our
              experience and expertise has made us what we are now in the
              industry. We wish to continue developing healthy relationship with
              students and setting the new heights and standards in the
              Industry.
            </p>

            <Link to="/aboutUs/ourMission">
              <button className="text-red-500 my-2 text-center">
                Read More...
              </button>
            </Link>
          </div>
        </div>
        <div className="hidden lg:block">
          <div className="border border-orange-500 w-3/4 mt-16 ">
            <h2 className="bg-orange-500 text-lg p-3 text-white font-bold">
              {" "}
              <span className="">Study Abroad</span>
            </h2>
            <ul className="p-2">
              <Link
                to="/education/study-in-australia"
                className="link link-hover hover:text-blue-600"
              >
                <li>
                  {" "}
                  <FaUserGraduate className="inline" /> Study in Australia
                </li>
              </Link>
              <Link
                to="/education/study-in-canada"
                className="link link-hover  hover:text-blue-600"
              >
                {" "}
                <li>
                  {" "}
                  <FaUserGraduate className="inline" /> Study in Canada
                </li>
              </Link>
              <Link
                to="/education/study-in-unitedKingdom"
                className="link link-hover  hover:text-blue-600"
              >
                <li>
                  {" "}
                  <FaUserGraduate className="inline" /> Study in United Kingdom
                </li>
              </Link>
              <Link
                to="/education/study-in-unitedStates"
                className="link link-hover  hover:text-blue-600"
              >
                <li>
                  {" "}
                  <FaUserGraduate className="inline" /> Study in United State
                </li>
              </Link>
            </ul>
          </div>
          <div className="border border-orange-500 w-3/4 mt-16">
            <h2 className="bg-orange-500 text-lg p-3 text-white font-bold">
              Testimonials
            </h2>
            <div className="italic p-2">
              <div className="avatar ml-[30%]">
                <div className="w-24 mask mask-squircle ">
                  <img
                    className="flex mx-auto"
                    src={testimonial}
                    alt="student"
                  />
                </div>
              </div>
              <p className="text-lg text-center font-semibold text-orange-500">
                {" "}
                Azmain Haqe{" "}
              </p>
              <address className="text-center">
                {" "}
                <small className="opacity-[.5] text-center">
                  {" "}
                  211/A,Mohammudpur,Dhaka{" "}
                </small>
              </address>
              <blockquote className=" text-center mx-10 lg:mx-[20%] text- italic font-semibold text-gray-900 dark:text-white">
                <svg
                  aria-hidden="true"
                  className="w-10 h-10 text-gray-400 dark:text-gray-600"
                  viewBox="0 0 24 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z"
                    fill="currentColor"
                  />
                </svg>
                <p>
                  “I loved Harbour Education, because you really become part of
                  a family with the rest of your Harbour mates. I thought that
                  was such a unique part of my time abroad when a lot of my
                  friends in other programs had hundreds of students and felt
                  little to no connection with their program. I really
                  appreciated that everyone in Harbour was so close and
                  comfortable with each other.”
                </p>
              </blockquote>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
