import React from "react";
import img1 from "../../../assets/images/banner-2/banner1.png";
import img2 from "../../../assets/images/banner-2/banner2.png";
import img3 from "../../../assets/images/banner-2/banner3.jpg";
import img4 from "../../../assets/images/banner-2/banner4.png";
import img5 from "../../../assets/images/banner-2/banner5.png";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";

import { Swiper, SwiperSlide } from "swiper/react";

import "./Banner.css";

// import required modules
import { Autoplay, Navigation, Pagination } from "swiper";

const Banner = () => {
  return (
    <Swiper
      spaceBetween={30}
      centeredSlides={true}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
      pagination={{
        clickable: true,
      }}
      navigation={true}
      modules={[Autoplay, Navigation, Pagination]}
      className="mySwiper"
    >
      <SwiperSlide>
        <img className="" src={img1} alt="banner" />
      </SwiperSlide>
      <SwiperSlide>
        <img className="" src={img2} alt="banner" />
      </SwiperSlide>
      <SwiperSlide>
        <img className="" src={img3} alt="banner" />
      </SwiperSlide>
      <SwiperSlide>
        <img className="" src={img4} alt="banner" />
      </SwiperSlide>
      <SwiperSlide>
        <img className="" src={img5} alt="banner" />
      </SwiperSlide>
    </Swiper>
  );
};

export default Banner;
