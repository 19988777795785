import React from "react";
import bgImage from "../../../assets/images/otherService/bg/2.jpg";
import img1 from "../../../assets/images/otherService/2.jpg";
import { Link } from "react-router-dom";
import EnquiryForms from "../../shared/forms/enquiry/EnquiryForms";

const VisitorHealthConver = () => {
  return (
    <div>
      <div
        className=" bg-no-repeat bg-center bg-cover  "
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <h2 className="text-center text-5xl text-white p-28">
          Overseas Visitor Health Cover (OSHC)
        </h2>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 lg:-ml-16 bg-white ">
        <div className="lg:col-span-3 mx-5  lg:ml-[15%] my-20 ">
          <p className="my-1">
            Overseas Visitors Health Cover (OVHC) is a form of health insurance
            which is designed for visitors to Australia who do not have access
            to Australia’s public Medicare system for medical or hospital
            expenses. OVHC insures against potential expenses you may incur if
            you require medical or hospital treatment.
            <br /> <br />
            In some cases, it may be a condition of your visa to hold a form of
            OVHC – for example, if you are working visa 482 or 485 applicant or
            an overseas student. <br />
          </p>

          <h2 className="text-xl font-medium my-1">What does OVHC Cover?</h2>
          <p className="my-1">
            Every OVHC policy will differ in costs, eligibility and benefits.
            However, most policies should cover you for medically-required
            hospital admissions and a portion of doctor’s fees. <br /> <br />
            In addition to cover for hospital and medical expenses, you can also
            purchase General Treatment cover. General Treatment cover pays
            benefits towards the services of health care providers in private
            practice such as dentists, optometrists and physiotherapists.
            Insurance can cover part of these costs, though you would still need
            to cover part of the fees yourself.
            <br /> <br />
            Lifetime Health Cover and the private health insurance rebate do not
            apply to overseas visitors’ health cover. Goods and Services Tax
            (GST) is applied to the cost of overseas visitors’ health cover
            purchases.
          </p>

          <h2 className="text-xl font-medium my-1">What may not be covered?</h2>
          <p className="my-1">
            Take care when you are purchasing cover as some plans will have
            lower benefits or no benefits for certain types of treatment, and
            waiting periods can vary – some policies may permanently exclude
            cover for pre-existing illnesses, meaning that such illnesses can
            never be covered. See the Tips on health insurance for visitors for
            further information about what you should look for when purchasing
            cover. <br />
            Most overseas visitors’ health cover products have limited cover for
            pharmaceuticals. Overseas visitors may face significant
            out-of-pocket costs if they need treatment with pharmaceuticals,
            particularly cancer treatment. <br />
            Travellers entering Australia may bring in medicines and medical
            devices for immediate use and to import small quantities for
            personal use. For more information see the Therapeutic Goods
            Administration website –{" "}
            <a
              className="link link-hover text-orange-500 hover:text-orange-600"
              href="https://www.tga.gov.au/entering-australia"
              target="_blank"
            >
              Entering Australia
            </a>{" "}
            and{" "}
            <a
              className="link link-hover text-orange-500 hover:text-orange-600"
              href="https://www.tga.gov.au/products/unapproved-therapeutic-goods/personal-importation-scheme"
              target="_blank"
            >
              Personal Importation Scheme.
            </a>{" "}
            <br />
          </p>

          <h2 className="text-xl font-medium my-1">
            Where can I purchase OVHC?
          </h2>
          <p className="my-1">
            You can purchase cover for visitors to Australia from some health
            insurers and some general insurers based in Australia. You can also
            opt to be covered by insurance policies issued in other countries.
            Remember, if you are on a visa with specific health insurance
            conditions, you need to purchase the type and level of health
            insurance specified by your visa.
          </p>
          <p className="my-1">
            The internet is a good way to locate insurance cover for visitors.
            We suggest you search for “Overseas Visitors Insurance” or “Overseas
            Visitors Health Cover” in a search engine and compare policies from
            at least three providers.
          </p>
          <p className="my-1">
            You may also want to consider purchasing travel insurance,
            especially if you are in Australia for a short stay. Travel
            insurance may cover you for items that are not covered on visitors’
            cover such as loss of travel goods and repatriation in a medical
            emergency. Travel insurance should be purchased in your country of
            origin, before you depart for overseas.
          </p>

          <h2 className="text-xl font-medium my-1">
            Medicare: Australia’s public health system
          </h2>
          <p className="my-1">
            Unlike Australian citizens who can use the public health system
            Medicare to cover their medical and hospital expenses, overseas
            visitors who hold temporary visas are not generally eligible to use
            Medicare.
          </p>
          <p className="my-1">
            This means that if you need medical attention while you are visiting
            Australia you could find yourself substantially out of pocket. Even
            in a public hospital you could be charged more than $1,000 a day in
            hospital fees alone. Without health cover you can find yourself
            responsible for the full cost of treatment.
          </p>
          <p className="my-1">
            If you are unsure of your Medicare eligibility status, you should
            check with your local Medicare office or contact the Medicare
            (Services Australia).
          </p>
          <p className="my-1">
            If your visa status or Medicare eligibility changes at any time,
            inform your insurer as soon as possible to find out whether your
            level of cover is still suitable.
          </p>
          <h2 className="text-xl font-medium my-1">Reciprocal Medicare</h2>
          <p className="my-1">
            If you are an overseas visitors from the United Kingdom, Sweden,
            Belgium, Finland, Italy, Malta, the Netherlands, Slovenia, the
            Republic of Ireland, Norway and New Zealand, you generally have
            access to Medicare under your country’s reciprocal health care
            agreement with Australia. This means you can receive emergency
            treatment in the public health system free of charge, which you
            would not otherwise be entitled to.
          </p>
          <p className="my-1">
            However, reciprocal Medicare benefits do not cover you for treatment
            as a private patient in hospital, and depending on your country,
            other limits may apply (for example, in some cases the benefits
            expire after you have been in Australia for a certain period of
            time). Check the conditions of your country’s reciprocal Medicare
            agreement to confirm what benefits and limits apply to you.
          </p>
          <p className="my-1">
            You should consider taking OVHC if you want to be covered for items
            that are not covered under the agreements.
          </p>
          <h2 className="text-xl font-medium my-2">
            How the Medicare Levy Surcharge might affect the type of health
            insurance you need
          </h2>
          <p className="my-1">If you are:</p>
          <li>
            on a high income (over $90,000 as a single or $180,000 as a couple
            or family in the current financial year);
          </li>
          <li>considered resident in Australia for tax purposes; and</li>
          <li>
            have successfully enrolled in Medicare (for any level of benefits –
            reciprocal, interim, or full)
          </li>
          <p className="my-2">
            then you may be affected by the Medicare Levy Surcharge. For further
            information about the Surcharge and the type of health insurance
            policy that exempts you from the Surcharge, please refer to the
            Medicare Levy Surcharge.
          </p>
        </div>

        <div>
          <div className="avatar">
            <div className="w-full rounded my-12 mx-7">
              <img src={img1} alt="cover" />
            </div>
          </div>

          <ul>
            <Link to="/otherService/student-health-cover">
              <li className="text-white bg-orange-500 hover:bg-blue-600 rounded my-3 mx-7 p-2 text-center">
                Overseas Student Health Cover(OSHC)
              </li>
            </Link>
            <Link to="/otherService/visitor-health-cover">
              <li className="text-white bg-orange-500 hover:bg-blue-600 rounded my-3 mx-7 p-2 text-center">
                Overseas Visitor Health Cover(OVHC)
              </li>
            </Link>
            <Link to="/otherService/sc-485-sc-482-health">
              <li className="text-white bg-orange-500 hover:bg-blue-600 rounded my-3 mx-7 p-2 text-center">
                SC 485 / SC 482 Health
              </li>
            </Link>
            <Link to="/otherService/job-help">
              <li className=" text-white bg-orange-500 hover:bg-blue-600 rounded my-3 mx-7 p-2 text-center">
                Job Help
              </li>
            </Link>
            <Link to="/otherService/accommodation-help">
              {" "}
              <li className=" text-white bg-orange-500 hover:bg-blue-600 rounded my-3 mx-7 p-2 text-center">
                Accommodation Help
              </li>
            </Link>
            <Link to="/otherService/airport-pick-u-services">
              {" "}
              <li className="text-white bg-orange-500 hover:bg-blue-600 rounded my-3 mx-7 p-2 text-center">
                Airport Pick up Services
              </li>
            </Link>
          </ul>
          <div>
           <EnquiryForms/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisitorHealthConver;
