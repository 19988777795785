import React from "react";
import bgImage from "../../../assets/images/otherService/bg/job_help.png";
import img1 from "../../../assets/images/otherService/4.jpg";
import { Link } from "react-router-dom";
import EnquiryForms from "../../shared/forms/enquiry/EnquiryForms";

const JobHelp = () => {
  return (
    <div>
      <div
        className=" bg-no-repeat bg-center bg-cover  "
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <h2 className="text-center text-5xl text-white p-28">Job Help</h2>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 lg:-ml-16 bg-white ">
        <div className="lg:col-span-3 mx-5  lg:ml-[15%] my-20 ">
          <h2 className="text-xl font-normal my-2">
            Health insurance for your 482 Temporary Skill Shortage visa
          </h2>
          <p className=" my-1">
            For domestic students, overseas work experience can really help your
            resume stand out. Even short-term programs overseas help you develop
            valuable professional and life skills like cultural awareness and
            competency, initiative, problem-solving, cross-cultural
            communication, and working within a team.
            <br />
          </p>
          <p className="py-2">
            All the planning and research that goes into travelling, studying,
            or working abroad helps you to develop employable skills before you
            even leave Australia. <br /> <br />
            If you don’t have enough space in your degree for a semester abroad,
            there are other experiences to consider:
          </p>
          <li>
            {" "}
            Working full-time or part-time in a professional capacity – a work
            while you study approach. Use your network to tap into
            opportunities, contact overseas recruitment agencies, or look
            through job searching websites.
          </li>
          <li> Working holidays</li>
          <li>Volunteering</li>
          <li>
            {" "}
            Vacation or other short-term programs and overseas internships
          </li>
          <h2 className="text-xl font-medium my-2">Get prepared</h2>
          <p className="my-2">
            When preparing for work overseas, ask yourself these questions:
            <br />
          </p>
          <li>What kind of work experience do I want?</li>
          <li>
            Do I want to go through a program or make independent arrangements?
          </li>
          <li>What programs could I apply for independently?</li>
          <li>
            Do I need work visas — check your eligibility for working in a
            particular country by contacting the relevant foreign embassy.{" "}
            <a
              className="link link-hover text-orange-500 hover:text-orange-600"
              href="https://www.dfat.gov.au/about-us/our-locations/missions/Pages/our-embassies-and-consulates-overseas.aspx"
              target="_blank"
            >
              The Department of Foreign Affairs and Trade
            </a>{" "}
            (DFAT) provides a list of embassies on their website. A working
            holiday visa is a useful way to try living in a new country.
            <a
              className="link link-hover text-orange-500 hover:text-orange-600"
              href="https://immi.homeaffairs.gov.au/what-we-do/whm-program/working-holidays-for-australians"
              target="_blank"
            >
              {" "}
              Working Holidays Overseas for Australians
            </a>{" "}
            provides advice on visas available to young Australians.
          </li>
          <li>
            What do I need to know about the country I am considering? Refer to
            Smart Traveller for a starting point in learning more about your new
            country.
          </li>
          <h2 className="text-xl font-normal my-2">
            Overseas opportunities available through Harbour Education
          </h2>
          <p>
            Harbour Education offer the opportunity to gain international work
            experience as part of your degree with one of our vast network of
            host organisations around the world.
          </p>
          <h3 className="text-lg font-medium my-2">Who can help?</h3>
          <p>
            If you have additional questions around working overseas, please
            email the Career and Employment Service at harbourgroupbd@gmail.com
          </p>
        </div>

        <div>
          <div className="avatar">
            <div className="w-full rounded my-12 mx-7">
              <img src={img1} alt="cover" />
            </div>
          </div>

          <ul>
            <Link to="/otherService/student-health-cover">
              <li className="text-white bg-orange-500 hover:bg-blue-600 rounded my-3 mx-7 p-2 text-center">
                Overseas Student Health Cover(OSHC)
              </li>
            </Link>
            <Link to="/otherService/visitor-health-cover">
              <li className="text-white bg-orange-500 hover:bg-blue-600 rounded my-3 mx-7 p-2 text-center">
                Overseas Visitor Health Cover(OVHC)
              </li>
            </Link>
            <Link to="/otherService/sc-485-sc-482-health">
              <li className="text-white bg-orange-500 hover:bg-blue-600 rounded my-3 mx-7 p-2 text-center">
                SC 485 / SC 482 Health
              </li>
            </Link>
            <Link to="/otherService/job-help">
              <li className=" text-white bg-orange-500 hover:bg-blue-600 rounded my-3 mx-7 p-2 text-center">
                Job Help
              </li>
            </Link>
            <Link to="/otherService/accommodation-help">
              {" "}
              <li className=" text-white bg-orange-500 hover:bg-blue-600 rounded my-3 mx-7 p-2 text-center">
                Accommodation Help
              </li>
            </Link>
            <Link to="/otherService/airport-pick-u-services">
              {" "}
              <li className="text-white bg-orange-500 hover:bg-blue-600 rounded my-3 mx-7 p-2 text-center">
                Airport Pick up Services
              </li>
            </Link>
          </ul>
          <div>
            <EnquiryForms/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobHelp;
