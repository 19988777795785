import React from "react";
import CountrySlider from "./CountrySlider";

const Countrys = () => {
  return (
    <div className="my-10 lg:my-28 ">
      <div className="text-center sm:bg-base-200 lg:bg-white sm:my-10  ">
        <h1 className="text-4xl  text-orange-500 my-10 ">
          We will help you with your Overseas Education
        </h1>
        {/* <div className="w-44 h-[2px] bg-orange-500 mx-auto mt-2"></div> */}
        <p className="mx-8 lg:mx-32 my-10 text-gray-500">
          Take the first step of studying abroad by picking the country of your
          choice. Find a host of opportunities to pursue your Bachelors, Masters
          or Doctorate in Australia, Canada, United Kingdom, United States of
          America.
        </p>
      </div>
      <div className=" p-2 lg:p-6 relative z-10">
        <CountrySlider />
      </div>
    </div>
  );
};

export default Countrys;
