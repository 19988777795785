import React from "react";
import Banner from "./banner/Banner";
import Countrys from "./countrys/Countrys";
import Services from "../home/services/Services";
import Students from "./students/Students";
import CollegeName from "./collegeNmae/CollegeName";

function Home(){
    return(
      <div className="bg-white">
      <Banner/>
      <Countrys/>
      <Services/>
      <CollegeName/>
      <Students/>
      </div>
    )
}
export default Home;