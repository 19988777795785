import React from "react";
import bgImage from "../../../assets/images/aboutUs/about-us.jpg";
import testimonial from "../../../assets/images/student/testimonial.png";
import { Link } from "react-router-dom";
import { FaUserGraduate } from "react-icons/fa";

const MigrationVisaHelp = () => {
  return (
    <div className=" bg-white">
      <div
        className=" bg-no-repeat bg-center "
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <h2 className="text-center text-5xl text-white p-28">
          Migration and Visa Help
        </h2>
      </div>

      <div className="grid grid-cols-4 gap-4 lg:-ml-16">
        <div className=" col-span-4 lg:col-span-3  p-5 sm:p-10 md:p-16 dark:bg-gray-800 dark:text-gray-100">
          <div className="m-4  mt-4 space-y-6 sm:px-10 sm:mx-12 lg:rounded-md dark:bg-gray-900">
            <div className="dark:text-gray-100">
              <h2 className="text-xl font-normal">Visa Guidance</h2>
              <p>
                Harbour Education and Migration Provides honest and reliable
                Guidance in
                <br />
                <br />
                <ul className="list-outside list-disc ml-5">
                  <li>Visa Documentation</li>
                  <li>
                    Personal Interview Preparation to candidates attending the
                    various Consulates/High Commissions for obtaining Visa.
                  </li>
                  <li>
                    Various complex processes involved in various visa types and
                    overall
                  </li>
                  <li>Going through whole visa process.</li>
                </ul>
                <br />
              </p>
              <p>
                Whether you are looking for work visa to Australia or sponosored
                visa to Australia, we can help you. We can cater and service any
                of below visa categories with unmatched expertise from our
                registered migration lawyers:
                <br />
                <li>TSS SC 482 Visa – Short to Medium term work visa</li>
                <li>SC 491 Visa – Employer Nomination Scheme Visa</li>
                <li>SC 820 / SC 801 Visa – Onshore Spouse Visa</li>
                <li>SC 600 Visa – Visitor Visa to Australia</li>
                <li>SC 500 Visa – Student Visa</li>
                <li>SC 189 Visa</li>
                <li>SC 190 Visa</li>
                <li>SC 494 Visa</li>
                <li>SC 407 Visa – Training Visa</li>
                <li>SC 408 Visa – Temporary Activity Visa</li>
                <br />
                So whether you are looking for temporary stay Visa for Australia
                or permanent residency visa for Australia, contact team at
                Harbour Education and Migration now for honest and reliable
                guidance. <br /> <br />
                We have the latest information in visa related issues and we
                ensure our students are kept informed. Sometimes even the most
                deserving students fail to get Visas, as they do not have the
                knowledge to present data before the consulate office. Our mock
                interviews and expert guidance in document preparation help
                students become more confident and clear the VISA interview in
                the first attempt.
              </p>

              <h2 className="text-xl font-normal mt-4">Student Visas</h2>
              <p>
                Student Visas are issued for the period it takes the visitor to
                complete his/her course of study, program or work assignment.
                <br />
                <br />
                Requirements for obtaining student visa are: Admission in a
                recognized University, Provision of fees, Financial capacity to
                meet lodging and boarding expenses, Ties – financial and family,
                to make the student return to home country.
                <br />
                <br />
                Steps for obtaining student visa are: Scoring marks high in
                TOFEL or IELTS, Offer letter from a foreign recognized and
                accredited University and in the Visa interview, which is the
                third and the last step, the applicant of a Student Visa should
                know that his Passport, Bank Certificates and Offer Letter
                reveal all necessary particulars to the Consular Officer.
                <br />
                <br />
              </p>
              <h2 className="text-xl font-normal mt-4">
                Important Tips for Visa Interview
              </h2>
              <p>
                The Visa interview is the grand finale for all the students who
                intend to study abroad. A student may go on any day for the Visa
                interview, in general in the 90 days period prior to the expiry
                date mentioned on the Offer Letter/Letter of Admission /
                Acceptance or the I-20.
                <br />
                <br />
                We at Harbour Education believe that a good preparation and
                systematic Visa file containing all the Documentation required
                helps a student to reply promptly and confidently at the Visa
                interview. We assist the student in this respect by conducting
                mock interviews to ensure that a student is confident, clear and
                not only thoughtful but also truthful in the Visa interview.
                Student should tackle the questions asked tactfully.
                <br />
                <br />
                Under Australian immigration law, anyone who wishes to operate
                as a migration agent or would like to provide migration advise
                must be registered with the Migration Agents Registration
                Authority (“MARA”). Harbour Education Migration and Financial
                Services Confirms that all its Migration agents are registered
                with MARA which is responsible authority for administrating the
                Code of Conduct for Migration Agents in Australia. The Code is
                intended to regulate the conduct of migration agents. Amongst
                the requirements of the Code, is the ability of agents to
                demonstrate good character as well as competency in the
                provision of Australian immigration advice.
                <br />
                <br />
                A copy of the Code of Conduct can be found in Schedule 2 of the
                Migration Agents Regulations 1998.
                <br />
                <br />
                <a
                  className="link link-hover text-orange-500 hover:text-orange-600"
                  href="https://www.mara.gov.au/tools-for-registered-agents/code-of-conduct"
                  target="_blank"
                >
                  To view the Code of Conduct please click here
                </a>{" "}
                <br />
                For a Scopy of the Information on the Regulation of the
                Migration Advice Profession
                <a
                  className="ml-1 link link-hover text-orange-500 hover:text-orange-600"
                  href="http://www.thisisaustralia.com/wp-content/uploads/2015/12/ENGLISH_Consumer-Guide_New.pdf"
                  target="_blank"
                >
                  Click here
                </a>
                <br />
              </p>
              <p className="py-8">Contact us now: harbourgroupbd@gmail.com</p>
            </div>
          </div>
        </div>
        <div className="hidden lg:block">
          <div className="border border-orange-500 w-3/4 mt-16 ">
            <h2 className="bg-orange-500 text-lg p-3 text-white font-bold">
              <span className="">Study Abroad</span>
            </h2>
            <ul className="p-2">
              <Link
                to="/education/study-in-australia"
                className="link link-hover hover:text-blue-600"
              >
                <li>
                  <FaUserGraduate className="inline" /> Study in Australia
                </li>
              </Link>
              <Link
                to="/education/study-in-canada"
                className="link link-hover  hover:text-blue-600"
              >
                <li>
                  <FaUserGraduate className="inline" /> Study in Canada
                </li>
              </Link>
              <Link
                to="/education/study-in-unitedKingdom"
                className="link link-hover  hover:text-blue-600"
              >
                <li>
                  <FaUserGraduate className="inline" /> Study in United Kingdom
                </li>
              </Link>
              <Link
                to="/education/study-in-unitedStates"
                className="link link-hover  hover:text-blue-600"
              >
                <li>
                  <FaUserGraduate className="inline" /> Study in United State
                </li>
              </Link>
            </ul>
          </div>
          <div className="border border-orange-500 w-3/4 mt-16">
            <h2 className="bg-orange-500 text-lg p-3 text-white font-bold">
              Testimonials
            </h2>
            <div className="italic p-2">
              <div className="avatar ml-[30%]">
                <div className="w-24 mask mask-squircle ">
                  <img
                    className="flex mx-auto"
                    src={testimonial}
                    alt="student"
                  />
                </div>
              </div>
              <p className="text-lg text-center font-semibold text-orange-500">
                Azmain Haqe{" "}
              </p>
              <address className="text-center">
                <small className="opacity-[.5] text-center">
                  211/A,Mohammudpur,Dhaka{" "}
                </small>
              </address>
              <blockquote className=" text-center mx-10 lg:mx-[20%] text- italic font-semibold text-gray-900 dark:text-white">
                <svg
                  aria-hidden="true"
                  className="w-10 h-10 text-gray-400 dark:text-gray-600"
                  viewBox="0 0 24 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z"
                    fill="currentColor"
                  />
                </svg>
                <p>
                  “I loved Harbour Education, because you really become part of
                  a family with the rest of your Harbour mates. I thought that
                  was such a unique part of my time abroad when a lot of my
                  friends in other programs had hundreds of students and felt
                  little to no connection with their program. I really
                  appreciated that everyone in Harbour was so close and
                  comfortable with each other.”
                </p>
              </blockquote>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MigrationVisaHelp;
