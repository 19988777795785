import React from "react";
import bgImage from "../../../assets/images/aboutUs/about-us.jpg";
import testimonial from "../../../assets/images/student/testimonial.png";
import { Link } from "react-router-dom";
import { FaUserGraduate } from "react-icons/fa";

const OurMission = () => {
  return (
    <div className=" bg-white">
      <div
        className=" bg-no-repeat bg-center "
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <h2 className="text-center text-5xl text-white p-28">Our Mission</h2>
      </div>

      <div className="grid grid-cols-4 gap-4 lg:-ml-16">
        <div className=" col-span-4 lg:col-span-3  p-5 sm:p-10 md:p-16 dark:bg-gray-800 dark:text-gray-100">
          <div className="m-4  mt-4 space-y-6 sm:px-10 sm:mx-12 lg:rounded-md dark:bg-gray-900">
            <div className="dark:text-gray-100">
              <p>
                <strong>Harbour</strong> is the foremost international students
                recruiting company employing the services of a team from
                Australia in committed and credible education professionals.{" "}
                <br /> <br />
                At Harbour, our mission is to provide comprehensive guidance and
                support to students seeking to pursue their academic goals
                abroad. We aim to empower students to make informed decisions
                and navigate the complex process of going abroad for higher
                education with confidence and ease. Our team of experienced
                consultants is committed to delivering personalized services
                that cater to each student's unique needs and aspirations.
              </p>
            </div>
            <div>
              <h2 className="text-2xl font-medium">Business Objectives</h2>{" "}
              <br />
              <p>
                We pace forward with a single minded devotion towards the cause
                of promoting high quality harbour education, widespread among
                different sections of the student community in Asia. In order to
                do that efficiently, we’ve developed a very strong network of
                acquaintance and business relationship with numerous educational
                institutions across India. <br /> <br />
                To do this, we’re backed by a dedicated, experienced and well
                versed team of industry professionals who look into student
                counseling, assistance provision and unconditional support so
                that our students will be at the best receiving end. Their
                technical prowess is highly lauded in the industry and we solely
                believe that its knowledge that empowers any organization and we
                run on that principle. It always worked! <br /> <br />
                <li>
                  Increase client base: One key objective for Harbour could be
                  to attract and retain more clients seeking assistance with
                  studying abroad. This could involve developing marketing
                  strategies to reach a wider audience, building relationships
                  with schools and universities, and offering referral
                  incentives.
                </li>
                <li>
                  Expand services: Another objective could be to expand the
                  range of services offered by Harbour, such as visa application
                  assistance, test preparation, or cultural orientation. This
                  could involve hiring additional staff with expertise in these
                  areas, or partnering with other companies to provide these
                  services.
                </li>
                <li>
                  Enhance customer satisfaction: Harbour could prioritize
                  improving customer satisfaction by offering personalized
                  services, responding promptly to client inquiries, and
                  soliciting feedback to improve the quality of services
                  offered.
                </li>
                <li>
                  Increase profitability: As a business, Harbour could set
                  objectives around increasing profitability, such as increasing
                  revenue through more clients or by increasing the average
                  value of each client engagement. This could involve optimizing
                  pricing strategies, streamlining operations to reduce costs,
                  or identifying new revenue streams.
                </li>
                <li>
                  Develop strategic partnerships: Harbour could set objectives
                  around developing strategic partnerships with schools,
                  universities, or other organizations in the education sector.
                  This could involve identifying complementary services or
                  products and collaborating to offer bundled services to
                  clients.
                </li>
                These are just a few potential business objectives that Harbour
                could pursue. The specific objectives will depend on the goals
                and priorities of the company, as well as the competitive
                landscape and market dynamics in the industry.
              </p>
            </div>
            <div>
              <p className="font-bold my-1">We market this way</p>
              <p className="font-semibold my-2">Harbour educational fairs</p>
              <p>
                Upon deliberation, we always are successful in bringing down the
                representatives of our authorized institutions to conduct
                education fairs and harbour education awareness campaigns to
                where we invite students. Thereby, we’re successful in creating
                interactive platforms where a lot of information exchange
                between the institutions and students will take place. These
                educational fairs also include spot admission opportunities
                which students can avail depending upon their eligibility and
                interest.
              </p>
              <h2 className="text-lg my-2 ">We make ourselves heard</h2>
              <p>
                It has been a part of our priority list that we make sure that
                our voice is heard. We feature ourselves in the popular forums
                and discussions and we spread out the good word about harbour
                education. We know empowerment happens via information exchange
                and we never make excuses for staying calm. In this way, we
                educate the students about the latest trends and keep them
                informed, time to time.
              </p>
              <h2 className="text-xl my-2">Advertising never failed us</h2>
              <p>
                We look to capture prominent spaces in the famous publications
                so that our word reaches out there to the student masses in the
                most rewarding way possible. We always stay available for the
                leading publications to spread out the updates and information
                about harbour education. We have a strong bench of supporting
                professionals dispersed proportionately across the country to
                stay available to advertising firms and publications.
              </p>
              <h2 className="text-xl my-2">Workshops do work out</h2>
              <p>
                We believe in creating platforms where a lot of information can
                be exchanged. We conduct workshops very rigorously in and out
                across the target locations to empower students regarding
                harbour education. We bring together the student recruiters and
                students onto a single platform, let them share views, opinions
                and information. We also pick up a program oriented approach to
                promote the most booming major of any particular nation or
                institution.
              </p>
            </div>
          </div>
        </div>
        <div className="hidden lg:block">
          <div className="border border-orange-500 w-3/4 mt-16 ">
            <h2 className="bg-orange-500 text-lg p-3 text-white font-bold">
              {" "}
              <span className="">Study Abroad</span>
            </h2>
            <ul className="p-2">
              <Link
                to="/education/study-in-australia"
                className="link link-hover hover:text-blue-600"
              >
                <li>
                  {" "}
                  <FaUserGraduate className="inline" /> Study in Australia
                </li>
              </Link>
              <Link
                to="/education/study-in-canada"
                className="link link-hover  hover:text-blue-600"
              >
                {" "}
                <li>
                  {" "}
                  <FaUserGraduate className="inline" /> Study in Canada
                </li>
              </Link>
              <Link
                to="/education/study-in-unitedKingdom"
                className="link link-hover  hover:text-blue-600"
              >
                <li>
                  {" "}
                  <FaUserGraduate className="inline" /> Study in United Kingdom
                </li>
              </Link>
              <Link
                to="/education/study-in-unitedStates"
                className="link link-hover  hover:text-blue-600"
              >
                <li>
                  {" "}
                  <FaUserGraduate className="inline" /> Study in United State
                </li>
              </Link>
            </ul>
          </div>
          <div className="border border-orange-500 w-3/4 mt-16">
            <h2 className="bg-orange-500 text-lg p-3 text-white font-bold">
              Testimonials
            </h2>
            <div className="italic p-2">
              <div className="avatar ml-[30%]">
                <div className="w-24 mask mask-squircle ">
                  <img
                    className="flex mx-auto"
                    src={testimonial}
                    alt="student"
                  />
                </div>
              </div>
              <p className="text-lg text-center font-semibold text-orange-500">
                {" "}
                Azmain Haqe{" "}
              </p>
              <address className="text-center">
                {" "}
                <small className="opacity-[.5] text-center">
                  {" "}
                  211/A,Mohammudpur,Dhaka{" "}
                </small>
              </address>
              <blockquote className=" text-center mx-10 lg:mx-[20%] text- italic font-semibold text-gray-900 dark:text-white">
                <svg
                  aria-hidden="true"
                  className="w-10 h-10 text-gray-400 dark:text-gray-600"
                  viewBox="0 0 24 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z"
                    fill="currentColor"
                  />
                </svg>
                <p>
                  “I loved Harbour EducSation, because you really become part of
                  a family with the rest of your Harbour mates. I thought that
                  was such a unique part of my time abroad when a lot of my
                  friends in other programs had hundreds of students and felt
                  little to no connection with their program. I really
                  appreciated that everyone in Harbour was so close and
                  comfortable with each other.”
                </p>
              </blockquote>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurMission;
