import React from "react";
import bgImage from "../../../../assets/images/study in/australia/classification/bg.png";
import img1 from "../../../../assets/images/study in/australia/3.jpg";
import { Link } from "react-router-dom";
import EnquiryForms from "../../../shared/forms/enquiry/EnquiryForms";
const AustralianStudyAdmissions = () => {
  return (
    <div>
      <div
        className=" bg-no-repeat bg-center bg-cover"
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <h2 className="text-center text-5xl text-white p-28">
          Australian Study Admissions Process
        </h2>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 lg:-ml-16 bg-white ">
        <div className="lg:col-span-3 mx-5  lg:ml-[15%] my-20 ">
          <h2 className="text-xl font-normal my-2 ">
            Minimum processing time for getting an admit is 3 – 4 weeks after
            submitting to the university
          </h2>
          <h2 className="text-xl font-bold my-2 bg-gradient-to-r from-slate-200 p-1">
            Step 1 – Start Processing Student VISA
          </h2>
          <p className=" my-1">
            Before you make a decision to choose the course , you have to
            research for course, institution. Location, cost and future scope.
            We strongly advise you to choose course which is relevant to your
            background, experience, interested course and financial Situation.
            <br />
          </p>

          <h2 className="text-xl font-bold my-4 bg-gradient-to-r from-slate-200 p-1">
            Step 2 – Eligibility Test for Admission
          </h2>
          <p className=" my-1">
            Entry requirement is vary depends on the level of qualification and
            individual university admission policy, the common entry requirement
            for all diploma, undergraduate and postgraduate courses are:
            <br />
          </p>
          <li>IELTS/PTE/TOEFL</li>
          <li>
            recognised degree completed academic degree (diploma, bachelor or
            master) Valid passport
          </li>
          <li>Resume</li>
          <li>Work Experience if applicable</li>

          <h2 className="text-xl font-bold my-4 bg-gradient-to-r from-slate-200 p-1">
            Step 3 – Offer Letter and Confirmation of Enrollment (COE)
          </h2>
          <p className=" my-1">
            Required above mention scan documents. Onces we receive your
            documents, we will appy for the university and will receive the
            offer letter within 7-10 working days. After receiving the offer
            letter we have to make the payment for tution fee and overseas
            student health cover (OSHC). We will provide you the complete
            information about the payment. After the payment you will receive
            CoE within one week.
            <br />
          </p>

          <h2 className="text-xl font-bold my-4 bg-gradient-to-r from-slate-200 p-1">
            Step 4 – Student VISA Lodgement in Australia High Commission
          </h2>
          <p className=" my-1">
            Once you recive CoE and OSHC from us then you can prepare for visa
            application. You must provide all the set of documents. Visa fee in
            local currency and requirement may vary depends on country to
            county. For More details about visa procedures and requirements,
            Please vist website:{" "}
            <a
              className="link link-hover text-orange-500 hover:text-orange-600"
              href=" http://www.immi.gov.au"
              target="_blank"
            >
              {" "}
              http://www.immi.gov.au
            </a>
            <br />
          </p>

          <h2 className="text-xl font-bold my-4 bg-gradient-to-r from-slate-200 p-1">
            Step 5 – Decision of your VISA Application
          </h2>
          <p className=" my-1">
            If you lodged valid application, the department of immigration and
            citizenship will assess your application and make the decision based
            on your academic records, evidence of financial support and meetong
            requirements of the genuine entrant student critreia.
            <br />
          </p>

          <h2 className="text-xl font-bold my-4">Student visa sub-classes</h2>
          <p className=" my-1">
            If you lodged valid application, the department of immigration and
            citizenship will assess your application and make the decision based
            on your academic records, evidence of financial support and meetong
            requirements of the genuine entrant student critreia.
            <br />
          </p>

          <h2 className="font-bold mt-10">
            ***Student visa will be extended for 2 years after completion of his
            masters for job search
          </h2>
        </div>

        <div>
          <div className="avatar">
            <div className="w-full rounded my-12 mx-7">
              <img src={img1} alt="cover" />
            </div>
          </div>

          <ul clasName="">
            <Link to="/australian-education-system">
              <li className="text-white bg-orange-500 hover:bg-blue-600 rounded my-3 mx-7 p-2 text-center">
                Australian Education System
              </li>
            </Link>
            <Link to="/universities-and-colleges-in-australia">
              <li className="text-white bg-orange-500 hover:bg-blue-600 rounded my-3 mx-7 p-2 text-center">
                Universities and Colleges in Australia
              </li>
            </Link>
            <Link to="/australian-study-admissions-process">
              <li className="text-white bg-orange-500 hover:bg-blue-600 rounded my-3 mx-7 p-2 text-center">
                Australian Study Admissions Process
              </li>
            </Link>
            <Link to="/australian-study-document-checklist">
              <li className=" text-white bg-orange-500 hover:bg-blue-600 rounded my-3 mx-7 p-2 text-center">
                Australian Study Document Checklist
              </li>
            </Link>
            <Link to="/accommodation-help">
              {" "}
              <li className=" text-white bg-orange-500 hover:bg-blue-600 rounded my-3 mx-7 p-2 text-center">
                Accommodation Help
              </li>
            </Link>
            <Link to="/australian-student-visa-application-process">
              {" "}
              <li className="text-white bg-orange-500 hover:bg-blue-600 rounded my-3 mx-7 p-2 text-center">
                Australian Student Visa Application Process
              </li>
            </Link>
            <Link to="/about-australia-and-its-weather">
              {" "}
              <li className="text-white bg-orange-500 hover:bg-blue-600 rounded my-3 mx-7 p-2 text-center">
                About Australia and its Weather
              </li>
            </Link>
          </ul>
          <div>
            <EnquiryForms/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AustralianStudyAdmissions;
