import React from "react";

import MultiSlider from "./MultiSlider";

const CollegeName = () => {
  return (
    <div className="bg-white mb-48">
      <section className="py-6  dark:text-gray-100">
        <div className="container p-4 mx-auto space-y-16 sm:p-10">
          <div className="space-y-4 ">
            <h3 className="text-5xl  text-center font-medium text-orange-500  leading-none mb-16">
              Trusted by Leading Institutions
            </h3>
          </div>
        </div>
      </section>
      <div>
        <MultiSlider />
      </div>
    </div>
  );
};

export default CollegeName;
