import React from 'react';
import { toast } from 'react-hot-toast';

const EnquiryForms = () => {
    const handlerSubmit=(e)=>{
        e.preventDefault()
        const form = e.target;
        toast.success("Thank you for your enquiry, we will contact you soon.");
        form.reset()
      }

    return (
        <div className="border border-orange-500 bg-base-200 rounded-md shadow-2xl w-5/6 my-12 mx-7 p-5 ">
              <h2 className="text-2xl mb-2 font-semibold text-orange-500">
                Quick Enquiry{" "}
              </h2>
              <p className="text-left ">
                We are here to help you with all of your queries. Reach out to
                us and one of our representatives will be in touch with you
                immediately.
              </p>
              <div className="divider"></div>
              <form 
              onSubmit={handlerSubmit}
              className="p-1">
                <label className="block">
                  <textarea
                    name="message*"
                    rows="3"
                    placeholder="Write your message here"
                    required
                    className=" w-full textarea border border-orange-500 "
                  ></textarea>
                </label>

                <label className="block">
                  <input
                    type="text"
                    name="user_name"
                    placeholder="Enter your full name*"
                    required
                    className="input w-full border border-orange-500 my-2"
                  />
                </label>
                <label className="block">
                  <input
                    type="email"
                    name="user_email"
                    placeholder="Enter your email*"
                    required
                    className="input w-full border border-orange-500 my-2"
                  />
                </label>
                <label className="block">
                  <input
                    type="phone"
                    name="mobile"
                    placeholder="Mobile number*"
                    required
                    className="input w-full border border-orange-500 my-2"
                  />
                </label>

                <input
                  type="submit"
                  value="Send Message"
                  className="  border border-orange-500 text-orange-500  hover:bg-zinc-100 font-medium text-sm rounded-lg lg:px-8 lg:btn-md "
                />
              </form>
            </div>
    );
};

export default EnquiryForms;