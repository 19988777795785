import React from "react";
import bgImage from "../../../assets/images/aboutUs/about-us.jpg";
import testimonial from "../../../assets/images/student/testimonial.png";
import { Link } from "react-router-dom";
import { FaUserGraduate } from "react-icons/fa";
import Pdf1 from "../../../assets/document/list_colleges1.pdf";
import Pdf2 from "../../../assets/document/list_universities1.pdf";

const OurVission = () => {
  return (
    <div className=" bg-white">
      <div
        className=" bg-no-repeat bg-center "
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <h2 className="text-center text-5xl text-white p-28">Our Vission</h2>
      </div>

      <div className="grid grid-cols-4 gap-4 lg:-ml-16">
        <div className=" col-span-4 lg:col-span-3  p-5 sm:p-10 md:p-16 dark:bg-gray-800 dark:text-gray-100">
          <div className="m-4  mt-4 space-y-6 sm:px-10 sm:mx-12 lg:rounded-md dark:bg-gray-900">
            <div className="dark:text-gray-100">
              <p>
                Our vision at Harbour is to be a premier student consultancy
                firm for going abroad for higher education, renowned for our
                excellence in service, innovation, and impact. We envision a
                future where students have access to the best academic
                opportunities worldwide, regardless of their backgrounds or
                circumstances. <br />
                <br />
                We strive to be a trusted partner for students, universities,
                and communities, working together to build a more inclusive,
                diverse, and sustainable world.
              </p>
              <h2 className="text-2xl font-medium mt-3">
                Here are some visions for Harbour, your student consultancy firm
                for going abroad for higher education:
              </h2>{" "}
              <br />
              <ul className="list-decimal">
                <li>
                  To be a leading provider of comprehensive study abroad
                  services: Harbour envisions becoming a recognized leader in
                  the study abroad industry, known for its high-quality and
                  comprehensive services that support students at every stage of
                  the process.
                </li>
                <li>
                  To make study abroad accessible and inclusive: Harbour is
                  committed to making study abroad opportunities accessible to
                  all students, regardless of their background or circumstances.
                  This vision includes promoting diversity and inclusion in all
                  aspects of the study abroad experience.
                </li>
                <li>
                  To foster global citizens: Harbour&#39;s vision is to help
                  students become global citizens who are equipped with the
                  knowledge, skills, and cultural sensitivity needed to thrive
                  in an interconnected world.
                </li>
                <li>
                  To continuously innovate and improve: Harbour aims to
                  continuously innovate and improve its services to meet the
                  evolving needs of students and the study abroad industry. This
                  includes leveraging technology, partnering with other
                  organizations, and investing in staff development.
                </li>
                <li>
                  To build lasting relationships with clients and partners:
                  Harbour envisions building strong, lasting relationships with
                  its clients and partners in the education industry, based on
                  trust, transparency, and mutual benefit.
                </li>
              </ul>
              <p className="mt-5">
                These are just a few visions for Harbour, and the specific
                vision for the company will depend on its goals, values, and
                long-term aspirations.
              </p>
            </div>
          </div>
        </div>
        <div className="hidden lg:block mb-6">
          <div className="border border-orange-500 w-3/4 mt-16 ">
            <h2 className="bg-orange-500 text-lg p-3 text-white font-bold">
              {" "}
              <span className="">Study Abroad</span>
            </h2>
            <ul className="p-2">
              <Link
                to="/education/study-in-australia"
                className="link link-hover hover:text-blue-600"
              >
                <li>
                  {" "}
                  <FaUserGraduate className="inline" /> Study in Australia
                </li>
              </Link>
              <Link
                to="/education/study-in-canada"
                className="link link-hover  hover:text-blue-600"
              >
                {" "}
                <li>
                  {" "}
                  <FaUserGraduate className="inline" /> Study in Canada
                </li>
              </Link>
              <Link
                to="/education/study-in-unitedKingdom"
                className="link link-hover  hover:text-blue-600"
              >
                <li>
                  {" "}
                  <FaUserGraduate className="inline" /> Study in United Kingdom
                </li>
              </Link>
              <Link
                to="/education/study-in-unitedStates"
                className="link link-hover  hover:text-blue-600"
              >
                <li>
                  {" "}
                  <FaUserGraduate className="inline" /> Study in United State
                </li>
              </Link>
            </ul>
          </div>
          <div className="border border-orange-500 w-3/4 mt-16">
            <h2 className="bg-orange-500 text-lg p-3 text-white font-bold">
              Testimonials
            </h2>
            <div className="italic p-2">
              <div className="avatar ml-[30%]">
                <div className="w-24 mask mask-squircle ">
                  <img
                    className="flex mx-auto"
                    src={testimonial}
                    alt="student"
                  />
                </div>
              </div>
              <p className="text-lg text-center font-semibold text-orange-500">
                Azmain Haqe{" "}
              </p>
              <address className="text-center">
                <small className="opacity-[.5] text-center">
                  211/A,Mohammudpur,Dhaka{" "}
                </small>
              </address>
              <blockquote className=" text-center mx-10 lg:mx-[20%] text- italic font-semibold text-gray-900 dark:text-white">
                <svg
                  aria-hidden="true"
                  className="w-10 h-10 text-gray-400 dark:text-gray-600"
                  viewBox="0 0 24 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z"
                    fill="currentColor"
                  />
                </svg>
                <p>
                  “I loved Harbour Education, because you really become part of
                  a family with the rest of your Harbour mates. I thought that
                  was such a unique part of my time abroad when a lot of my
                  friends in other programs had hundreds of students and felt
                  little to no connection with their program. I really
                  appreciated that everyone in Harbour was so close and
                  comfortable with each other.”
                </p>
              </blockquote>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurVission;
