import React from "react";
import bgImage from "../../../assets/images/other-service/category/healthCare.jpg";
import img1 from "../../../assets/images/otherService/1.jpeg";
import { Link } from "react-router-dom";
import EnquiryForms from "../../shared/forms/enquiry/EnquiryForms";

const StudentHealthConver = () => {
  return (
    <div>
      <div
        className=" bg-no-repeat bg-center  "
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <h2 className="text-center text-5xl text-white p-28">
          Overseas Student Health Cover (OSHC)
        </h2>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 lg:-ml-16 bg-white ">
        <div className="lg:col-span-3 mx-5  lg:ml-[15%] mt-20">
          <p className="my-1">
            If you are a student from overseas on a temporary student visa it is
            a condition of your visa to maintain adequate health insurance for
            the duration of your stay in Australia. This means you need to
            purchase Overseas Student Health Cover (OSHC) and keep your policy
            up to date whilst you are in Australia and holding a student visa.
          </p>
          <p className="my-1">
            OSHC assists international students to meet the costs of medical and
            hospital care they may need while in Australia. OSHC also includes
            ambulance cover and limited pharmaceuticals.
          </p>
          <p className="my-1">
            Some exceptions may apply if you are a student from Sweden, Norway,
            or Belgium. If this applies to you, you may have special
            arrangements under your own national schemes – check with the{" "}
            <a
              className="link link-hover text-orange-500 hover:text-orange-600"
              href="https://www.homeaffairs.gov.au/"
              target="_blank"
            >
              Department of Home Affairs
            </a>{" "}
            to find out if special arrangements apply to you and if you are
            exempt from the requirement to purchase OSHC.
          </p>
          <p className="my-1">
            As holding OSHC is a visa requirement, take care to maintain your
            cover at all times. If you do fall behind in payments or renewing
            your cover, you will be able to continue your cover but you may not
            be able to claim for services you received while you were in
            arrears.
          </p>
          <p className="my-1">
            If your visa status or Medicare eligibility changes at any time,
            inform your insurer as soon as possible to find out whether your
            level of cover is still suitable. When your student visa expires,
            then you are no longer eligible to hold OSHC. You can then swap over
            to a residents’ cover or to an Overseas Visitors Health Cover plan.
          </p>
          <h2 className="text-xl font-medium my-1">Medicare and OSHC</h2>
          <p className="my-1">
            If you are in Australia on a student visa from the United Kingdom,
            Sweden, the Netherlands, Belgium, Slovenia, Italy or New Zealand,
            you can also apply for Medicare under your country’s{" "}
            <a
              className="link link-hover text-orange-500 hover:text-orange-600"
              href="https://www.servicesaustralia.gov.au/when-reciprocal-health-care-agreements-apply-and-you-visit-australia?context=22481"
              target="_blank"
            >
              Reciprocal Health Care Agreements.
            </a>{" "}
            (Students from Norway, Finland, Malta and the Republic of Ireland
            are not covered by the agreements with those countries.)
          </p>
          <p className="my-1">
            Any Medicare coverage you are entitled to under a Reciprocal Health
            Care Agreement will be in addition to OSHC. Having reciprocal access
            to Medicare does not exempt you from needing to take out OSHC.
          </p>
          <h2 className="text-xl font-medium my-1">What does OSHC cover?</h2>
          <p className="my-1">
            OSHC policies assist to cover the cost of hospital and medical
            treatment. Benefits are also paid for ambulance services.
          </p>
          <p className="my-1">
            The minimum requirements of a OSHC policy are set out in the{" "}
            <a
              className="link link-hover text-orange-500 hover:text-orange-600"
              href="https://www.health.gov.au/resources/collections/overseas-student-health-cover-oshc-resources?utm_source=health.gov.au&utm_medium=callout-auto-custom&utm_campaign=digital_transformation"
              target="_blank"
            >
              OSHC Deed.
            </a>{" "}
            The Department of Health also provides{" "}
            <a
              className="link link-hover text-orange-500 hover:text-orange-600"
              href="https://www.servicesaustralia.gov.au/when-reciprocal-health-care-agreements-apply-and-you-visit-australia?context=22481"
              target="_blank"
            >
              Explanatory Guidelines for the Deed
            </a>{" "}
            and a{" "}
            <a
              className="link link-hover text-orange-500 hover:text-orange-600"
              href="https://www.health.gov.au/resources/collections/overseas-student-health-cover-oshc-resources?utm_source=health.gov.au&utm_medium=callout-auto-custom&utm_campaign=digital_transformation"
              target="_blank"
            >
              One-Page Fact Sheet
            </a>{" "}
            about what is included under the Deed and how OSHC works. For
            further information, check with your OSHC insurer.
          </p>
          <p className="my-1">
            OSHC does not pay for general treatment (ancillary, or extras cover)
            such as dental, optical or physiotherapy. If you require cover for
            these treatments, you may take Extras OSHC provided by an OSHC
            provider or general treatment cover with any Australian private
            health insurer. You can also choose to supplement OSHC with other
            insurance such as international travel insurance.
          </p>
          <h2 className="text-xl font-medium my-1">OSHC and COVID-19</h2>
          <p className="my-1">
            OSHC includes cover for Coronavirus (COVID-19) related illnesses,
            principally “Lung and chest”, “Kidney and bladder” and “Dialysis for
            chronic kidney failure” clinical categories.
          </p>
          <p className="my-1">
            International students who are stranded in Australia, due to
            mandated travel restrictions put in place by the Australian
            Government in response to the global pandemic, are recommended to
            contact their private health insurer for further assistance to
            ensure continuity of cover during this time.
          </p>
          <p className="my-1">
            International students who are offshore due to Coronavirus
            (COVID-19) while the holder of a student visa are recommended to
            contact their private health insurer about available options during
            this time.
          </p>
          <p className="my-1">
            For more information see the{" "}
            <a
              className="link link-hover text-orange-500 hover:text-orange-600"
              href="https://www1.health.gov.au/internet/main/publishing.nsf/Content/private-1"
              target="_blank"
            >
              Department of Health.
            </a>{" "}
          </p>
          <h2 className="text-xl font-medium my-1">OSHC and pharmaceuticals</h2>
          <p className="my-1">
            Only limited benefits for pharmaceuticals apply, limited to $50 per
            pharmaceutical item to a maximum of $300 a year for single
            membership ($600 for a family membership). You may face significant
            out of pocket costs if you need treatment with pharmaceuticals,
            particularly oncology (cancer) treatment which can costs tens of
            thousands of dollars.
          </p>
          <p className="my-1">
            Travellers entering Australia may bring in medicines and medical
            devices for immediate use and to import small quantities for
            personal use. For more information see the Therapeutic Goods
            Administration website –{" "}
            <a
              className="link link-hover text-orange-500 hover:text-orange-600"
              href="https://www.tga.gov.au/entering-australia"
              target="_blank"
            >
              Entering Australia
            </a>{" "}
            and{" "}
            <a
              className="link link-hover text-orange-500 hover:text-orange-600"
              href="https://www.tga.gov.au/products/unapproved-therapeutic-goods/personal-importation-scheme"
              target="_blank"
            >
              Personal Importation Scheme.
            </a>
          </p>
          <h2 className="text-xl font-medium my-1">
            Where can I purchase OSHC?
          </h2>
          <p className="my-1">
            OSHC is offered by certain insurers under a{" "}
            <a
              className="link link-hover text-orange-500 hover:text-orange-600"
              href="https://www.health.gov.au/resources/collections/overseas-student-health-cover-oshc-resources?utm_source=health.gov.au&utm_medium=callout-auto-custom&utm_campaign=digital_transformation"
              target="_blank"
            >
              Deed of Agreement
            </a>{" "}
            with the Department of Health to provide adequate health insurance
            to students at a reasonable cost. Only a small number of registered
            health insurers offer OSHC:
          </p>
          <div className="border w-full my-6 ">
            <p className="text-lg font-semibold pt-3 pb-1 px-2">
              Health Insurer
            </p>
            <ul className="p-2">
              <li className="link link-hover text-orange-500 hover:text-orange-600">
                <a target="_blank" href="https://www.ahmoshc.com.au/">
                  ahm OSHC
                </a>
              </li>
              <li className="link link-hover text-orange-500 hover:text-orange-600">
                <a target="_blank" href="/otherService/student-health-cover">
                  Allianz Global Assistance (Peoplecare Health)
                </a>
              </li>
              <li className="link link-hover text-orange-500 hover:text-orange-600">
                <a
                  target="_blank"
                  href="https://www.bupa.com.au/health-insurance/oshc"
                >
                  BUPA Australia
                </a>
              </li>
              <li className="link link-hover text-orange-500 hover:text-orange-600">
                <a
                  target="_blank"
                  href="https://www.bupa.com.au/health-insurance/oshc"
                >
                  CBHS International Health
                </a>
              </li>
              <li className="link link-hover text-orange-500 hover:text-orange-600">
                <a
                  target="_blank"
                  href="https://www.medibank.com.au/overseas-health-insurance/oshc/"
                >
                  Medibank Private
                </a>
              </li>
              <li className="link link-hover text-orange-500 hover:text-orange-600">
                <a
                  target="_blank"
                  href="https://www.nib.com.au/overseas-students/"
                >
                  NIB OSHC
                </a>
              </li>
            </ul>
          </div>

          <div className="my-12">
            <h2 className="text-2xl font-medium">External Links</h2>

            <span className="m-2">
              <li className="link link-hover text-orange-500 hover:text-orange-600">
                <a
                  href="https://www.health.gov.au/resources/collections/overseas-student-health-cover-oshc-resources?utm_source=health.gov.au&utm_medium=callout-auto-custom&utm_campaign=digital_transformation"
                  target="_blank"
                >
                  {" "}
                  Department of Health – OSHC Deed and Explanatory Guidelines
                </a>
              </li>
              <li className="link link-hover text-orange-500 hover:text-orange-600">
                {" "}
                <a
                  href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/student-500"
                  target="_blank"
                >
                  Department of Home Affairs – Student Visa 500
                </a>
              </li>
              <li className="link link-hover text-orange-500 hover:text-orange-600">
                {" "}
                <a
                  href="https://www.servicesaustralia.gov.au/when-reciprocal-health-care-agreements-apply-and-you-visit-australia?context=22481"
                  target="_blank"
                >
                  Medicare – Health Care for visitors to Australia
                </a>
              </li>
            </span>
          </div>
        </div>

        <div>
          <div className="avatar">
            <div className="w-full rounded my-12 mx-7">
              <img src={img1} alt="cover" />
            </div>
          </div>

          <ul>
            <Link to="/otherService/student-health-cover">
              <li className="text-white bg-orange-500 hover:bg-blue-600 rounded my-3 mx-7 p-2 text-center">
                Overseas Student Health Cover(OSHC)
              </li>
            </Link>
            <Link to="/otherService/visitor-health-cover">
              <li className="text-white bg-orange-500 hover:bg-blue-600 rounded my-3 mx-7 p-2 text-center">
                Overseas Visitor Health Cover(OVHC)
              </li>
            </Link>
            <Link to="/otherService/sc-485-sc-482-health">
              <li className="text-white bg-orange-500 hover:bg-blue-600 rounded my-3 mx-7 p-2 text-center">
                SC 485 / SC 482 Health
              </li>
            </Link>
            <Link to="/otherService/job-help">
              <li className=" text-white bg-orange-500 hover:bg-blue-600 rounded my-3 mx-7 p-2 text-center">
                Job Help
              </li>
            </Link>
            <Link to="/otherService/accommodation-help">
              {" "}
              <li className=" text-white bg-orange-500 hover:bg-blue-600 rounded my-3 mx-7 p-2 text-center">
                Accommodation Help
              </li>
            </Link>
            <Link to="/otherService/airport-pick-u-services">
              {" "}
              <li className="text-white bg-orange-500 hover:bg-blue-600 rounded my-3 mx-7 p-2 text-center">
                Airport Pick up Services
              </li>
            </Link>
          </ul>
          <div>
          <EnquiryForms/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentHealthConver;
